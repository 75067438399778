export enum PaymentMethodsEnum {
  TRANSACTION = 1,
  WALLET = 2,
  TRANSACTION_AND_WALLET = 3,
  TRANSFER_MOVIL = 5,
  STAFF_PAYMENT = 100,
  MI_TRANSFER = 6,
  PAYMENT_REQUEST = 200,
  TROPI_PAY= 7,
}

/*
[
    {
        "paymentVariant": "Wallet",
        "paymentVariantId": 2,
        "title": "K-Wallet",
        "message": null,
        "disabled": false,
        "visible": true,
        "showBillingToSelect": true
    },
    {
        "paymentVariant": "TransactionAndWallet",
        "paymentVariantId": 3,
        "title": "Mix",
        "message": null,
        "disabled": false,
        "visible": false,
        "showBillingToSelect": false
    },
    {
        "paymentVariant": "Transaction",
        "paymentVariantId": 1,
        "title": "Credit/Debit Card",
        "message": null,
        "disabled": false,
        "visible": true,
        "showBillingToSelect": false
    }
]
 */
