import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

import {
  Observable, retry,
  throwError
} from 'rxjs';

import {AuthService} from './auth.service';

import {catchError, map} from "rxjs/operators";
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
    excludedResources = [
        // '/storefront/products',
        '/multi_stores',
        '/storefront/countries',
        '/storefront/grouped_stores',
        '/frontend-settings',
        '/global-categories',
    ];

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
  }

  /**
   * Para obtener un objeto
   *
   * @param {string} resource url relativa del servicio
   * @param {HttpParams} params parametros que se pasaran por la url
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public get(
    resource: string, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, isProxified: boolean = false, observe: string = '',
    responseType: string = ''): Observable<any> {
    console.log();
    return this.request('GET', resource, null, params, isV2, headers, showError, observe, responseType, isProxified);
  }

  /**
   * Para insertar un objeto
   *
   * @param {string} resource url relativa del servicio
   * @param data objeto json que se va a insertar
   * @param {HttpParams} params parametros que se pasaran por la url
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public post(
    resource: string, data?: any, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, observe: string = '',
    responseType: string = ''): Observable<any> {
    return this.request('POST', resource, data, params, isV2, headers, showError, observe, responseType);
  }

  /**
   * Para actualizar un objeto
   *
   * @param {string} resource url relativa del servicio
   * @param data objeto json que se va a insertar
   * @param {HttpParams} params parametros que se pasaran por la url
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public put(
    resource: string, data: any, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, observe: string = '',
    responseType: string = ''): Observable<any> {
    return this.request('PUT', resource + data._id, data, params, isV2, headers, showError, observe, responseType);
  }

  /**
   * Para actualizar un objeto sin enviar el objeto
   *
   * @param {string} resource url relativa del servicio
   * @param data objeto json que se va a insertar
   * @param {HttpParams} params parametros que se pasaran por la url
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public putOnlyUrl(
    resource: string, data?: any, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, observe: string = '',
    responseType: string = ''): Observable<any> {
    return this.request('PUT', resource, data, params, isV2, headers, showError, observe, responseType);
  }

  /**
   * Para parchear un objeto
   *
   * @param {string} resource url relativa del servicio
   * @param data objeto json que se va a parchear
   * @param {HttpParams} params parametros que se pasaran por la url
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public patch(
    resource: string, data?: any, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, observe: string = '',
    responseType: string = ''): Observable<any> {
    return this.request('PATCH', resource, data, params, isV2, headers, showError, observe, responseType);
  }

  /**
   * Para insertar o actualizar un objeto, el decide que peticion hacer en dependencia si tiene id el objeto
   *
   * @param {string} resource url relativa del servicio
   * @param data objeto json que se va a insertar
   * @param {HttpParams} params parametros que se pasaran por la url
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public save(
    resource: string, data: any, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, observe: string = '',
    responseType: string = ''): Observable<any> {
    if (data.id === undefined || data.id === null) {
      return this.request('POST', resource, data, params, isV2, headers, showError, observe, responseType);
    } else {
      return this.request('PUT', resource + data.id, data, params, isV2, headers, showError, observe, responseType);
    }
  }

  /**
   * Para eliminar un objeto
   *
   * @param {string} resource url relativa del servicio
   * @param {HttpParams} params parametros que se pasaran por la url
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public delete(
    resource: string, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, observe: string = '',
    responseType: string = ''): Observable<any> {
    return this.request('DELETE', resource, null, params, isV2, headers, showError, observe, responseType);
  }

  /**
   * Para eliminar una lista de objetos
   *
   * @param {string} resource url relativa del servicio
   * @param {any[]} list lista de id que se van a eliminar
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  public deleteList(
    resource: string, list: any[], isV2: boolean = true, headers?: HttpHeaders, showError: boolean = true, observe: string = '',
    responseType: string = ''): Observable<any> {
    return this.request('POST', resource, list, undefined, isV2, headers, showError, observe, responseType);
  }

  /**
   *
   * @param {string} method metodo por el que se va a enviar la peticion
   * @param {string} resource recurso o direccion
   * @param data data a enviar
   * @param {HttpParams} params params
   * @param {boolean} isV2 isV2
   * @param {HttpHeaders} headers headers
   * @param {boolean} showError showError
   * @param {string} observe observe
   * @param {string} responseType tipo de respuesta
   * @returns {Observable<any>} Observable para retornar
   */
  private request(
    method: string, resource: string, data?: any, params?: HttpParams, isV2: boolean = true, headers?: HttpHeaders,
    showError: boolean = true, observe: string = '', responseType: string = '', isProxified: boolean = false): Observable<any> {
    let proxy = `${ isProxified ? '/api/proxy/spree': ''}`;

    let url = ( isProxified ? environment.services_api_url : environment.base_route) + proxy + (isV2 ? environment.v2 : environment.v1) + resource;

    const options: any = {
      headers: this.authService.getHeader(data)
    };

    if (data) {
      options.body = data;
    }

    if (params) {
      options.params = params;
    }

    if (headers) {
      options.headers = headers;
    }

    if (observe) {
      options.observe = observe;
    }

    if (responseType) {
      options.responseType = responseType;
    }
    return this.http.request(method, url, options)
      .pipe(
        map((response: any) => {
          this.messageFromServer(response);
          // this.blockUI.stop();
          return response;
        }),
        retry(environment.retry_on_fail),
        catchError((error: any) => this.handleErrors(error, showError, url))
      );
  }

  public handleErrors(error: any, showError: boolean = true, url = ''): any {
      if (error.status === 0 && environment.production) {
        // todo notificar que no hay conexion con el api
        this.authService.logout();
        this.router.navigate(['']);
      }

      const excluded = this.excludedResources.some((re) => url.includes(re));

      if (!excluded) {
          const msg = error?.error?.error || error?.error?.message || null;
          this.showError(msg, showError);
      }

      window.scrollTo(0, 0);
      return throwError(error);
  }

  public showError(msg: string, show: boolean): void {
    if (show && msg) {
      this.notificationService.showAndSubscribe(msg, 'CLOSE');
    } else {
      if (show) {
        this.notificationService.showAndSubscribe('GENERIC_ERROR', 'CLOSE');
      }
    }
  }

  private messageFromServer(response: any): void {
    if (response?.msg_success) {
      // this.notificationService.success(response.msg_success, true);
      window.scrollTo(0, 0);
    }

    if (response?.msg_info) {
      // this.notificationService.info(response.msg_info, true);
      window.scrollTo(0, 0);
    }

    if (response?.msg_warn) {
      // this.notificationService.warn(response.msg_warn, true);
      window.scrollTo(0, 0);
    }

    if (response?.msg_error) {
      // this.notificationService.error(response.msg_error, true);
      window.scrollTo(0, 0);
    }
  }

  public convertModelToFormData(model: any, form?: FormData, namespace = ''): FormData {
    let formData = form || new FormData();

    if (typeof model === 'string') {
      formData.append(namespace, model);
    } else {
      for (const propertyName in model) {
        if (!model.hasOwnProperty(propertyName) || !model[propertyName]) {
          continue;
        }
        const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
        if (model[propertyName] instanceof Date) {
          formData.append(formKey, model[propertyName].toISOString());
        } else if (model[propertyName] instanceof Array) {
          model[propertyName].forEach((element: any, index: any) => {
            const tempFormKey = `${formKey}[${index}]`;
            this.convertModelToFormData(element, formData, tempFormKey);
          });
        } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
          this.convertModelToFormData(model[propertyName], formData, formKey);
        } else if (typeof model[propertyName] === 'object' && (model[propertyName] instanceof File)) {
          formData.append(formKey, model[propertyName]);
        } else {
          formData.append(formKey, model[propertyName].toString());
        }
      }
    }

    return formData;
  }
}
