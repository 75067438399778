import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {Transaction} from "../../../shared/models";
import {faSignOut, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {WalletService} from "../../../shared/services/wallet.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../shared/services/auth.service";
import {takeUntil} from "rxjs/operators";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {LanguageService} from "../../../shared/services/language.service";
import {Payment} from "../../../shared/models/payment.model";
import {MatTooltip} from "@angular/material/tooltip";


@Component({
  selector: 'app-user-payments',
  standalone: true,
  imports: [
    CurrencyPipe,
    DatePipe,
    FaIconComponent,
    NgForOf,
    NgIf,
    TranslateModule,
    UpperCasePipe,
    CommonModule,
    MatTooltip
  ],
  templateUrl: './user-payments.component.html',
  styleUrl: './user-payments.component.css'
})
export class UserPaymentsComponent implements OnInit, OnDestroy {
  data: any;
  params: any;

  faSpinner = faSpinner;
  spinLoadMore = false;

  actualPage: number = 1;
  perPage: number = 10;
  totalElements: number = 0;
  totalPages: number = 0;

  walletTierId: number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  payments: Payment[] = [];

  language!: string;
  isLanguageLoaded: boolean = false;
  redirect: any;
  paymentClient: any;
  auth: boolean = false;



  constructor(
    private walletService: WalletService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private languageService: LanguageService
  ) {
    this.goToTop();
  }


  ngOnInit(): void {
    window.scroll({top: 0});

    this.languageService.selectedLanguage
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        const paramMap = this.route.snapshot.queryParamMap;
        if (isAllowed(paramMap.get('redirect'))) {
          this.language = this.languageService.selectedLanguage.value;
          this.isLanguageLoaded = true;

          this.redirect = paramMap.get('redirect');
          this.authService.saveRedirect(this.redirect);
          this.paymentClient = paramMap.get('paymentClient') ?? '';

          if (!this.authService.getCurrentPaymentClient()) {
            this.authService.savePaymentClient(this.paymentClient);
          }
          const code = paramMap.get('code');
          if (code && !this.authService.isAuthenticated()) {
            await this.authService.getTokensWithCode(code as string).toPromise();
          }
          if (this.authService.getCurrentToken()) {
            this.auth = true;
            this.loadPayments(this.actualPage);
          }
        } else {
          this.router.navigate(['']);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }


  loadMoreTransactions() {
    this.loadPayments(++this.actualPage);
  }

  loadPayments(page: number) {
    this.spinLoadMore = true;
    this.walletService.getPayments(page, this.perPage)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: (data: any) => {
            const paymentsData = [ ...this.payments, ...data?.items];
            this.payments = this.toMapAmounts(paymentsData,
              ['initialTotalAmount', 'initialWalletAmount', 'totalRefundConfirmedAmount', 'walletRefundConfirmedAmount']);
            this.spinLoadMore = false;
          },
          error: () => this.spinLoadMore = false
        }
      )
  }

  toMapAmounts(data: any, mapFields: any) {
    data.forEach( (paymentKey: any) => {
      mapFields.forEach((key:string) => {
        if (paymentKey[key]) {
          paymentKey[key] = Number((paymentKey[key] / 100).toFixed(2));
        }
      });
    });
    return data;
  }

  getObjectKeys(object: Object) {
    return Object.keys(object);
  }

  goToTop() {
    window.scroll({top: 0});
  }

  protected readonly faSignOut = faSignOut;

  async logout() {
    await this.authService.signOff();
    this.authService.navigateToApp();
  }
}
