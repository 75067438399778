<app-loading *ngIf="!isLoaded"></app-loading>
<app-header *ngIf="isLoaded">

</app-header>
<div class="bg-gray-100 min-h-screen" [hidden]="!isLoaded">
  <section class="relative w-full h-auto flex justify-center items-start bg-gray-#f2f2f2 pb-5">
    <div class="w-[350px]">
      <mat-stepper *ngIf="isLanguageLoaded" labelPosition="bottom" [disableRipple]="true" #stepper
                   class="hidden-stepper bg-gray-#f2f2f2">
        <mat-step class="hidden">
          <app-payments
                        (isBolsaTransfer)="setIsBolsaTransfer($event)"
                        [isBolsaTransferInput]="isBolsaTransfer"
                        [total]="total" [paymentRoute]="paymentRoute"
                        [id]="id"
                        [orderId]="orderId"
                        [orderNumber]="orderNumber"
                        [discount]="discount"
                        [vid]="vid"
                        [rid]="rid"
                        [redirect]="redirect"
                        (isLoaded)= "loadCompleted()"
                        (next)="changeStepper($event)">
          </app-payments>
        </mat-step>

        <!--   todo poner la barrera de paso final aqui y verificar como   validarla   -->

        <mat-step class="hidden" *ngIf="isBolsaTransfer">
          <ng-template matStepLabel>{{ 'QR' | translate }}</ng-template>
          <app-new-cart-qr-transfer *ngIf="stepper.selectedIndex === 1"
                                    (next)="changeStepper($event)">
          </app-new-cart-qr-transfer>
        </mat-step>
      </mat-stepper>
    </div>
  </section>
</div>
