import * as Sentry from '@sentry/browser';
import { breadcrumbsIntegration, globalHandlersIntegration, linkedErrorsIntegration, httpContextIntegration, init as init$1, setContext, browserTracingIntegration as browserTracingIntegration$1, spanToJSON, SEMANTIC_ATTRIBUTE_SENTRY_SOURCE, SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN, getClient, startBrowserTracingNavigationSpan, startInactiveSpan, getCurrentScope, getActiveSpan, getRootSpan } from '@sentry/browser';
export * from '@sentry/browser';
import * as i0 from '@angular/core';
import { VERSION, Injectable, Inject, Directive, Input, NgModule } from '@angular/core';
import { inboundFiltersIntegration, functionToStringIntegration, dedupeIntegration, applySdkMetadata } from '@sentry/core';
import { logger, isString, consoleSandbox, stripUrlQueryAndFragment, timestampInSeconds } from '@sentry/utils';
import { HttpErrorResponse } from '@angular/common/http';
import * as i1 from '@angular/router';
import { NavigationStart, ResolveEnd, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

/*
 * This file defines flags and constants that can be modified during compile time in order to facilitate tree shaking
 * for users.
 *
 * We define "magic strings" like `__SENTRY_DEBUG__` that may get replaced with actual values during our, or the user's
 * build process. Take care when introducing new flags - they must not throw if they are not replaced. See the Debug
 * Build Flags section in CONTRIBUTING.md.
 */
/** Flag that is true for debug builds, false otherwise. */
const IS_DEBUG_BUILD = typeof __SENTRY_DEBUG__ === 'undefined' ? true : __SENTRY_DEBUG__;

/**
 * Get the default integrations for the Angular SDK.
 */
function getDefaultIntegrations() {
  // Don't include the BrowserApiErrors integration as it interferes with the Angular SDK's `ErrorHandler`:
  // BrowserApiErrors would catch certain errors before they reach the `ErrorHandler` and
  // thus provide a lower fidelity error than what `SentryErrorHandler`
  // (see errorhandler.ts) would provide.
  //
  // see:
  //  - https://github.com/getsentry/sentry-javascript/issues/5417#issuecomment-1453407097
  //  - https://github.com/getsentry/sentry-javascript/issues/2744
  return [inboundFiltersIntegration(), functionToStringIntegration(), breadcrumbsIntegration(), globalHandlersIntegration(), linkedErrorsIntegration(), dedupeIntegration(), httpContextIntegration()];
}
/**
 * Inits the Angular SDK
 */
function init(options) {
  const opts = {
    defaultIntegrations: getDefaultIntegrations(),
    ...options
  };
  applySdkMetadata(opts, 'angular');
  checkAndSetAngularVersion();
  return init$1(opts);
}
function checkAndSetAngularVersion() {
  const ANGULAR_MINIMUM_VERSION = 14;
  const angularVersion = VERSION && VERSION.major ? parseInt(VERSION.major, 10) : undefined;
  if (angularVersion) {
    if (angularVersion < ANGULAR_MINIMUM_VERSION) {
      IS_DEBUG_BUILD && logger.warn(`This Sentry SDK does not officially support Angular ${angularVersion}.`, `This SDK only supports Angular ${ANGULAR_MINIMUM_VERSION} and above.`, "If you're using lower Angular versions, check the Angular Version Compatibility table in our docs: https://docs.sentry.io/platforms/javascript/guides/angular/#angular-version-compatibility.", 'Otherwise, please consider upgrading your Angular version.');
    }
    setContext('angular', {
      version: angularVersion
    });
  }
}

// In Angular 17 and future versions, zoneless support is forthcoming.
// Therefore, it's advisable to safely check whether the `run` function is
// available in the `<root>` context.
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const isNgZoneEnabled = typeof Zone !== 'undefined' && Zone.root && Zone.root.run;
/**
 * The function that does the same job as `NgZone.runOutsideAngular`.
 *
 * ⚠️ Note: All of the Sentry functionality called from inside the Angular
 * execution context must be wrapped in this function. Angular's rendering
 * relies on asynchronous tasks being scheduled within its execution context.
 * Since Sentry schedules tasks that do not interact with Angular's rendering,
 * it may prevent Angular from functioning reliably. Consequently, it may disrupt
 * processes such as server-side rendering or client-side hydration.
 */
function runOutsideAngular(callback) {
  // Running the `callback` within the root execution context enables Angular
  // processes (such as SSR and hydration) to continue functioning normally without
  // timeouts and delays that could affect the user experience. This approach is
  // necessary because some of the Sentry functionality continues to run in the background.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return isNgZoneEnabled ? Zone.root.run(callback) : callback();
}

// https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
function tryToUnwrapZonejsError(error) {
  // TODO: once Angular14 is the minimum requirement ERROR_ORIGINAL_ERROR and
  //  getOriginalError from error.ts can be used directly.
  return error && error.ngOriginalError ? error.ngOriginalError : error;
}
function extractHttpModuleError(error) {
  // The `error` property of http exception can be either an `Error` object, which we can use directly...
  if (isErrorOrErrorLikeObject(error.error)) {
    return error.error;
  }
  // ... or an`ErrorEvent`, which can provide us with the message but no stack...
  // guarding `ErrorEvent` against `undefined` as it's not defined in Node environments
  if (typeof ErrorEvent !== 'undefined' && error.error instanceof ErrorEvent && error.error.message) {
    return error.error.message;
  }
  // ...or the request body itself, which we can use as a message instead.
  if (typeof error.error === 'string') {
    return `Server returned code ${error.status} with body "${error.error}"`;
  }
  // If we don't have any detailed information, fallback to the request message itself.
  return error.message;
}
function isErrorOrErrorLikeObject(value) {
  if (value instanceof Error) {
    return true;
  }
  if (value === null || typeof value !== 'object') {
    return false;
  }
  const candidate = value;
  return isString(candidate.name) && isString(candidate.message) && (undefined === candidate.stack || isString(candidate.stack));
}
/**
 * Implementation of Angular's ErrorHandler provider that can be used as a drop-in replacement for the stock one.
 */
class SentryErrorHandler {
  constructor(options) {
    this._options = {
      logErrors: true,
      ...options
    };
  }
  /**
   * Method executed when the injector is destroyed.
   */
  ngOnDestroy() {
    if (this._removeAfterSendEventListener) {
      this._removeAfterSendEventListener();
    }
  }
  /**
   * Method called for every value captured through the ErrorHandler
   */
  handleError(error) {
    const extractedError = this._extractError(error) || 'Handled unknown error';
    // Capture handled exception and send it to Sentry.
    const eventId = runOutsideAngular(() => Sentry.captureException(extractedError, {
      mechanism: {
        type: 'angular',
        handled: false
      }
    }));
    // When in development mode, log the error to console for immediate feedback.
    if (this._options.logErrors) {
      // eslint-disable-next-line no-console
      consoleSandbox(() => console.error(extractedError));
    }
    // Optionally show user dialog to provide details on what happened.
    if (this._options.showDialog) {
      const client = Sentry.getClient();
      if (client && !this._removeAfterSendEventListener) {
        this._removeAfterSendEventListener = client.on('afterSendEvent', event => {
          if (!event.type && event.event_id) {
            runOutsideAngular(() => {
              Sentry.showReportDialog({
                ...this._options.dialogOptions,
                eventId: event.event_id
              });
            });
          }
        });
      } else if (!client) {
        runOutsideAngular(() => {
          Sentry.showReportDialog({
            ...this._options.dialogOptions,
            eventId
          });
        });
      }
    }
  }
  /**
   * Used to pull a desired value that will be used to capture an event out of the raw value captured by ErrorHandler.
   */
  _extractError(error) {
    // Allow custom overrides of extracting function
    if (this._options.extractor) {
      const defaultExtractor = this._defaultExtractor.bind(this);
      return this._options.extractor(error, defaultExtractor);
    }
    return this._defaultExtractor(error);
  }
  /**
   * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
   */
  _defaultExtractor(errorCandidate) {
    const error = tryToUnwrapZonejsError(errorCandidate);
    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      return extractHttpModuleError(error);
    }
    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || isErrorOrErrorLikeObject(error)) {
      return error;
    }
    // Nothing was extracted, fallback to default error message.
    return null;
  }
}
SentryErrorHandler.ɵfac = function SentryErrorHandler_Factory(t) {
  return new (t || SentryErrorHandler)(i0.ɵɵinject('errorHandlerOptions'));
};
SentryErrorHandler.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SentryErrorHandler,
  factory: SentryErrorHandler.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SentryErrorHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: ['errorHandlerOptions']
      }]
    }];
  }, null);
})();
/**
 * Factory function that creates an instance of a preconfigured ErrorHandler provider.
 */
function createErrorHandler(config) {
  return new SentryErrorHandler(config);
}
const ANGULAR_ROUTING_OP = 'ui.angular.routing';
const ANGULAR_INIT_OP = 'ui.angular.init';
const ANGULAR_OP = 'ui.angular';
let instrumentationInitialized;
/**
 * A custom browser tracing integration for Angular.
 *
 * Use this integration in combination with `TraceService`
 */
function browserTracingIntegration(options = {}) {
  // If the user opts out to set this up, we just don't initialize this.
  // That way, the TraceService will not actually do anything, functionally disabling this.
  if (options.instrumentNavigation !== false) {
    instrumentationInitialized = true;
  }
  return browserTracingIntegration$1({
    ...options,
    instrumentNavigation: false
  });
}
/**
 * This function is extracted to make unit testing easier.
 */
function _updateSpanAttributesForParametrizedUrl(route, span) {
  const attributes = span && spanToJSON(span).data || {};
  if (span && attributes[SEMANTIC_ATTRIBUTE_SENTRY_SOURCE] === 'url') {
    span.updateName(route);
    span.setAttribute(SEMANTIC_ATTRIBUTE_SENTRY_SOURCE, 'route');
    span.setAttribute(SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN, `auto.${spanToJSON(span).op}.angular`);
  }
}
/**
 * Angular's Service responsible for hooking into Angular Router and tracking current navigation process.
 * Creates a new transaction for every route change and measures a duration of routing process.
 */
class TraceService {
  constructor(_router) {
    this._router = _router;
    this.navStart$ = this._router.events.pipe(filter(event => event instanceof NavigationStart), tap(navigationEvent => {
      if (!instrumentationInitialized) {
        IS_DEBUG_BUILD && logger.error('Angular integration has tracing enabled, but Tracing integration is not configured');
        return;
      }
      if (this._routingSpan) {
        this._routingSpan.end();
        this._routingSpan = null;
      }
      const client = getClient();
      const strippedUrl = stripUrlQueryAndFragment(navigationEvent.url);
      if (client) {
        // see comment in `_isPageloadOngoing` for rationale
        if (!this._isPageloadOngoing()) {
          runOutsideAngular(() => {
            startBrowserTracingNavigationSpan(client, {
              name: strippedUrl,
              attributes: {
                [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.navigation.angular',
                [SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'url'
              }
            });
          });
        } else {
          // The first time we end up here, we set the pageload flag to false
          // Subsequent navigations are going to get their own navigation root span
          // even if the pageload root span is still ongoing.
          this._pageloadOngoing = false;
        }
        this._routingSpan = runOutsideAngular(() => startInactiveSpan({
          name: `${navigationEvent.url}`,
          op: ANGULAR_ROUTING_OP,
          attributes: {
            [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.angular',
            [SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'url',
            url: strippedUrl,
            ...(navigationEvent.navigationTrigger && {
              navigationTrigger: navigationEvent.navigationTrigger
            })
          }
        })) || null;
        return;
      }
    }));
    // The ResolveEnd event is fired when the Angular router has resolved the URL and
    // the parameter<->value mapping. It holds the new resolved router state with
    // the mapping and the new URL.
    // Only After this event, the route is activated, meaning that the transaction
    // can be updated with the parameterized route name before e.g. the route's root
    // component is initialized. This should be early enough before outgoing requests
    // are made from the new route, with the exceptions of requests being made during
    // a navigation.
    this.resEnd$ = this._router.events.pipe(filter(event => event instanceof ResolveEnd), tap(event => {
      const route = getParameterizedRouteFromSnapshot(event.state.root);
      if (route) {
        getCurrentScope().setTransactionName(route);
      }
      const activeSpan = getActiveSpan();
      const rootSpan = activeSpan && getRootSpan(activeSpan);
      _updateSpanAttributesForParametrizedUrl(route, rootSpan);
    }));
    this.navEnd$ = this._router.events.pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError), tap(() => {
      if (this._routingSpan) {
        runOutsideAngular(() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this._routingSpan.end();
        });
        this._routingSpan = null;
      }
    }));
    this._routingSpan = null;
    this._pageloadOngoing = true;
    this._subscription = new Subscription();
    this._subscription.add(this.navStart$.subscribe());
    this._subscription.add(this.resEnd$.subscribe());
    this._subscription.add(this.navEnd$.subscribe());
  }
  /**
   * This is used to prevent memory leaks when the root view is created and destroyed multiple times,
   * since `subscribe` callbacks capture `this` and prevent many resources from being GC'd.
   */
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  /**
   * We only _avoid_ creating a navigation root span in one case:
   *
   * There is an ongoing pageload span AND the router didn't yet emit the first navigation start event
   *
   * The first navigation start event will create the child routing span
   * and update the pageload root span name on ResolveEnd.
   *
   * There's an edge case we need to avoid here: If the router fires the first navigation start event
   * _after_ the pageload root span finished. This is why we check for the pageload root span.
   * Possible real-world scenario: Angular application and/or router is bootstrapped after the pageload
   * idle root span finished
   *
   * The overall rationale is:
   * - if we already avoided creating a navigation root span once, we don't avoid it again
   *   (i.e. set `_pageloadOngoing` to `false`)
   * - if `_pageloadOngoing` is already `false`, create a navigation root span
   * - if there's no active/pageload root span, create a navigation root span
   * - only if there's an ongoing pageload root span AND `_pageloadOngoing` is still `true,
   *   don't create a navigation root span
   */
  _isPageloadOngoing() {
    if (!this._pageloadOngoing) {
      // pageload is already finished, no need to update
      return false;
    }
    const activeSpan = getActiveSpan();
    if (!activeSpan) {
      this._pageloadOngoing = false;
      return false;
    }
    const rootSpan = getRootSpan(activeSpan);
    this._pageloadOngoing = spanToJSON(rootSpan).op === 'pageload';
    return this._pageloadOngoing;
  }
}
TraceService.ɵfac = function TraceService_Factory(t) {
  return new (t || TraceService)(i0.ɵɵinject(i1.Router));
};
TraceService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TraceService,
  factory: TraceService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TraceService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.Router
    }];
  }, null);
})();
const UNKNOWN_COMPONENT = 'unknown';
/**
 * A directive that can be used to capture initialization lifecycle of the whole component.
 */
class TraceDirective {
  /**
   * Implementation of OnInit lifecycle method
   * @inheritdoc
   */
  ngOnInit() {
    if (!this.componentName) {
      this.componentName = UNKNOWN_COMPONENT;
    }
    if (getActiveSpan()) {
      this._tracingSpan = runOutsideAngular(() => startInactiveSpan({
        name: `<${this.componentName}>`,
        op: ANGULAR_INIT_OP,
        attributes: {
          [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.angular.trace_directive'
        }
      }));
    }
  }
  /**
   * Implementation of AfterViewInit lifecycle method
   * @inheritdoc
   */
  ngAfterViewInit() {
    if (this._tracingSpan) {
      runOutsideAngular(() => this._tracingSpan.end());
    }
  }
}
TraceDirective.ɵfac = function TraceDirective_Factory(t) {
  return new (t || TraceDirective)();
};
TraceDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TraceDirective,
  selectors: [["", "trace", ""]],
  inputs: {
    componentName: [0, "trace", "componentName"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TraceDirective, [{
    type: Directive,
    args: [{
      selector: '[trace]'
    }]
  }], null, {
    componentName: [{
      type: Input,
      args: ['trace']
    }]
  });
})();
/**
 * A module serves as a single compilation unit for the `TraceDirective` and can be re-used by any other module.
 */
class TraceModule {}
TraceModule.ɵfac = function TraceModule_Factory(t) {
  return new (t || TraceModule)();
};
TraceModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TraceModule
});
TraceModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TraceModule, [{
    type: NgModule,
    args: [{
      declarations: [TraceDirective],
      exports: [TraceDirective]
    }]
  }], null, null);
})();
/**
 * Decorator function that can be used to capture initialization lifecycle of the whole component.
 */
function TraceClass(options) {
  let tracingSpan;
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  return target => {
    const originalOnInit = target.prototype.ngOnInit;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    target.prototype.ngOnInit = function (...args) {
      tracingSpan = runOutsideAngular(() => startInactiveSpan({
        onlyIfParent: true,
        name: `<${options && options.name ? options.name : 'unnamed'}>`,
        op: ANGULAR_INIT_OP,
        attributes: {
          [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.angular.trace_class_decorator'
        }
      }));
      if (originalOnInit) {
        return originalOnInit.apply(this, args);
      }
    };
    const originalAfterViewInit = target.prototype.ngAfterViewInit;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    target.prototype.ngAfterViewInit = function (...args) {
      if (tracingSpan) {
        runOutsideAngular(() => tracingSpan.end());
      }
      if (originalAfterViewInit) {
        return originalAfterViewInit.apply(this, args);
      }
    };
  };
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
}
/**
 * Decorator function that can be used to capture a single lifecycle methods of the component.
 */
function TraceMethod(options) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (target, propertyKey, descriptor) => {
    const originalMethod = descriptor.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    descriptor.value = function (...args) {
      const now = timestampInSeconds();
      runOutsideAngular(() => {
        startInactiveSpan({
          onlyIfParent: true,
          name: `<${options && options.name ? options.name : 'unnamed'}>`,
          op: `${ANGULAR_OP}.${String(propertyKey)}`,
          startTime: now,
          attributes: {
            [SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.angular.trace_method_decorator'
          }
        }).end(now);
      });
      if (originalMethod) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return originalMethod.apply(this, args);
      }
    };
    return descriptor;
  };
}
/**
 * Takes the parameterized route from a given ActivatedRouteSnapshot and concatenates the snapshot's
 * child route with its parent to produce the complete parameterized URL of the activated route.
 * This happens recursively until the last child (i.e. the end of the URL) is reached.
 *
 * @param route the ActivatedRouteSnapshot of which its path and its child's path is concatenated
 *
 * @returns the concatenated parameterized route string
 */
function getParameterizedRouteFromSnapshot(route) {
  const parts = [];
  let currentRoute = route && route.firstChild;
  while (currentRoute) {
    const path = currentRoute && currentRoute.routeConfig && currentRoute.routeConfig.path;
    if (path === null || path === undefined) {
      break;
    }
    parts.push(path);
    currentRoute = currentRoute.firstChild;
  }
  const fullPath = parts.filter(part => part).join('/');
  return fullPath ? `/${fullPath}/` : '/';
}

/**
 * Generated bundle index. Do not edit.
 */

export { SentryErrorHandler, TraceClass, TraceDirective, TraceMethod, TraceModule, TraceService, browserTracingIntegration, createErrorHandler, getDefaultIntegrations, init };
