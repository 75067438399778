<section *ngIf="isLanguageLoaded"
         class="relative w-full min-h-screen flex flex-wrap justify-center bg-gray-#f2f2f2 us:p-5 md:p-12">

  <div class="relative mb-4
        us:w-full
        lg-landscape:w-879px">
    <div class="relative flex flex-col flex-1">


      <div class="flex flex-row justify-between w-full">

        <div class="relative w-full flex justify-between items-center justify-items-center mt-2">
          <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                      text-19px">{{'PROFILE_PAYMENT_REQUESTS' | translate}}</p>
        </div>
        <button type="button" class="bg-yellow-#FF6D03 text-white font-Lato-Regular font-bold rounded-full
            w-8 h-8 text-base" (click)="logout()">
          <fa-icon class="relative w-5 h-5" [icon]="faSignOut" [matTooltip]="'LOG_OUT'| translate">
          </fa-icon>
        </button>
      </div>

      <div class="relative w-full box-border
                  mt-7">
        <div class="relative w-full bg-gray-#E7E7E7 flex justify-start
                      h-50px">
          <div (click)="selectTab(2)"
               [ngClass]="tab_2 ? 'bg-white border-t-4 border-t-yellow-#FF6D03' : 'border-t-0'"
               class="tab relative cursor-pointer w-auto h-full flex justify-start items-center
                          pl-4 pr-4">
            <p class="relative font-Lato-Regular font-bold text-gray-#252525 uppercase
                              text-sm">{{'SENT' | translate}}</p>
          </div>
          <div (click)="selectTab(1)"
               [ngClass]="tab_1 ? 'bg-white border-t-4 border-t-yellow-#FF6D03' : 'border-t-0'"
               class="tab relative cursor-pointer w-auto h-full flex justify-start items-center
                          pl-4 pr-4">
            <p class="relative font-Lato-Regular font-bold text-gray-#252525 uppercase
                              text-sm">{{'RECEIVED' | translate}}</p>
          </div>
        </div>

        <ng-container *ngIf="!is_loading_requests else loadingContent">
          <div class="relative w-full h-auto gap-1 flex flex-col">
            <div *ngFor="let item of payment_requests" class="relative w-full bg-white border-t border-b border-gray-#E7E7E7 grid grid-cols-1 py-4
                      min-h-50px px-2 sm:px-4">
              <div class="relative w-full flex justify-between items-center space-y-2">
                <p class="relative text-gray-#252525 text-xs font-semibold font-Lato-Regular uppercase">
                  {{item?.paymentRequestStatusNameTranslated}}</p>
                <p
                  class="relative text-gray-#252525 text-xs font-semibold font-Lato-Regular uppercase mt-2">
                  total
                </p>
              </div>
              <div class="relative w-full flex justify-between items-center">
                <p class="relative text-gray-#252525 text-xs font-semibold font-Lato-Regular uppercase">
                  {{item?.createdAt | date: 'dd.MM.YYYY | HH:mma'}}
                </p>
                <p
                  class="relative text-gray-#252525 text-xs font-semibold font-Lato-Regular uppercase mt-2">
                  $ {{item?.displayAmount}}
                </p>
              </div>
              <div class="relative w-full h-[2px] bg-gray-#D6D6D6 my-2"></div>
              <div class="relative w-full flex justify-between items-center space-y-2
                            us:flex-wrap
                            lg-landscape:flex-nowrap">
                <p class="relative text-blue-#18396C text-xs font-semibold font-Lato-Regular
                                us:w-full
                                us-landscape:w-auto">
                  {{item?.payerUserEmail}}</p>
                <p class="relative text-gray-#252525 text-xs font-semibold font-Lato-Regular uppercase">
                  {{'ORDER_NUMBER' | translate}} <span
                  class="relative ml-4">{{item?.publicCode}}</span>
                </p>
              </div>
              <p class="relative w-full font-Lato-Regular font-semibold text-sm text-gray-#252525 mt-2">
                {{item?.requestMessage}}</p>

              <div class="relative w-full flex justify-between items-center py-2">
                <div class="relative "
                     *ngIf="item?.paymentRequestStatusId == PENDING_PAYMENT_STATUS && item.paymentLink && tab_2">
                  <button class="relative font-bold flex gap-2 justify-center items-center text-yellow-#FF6D03 bg-white border border-yellow-#FF6D03 rounded-full
                                      font-Lato-Regular text-sm h-10 p-2 min-w-108px"
                          (click)="cancelPaymentRequest(item.id)">
                    {{'CANCEL' | translate}}
                    <fa-icon *ngIf="isCancelingRequest"
                             class="relative animate-spin flex justify-center items-center bg-white rounded-full h-5 w-5 text-xl  text-yellow-#FF6D03"
                             [icon]="faspinner">
                    </fa-icon>
                  </button>
                </div>
                <div class="relative flex">
                  <button TooltipTouchGestures="false" matTooltipPosition="above"
                          matTooltip="{{'COPY_LINK' | translate}}"
                          *ngIf="item.paymentRequestStatusId === PENDING_PAYMENT_STATUS && item.paymentLink"
                          (click)="copyToClipboard(item)"
                          class="relative flex justify-center items-center bg-yellow-#FF6D03 border rounded-md border-yellow-#FF6D03 h-8 mr-2 w-8">
                    <fa-icon *ngIf="!is_copying"
                             class="relative flex justify-center items-center bg-yellow-#FF6D03 rounded-full h-5 w-5  text-xl  text-white"
                             [icon]="facopy">
                    </fa-icon>
                    <fa-icon *ngIf="is_copying"
                             class="relative animate-spin flex justify-center items-center bg-yellow-#FF6D03 rounded-full h-5 w-5 text-xl  text-white"
                             [icon]="faspinner">
                    </fa-icon>
                    <p *ngIf="show_copied_text"
                       class="absolute top-100.5% font-Lato-Bold text-sm text-green-#0D842D text-left">
                      {{'COPIED' | translate}}
                    </p>
                  </button>
                  <button
                    *ngIf="can_share && item.paymentRequestStatusId === PENDING_PAYMENT_STATUS && item.paymentLink"
                    TooltipTouchGestures="false" matTooltipPosition="above"
                    matTooltip="{{'SHARE_LINK' | translate}}" (click)="shareLink(item)"
                    class="relative flex justify-center items-center bg-yellow-#FF6D03 border-2 rounded-md border-yellow-#FF6D03 h-8 w-8">
                    <fa-icon *ngIf="!is_sharing"
                             class="relative flex justify-center items-center bg-yellow-#FF6D03 rounded-full h-5 w-5 text-xl text-white"
                             [icon]="fashare">
                    </fa-icon>
                    <fa-icon *ngIf="is_sharing"
                             class="relative animate-spin flex justify-center items-center bg-yellow-#FF6D03 rounded-full h-5 w-5 text-xl  text-white"
                             [icon]="faspinner">
                    </fa-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="relative w-full text-center justify-center items-center pt-3"
                 *ngIf="!is_loading_requests && !payment_requests.length">
              <h3 class="relative text-2xl font-Lato-Regular">{{'NO_PAYMENT_REQUESTS_AVAILABLE' | translate}}</h3>
            </div>
          </div>
        </ng-container>
        <ng-template #loadingContent>
          <div class="relative grid grid-cols-1 gap-1">
            <div class="relative w-full h-132px animate-pulse bg-gray-#dfdfdf" *ngFor="let item of [1,2,3]"></div>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</section>
