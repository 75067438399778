import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose} from "@angular/material/dialog";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {ReactiveFormsModule} from "@angular/forms";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {QrCodeModule} from "ng-qrcode";

@Component({
  selector: 'app-qr-generator',
  standalone: true,
  imports: [
    CommonModule,
    QrCodeModule,
    TranslateModule,
  ],
  templateUrl: './qr-generator.component.html',
  styleUrls: ['./qr-generator.component.scss']
})
export class QrGeneratorComponent implements OnInit {

  @Input() qrValue: any = '';
  @Input() title: string = 'K_WALLET';

  joinedValues = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ) {
    if (data) {
      this.qrValue = data.qrValue;
      this.title = data.title;
    }

    this.joinedValues = `KWallet: ${this.qrValue}`;

  }

  ngOnInit(): void {
  }

}
