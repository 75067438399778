import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {faSpinner, faWarning} from '@fortawesome/free-solid-svg-icons';
import {WalletService} from "../../../shared/services/wallet.service";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {MatHint, MatOption, MatSelect} from "@angular/material/select";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";

@Component({
  standalone: true,
  selector: 'app-reuqtes-transfer-funds',
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    NgxMatIntlTelInputComponent,
    MatDialogClose,
    MatHint,
    CaptchaComponent,
  ],
  templateUrl: './request-transfer-funds-dialog.component.html'
})
export class RequestTransferFundsDialogComponent {
  @ViewChild('section') section: any;
  is_human = false;
  token: string | null = null;
  maximumCharacter: number = 255;

  minAmountValue: number = 1;
  form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    amount: new FormControl(null, [Validators.required, Validators.min(this.minAmountValue)]),
    message: new FormControl(null),
  });
  formMarkAsTouched: boolean = false;

  isSaving = false;
  faSpinner = faSpinner;
  faWarning = faWarning;
  kwalletTierId: string;
  messageError: string | null = null;

  constructor(
    private walletService: WalletService,
    private dialogRef: MatDialogRef<RequestTransferFundsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.kwalletTierId = data.kwalletTierId;
    // this.form.get('email')?.valueChanges.subscribe(value => {
    //     this.messageError = null;
    // });
  }

  valueChange(event: any) {
    this.messageError = null;
  }

  get email() {
    return this.form.get('email');
  }

  get amount() {
    return this.form.get('amount');
  }

  get message() {
    return this.form.get('message');
  }

  captchaCapture(token: string) {
    this.token = token;
    this.is_human = true;
    let el: HTMLElement = this.section?.nativeElement;
    el?.click();
  }

  onSubmit(): void {
    this.isSaving = true;
    this.formMarkAsTouched = true;
    this.form.disable();
    const dataForm = this.form.getRawValue();
    const dataObj = {
      kwalletTierId: 1,
      amount: this.converterNumber(dataForm.amount),
      message: dataForm.message,
      requestTo: dataForm.email,
      captchaToken: this.token,
      requestToIsPublicAddress: false
    };
    this.walletService.requestTransfer(dataObj).subscribe((response: any) => {
      if (response.success) {
        this.dialogRef.close(response);
      } else {
        this.messageError = response.message;
      }
      this.isSaving = false;
      this.form.enable();
    }, (error) => {
      this.messageError = error.error && error.error.message ? error.error.message : error.error.errors.message;
      this.isSaving = false;
      this.form.enable();
    });
    // setTimeout(() => {
    //     this.isSaving = false;
    //     this.message = 'Error por cupon de un solo uso';
    // }, 5000)
  }

  converterNumber(value: number): number {
    return Math.round(Number(value.toFixed(2)) * 100);
  }

  close(): void {
    this.dialogRef.close(null);
  }

}
