import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogClose} from '@angular/material/dialog';
import {
  faAngleDown,
  faCircleQuestion,
  faCopy,
  faCreditCard,
  faDollar,
  faLongArrowLeft,
  faMoneyBill,
  faQrcode,
  faShareFromSquare,
  faSignOut,
  faSpinner,
  faWarning
} from '@fortawesome/free-solid-svg-icons';
import {filter, takeUntil} from "rxjs/operators";
import {forkJoin, Subject} from "rxjs";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
  ActionType,
  CardNewIntegration,
  MultipleWalletAccount,
  ProfileWalletDataType,
  SelectedZone
} from "../../shared/models";
import {ZonesService} from "../../shared/services/zones.service";
import {WalletService} from "../../shared/services/wallet.service";
import {NotificationService} from "../../shared/services/notification.service";
import {AcceptTermsAndConditionsComponent} from "../accept-terms-and-conditions/accept-terms-and-conditions.component";
import {OnfidoService} from "../../shared/services/onfido.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {animate, style, transition, trigger} from "@angular/animations";
import {WalletActionTypesEnum} from "../../shared/enum/wallet-action-types.enum";
import {
  SendMoneyToRecipientComponent
} from "../../profile/components/send-money-to-recipient/send-money-to-recipient.component";
import {
  RequestTransferFundsDialogComponent
} from "../../profile/components/request-transfer-funds-dialog/request-transfer-funds-dialog.component";
import {QrGeneratorComponent} from "../../profile/components/qr-generator/qr-generator.component";
import {
  KWalletSecurityConfig
} from "../../profile/components/k-wallet-security-config/k-wallet-security-config.component";
import {GiftCardComponent} from "../../profile/components/gift-card/gift-card.component";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {ReactiveFormsModule} from "@angular/forms";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {BreadCrumbsComponent} from "../../shared/components/bread-crumbs/bread-crumbs.component";
import {KWalletSkeletonComponent} from "../../profile/components/k-wallet-skeleton/k-wallet-skeleton.component";
import {MatTooltip} from "@angular/material/tooltip";
import {PaymentsCardsComponent} from "../payments-cards/payments-cards.component";
import {isAllowed} from "../../shared/utils/allowedRoutes";
import {AuthService} from "../../shared/services/auth.service";
import {CookieService} from "ngx-cookie-service";
import {LanguageService} from "../../shared/services/language.service";

@Component({
  selector: 'app-cards-management',
  standalone: true,
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    NgxMatIntlTelInputComponent,
    MatDialogClose,
    BreadCrumbsComponent,
    KWalletSkeletonComponent,
    MatTooltip,
    PaymentsCardsComponent,
    RouterLink
  ],
  animations: [
    trigger('fadeInAnimationPositional', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateX(50%)'}),
        animate('.5s', style({opacity: 1, transform: 'translateX(0)'}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('.1s', style({opacity: 0}))
      ]),
    ])
  ],
  templateUrl: './cards-management.component.html',
  styleUrl: './cards-management.component.css'
})
export class CardsManagementComponent implements OnInit, OnDestroy, AfterViewInit {
  faShareFromSquare = faShareFromSquare;
  faCopy = faCopy;
  faQrcode = faQrcode;
  faLongArrowLeft = faLongArrowLeft;
  faCircleQuestion = faCircleQuestion;
  faWarning = faWarning;

  faSpinner = faSpinner;


  destroy$: Subject<boolean> = new Subject<boolean>();

  selectedCard: CardNewIntegration | null = null;

  totalPages: number = 0;

  statuses = {
    completed: 'completed',
    declined: 'declined',
    processing: 'processing'
  }

  walletAccounts: MultipleWalletAccount | null = null;
  isLoadingWalletAccounts: boolean = true;
  currency: string = 'USD';
  isLoadingWalletAccount = true;
  id_copied = false;
  copied_wallet_id: string = '';

  isCheckout: boolean = false;

  actionTypes = WalletActionTypesEnum;
  tab_1 = true;
  tab_2 = false;
  faMoneyBill = faMoneyBill;
  faDollarSign = faDollar;
  faAngleDown = faAngleDown;
  selected_index = 0;
  redirect: any;
  paymentClient: any;
  selectedTab: string = '';
  auth: boolean = false;
  language!: string;
  isLanguageLoaded: boolean = false;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private authService: AuthService,
    private zonesService: ZonesService,
    private walletService: WalletService,
    private notificationService: NotificationService,
    private onfidoService: OnfidoService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private cookieService: CookieService,
    private languageService: LanguageService,
  ) {
    window.scroll({top: 0});
  }

  async ngOnInit() {
    const paramMap = this.activeRoute.snapshot.queryParamMap;
    if (isAllowed(paramMap.get('redirect'))) {
      this.language = this.languageService.selectedLanguage.value;
      this.isLanguageLoaded = true;

      this.redirect = paramMap.get('redirect');
      this.authService.saveRedirect(this.redirect);
      this.paymentClient = paramMap.get('paymentClient') ?? '';
      this.selectedTab = paramMap.get('tab') ?? '';
      if (this.selectedTab) {
        this.selectTab(Number(this.selectedTab));
      }
      if (!this.authService.getCurrentPaymentClient()) {
        this.authService.savePaymentClient(this.paymentClient);
      }
      const checkout = paramMap.get('checkout');
      this.isCheckout = !!checkout;
      const code = paramMap.get('code');
      if (code && !this.authService.isAuthenticated()) {
        await this.authService.getTokensWithCode(code as string).toPromise();
      }
      if (this.authService.getCurrentToken()) {
        this.auth = true;
        this.getWalletAccount();
      }
    } else {
      this.router.navigate(['']);
    }
  }

  navigateRequestList(walletTierId: number, label: string) {
    this.router.navigate([`/payments/1/requests/${walletTierId}`],
      {queryParams: {walletTierId: walletTierId, label}}
    );
  }

  navigateKWalletOperations(walletTierId: number, label: string) {
    this.router.navigate([`/k-wallet-operations`],
      {queryParams: {wTier: walletTierId, label}}
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  ngAfterViewInit() {
    this.activeRoute.fragment.subscribe((fragment: string | null) => {
      this.navigateInternal(fragment!);
    });
  }

  transferWalletFonds(action: ActionType, kWalletTier: ProfileWalletDataType) {
    if (!action.disabled) {
      this.dialog.open(SendMoneyToRecipientComponent, {
        width: '550px',
        disableClose: true,
        data: {
          senderWalletTierId: kWalletTier.kWalletTierId
        }
      });
    }
  }

  activateWallet() {
    if (!this.isWalletActive) {
      let dialoRef = this.dialog.open(AcceptTermsAndConditionsComponent, {
        disableClose: false,
        // position: { top: '30px' }
      });
      dialoRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: ((data: any) => {
            if (data?.modified) {
              this.getWalletAccount(true);
            } else if (!data?.modified && data.error) {
              this.notificationService.showAndSubscribe(data.error, 'VERIFY', 'CLOSE')
                .afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: ((data: any) => {
                    // console.log('data del mensaje de error del fails => ', data);
                    if (data) {
                      this.onfidoService.pushValuesInStack(this.router.url);
                      this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/verify-user-identity`]);
                    }
                  }),
                  error: (err => {
                    console.error('Error in verification notification:', err);
                  })
                });
            }
          }),
          error: (err => {
            console.error('Error in wallet activation:', err);
          })
        })
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  requestTransfer(action: ActionType, kWalletTier: ProfileWalletDataType) {
    this.dialog.open(RequestTransferFundsDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        kwalletTierId: kWalletTier.kWalletTierId,
      }
    })
      .afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response.success) {
          this.notificationService.showAndSubscribe('FUND_TRANSFER_SUCCESS_REQUESTED', 'CLOSE');
        }
      });
  }

  getWalletAccount(isReload = false) {
    this.isLoadingWalletAccounts = true;
    this.walletService.getAccountsNewImplementation()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (walletsData: MultipleWalletAccount | null) => {
          this.walletAccounts = walletsData;
          console.log('this.walletAccounts => ', this.walletAccounts);
          this.loadReceivedCount();
          this.isLoadingWalletAccounts = false;
        },
        error: () => {
          this.isLoadingWalletAccounts = false;
        }
      });
  }

  loadReceivedCount() {
    const queries: any[] = [];
    if (this.walletAccounts && this.walletAccounts?.profileWalletData && this.walletAccounts?.profileWalletData.length) {
      this.walletAccounts?.profileWalletData.forEach(it => {
        queries.push(this.walletService.getReceivedCount(it.kWalletTierId));
      });

      forkJoin(queries).subscribe((response) => {
        console.log('QUERIES: ', response);
        this.walletAccounts?.profileWalletData.forEach((it, index: number) => {
          it.receivedCount = response[index].data;
        });
      });
    }
  }

  get isWalletActive() {
    return !!this.walletService.getIsActive();
  }

  onWalletIdCopy(walletAccountData: ProfileWalletDataType) {
    navigator.clipboard.writeText(walletAccountData?.walletData?.publicAddress!).then(() => {
      this.id_copied = true;
      this.copied_wallet_id = walletAccountData?.walletData?.publicAddress;
      setTimeout(() => {
        this.id_copied = false;
        this.copied_wallet_id = '';
      }, 3000);
    })
  }

  async onWalletIdShare(walletAccountData: ProfileWalletDataType) {
    const shareData = {
      title: this.translateService.instant('MY_K_WALLET_ADDRESS'),
      text: `KWallet: ${walletAccountData?.walletData?.publicAddress!}`,
    };

    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log(err);
    }
  }

  showQrGeneratorModal(walletAccountData: ProfileWalletDataType) {
    let dialoRef = this.dialog.open(QrGeneratorComponent, {
      disableClose: false,
      // position: { top: '60px' }
      data: {
        qrValue: walletAccountData?.walletData?.publicAddress!,
        title: 'K_WALLET'
      }
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {

        }),
        error: (err => {
          console.error('Error in qr generator modal:', err);
        })
      });
  }

  navigateInternal(fragment: string = 'cards') {
    const elements = document.getElementById(fragment);
    elements?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

  goToRoute(backTo = false) {
    if (backTo) {
      if (this.isCheckout) {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/add-funds`], {queryParamsHandling: 'merge'});
      } else {
        this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/add-funds`);
      }
    } else {
      if (this.isCheckout) {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/checkout`]);
      } else {
        this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/1`);
      }
    }
  }

  redirectToActionPage(kWalletTier: ProfileWalletDataType, action: ActionType) {
    if (!action.disabled) {
      let route = null;

      switch (action.actionType) {
        case this.actionTypes.ACH_DEBIT:
          route = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'profile', 'payments', 'add-funds-ach'];
          break;
        case this.actionTypes.DEBIT_RECHARGE:
          route = ['/', 'add-funds-debit'];
          break;
        case this.actionTypes.SEND_MONEY_TO_RECIPIENT:
          this.transferWalletFonds(action, kWalletTier);
          break;
        case this.actionTypes.TRANSFER_REQUEST:
          this.requestTransfer(action, kWalletTier);
          break;
        case this.actionTypes.GIFT_CARD:
          this.applyGiftCard(action, kWalletTier);
          break;
        case this.actionTypes.WALLET_OPERATIONS:
          route = ['/', 'k-wallet-operations'];
          break;
        default:
          break;
      }

      if (route) {
        this.router.navigate(route, {
          queryParamsHandling: 'merge',
          queryParams: {wTier: kWalletTier.kWalletTierId}
        });
      }
    }
  }

  navigateToAddFounds(kWalletTierId: number) {
    const route = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'profile', 'payments', 'add-funds']
    this.router.navigate(route, {queryParamsHandling: 'merge', queryParams: {wTier: kWalletTierId}});
  }

  openModalSecurityConfig() {
    let dialoRef = this.dialog.open(KWalletSecurityConfig, {
      disableClose: false,
      // position: { top: '60px' }
      data: {
        settings: this.walletAccounts?.userSettingsDto
      }
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {
          if (data?.modified) {
            this.getWalletAccount();
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      });
  }

  selectTab(num: number) {
    switch (num) {
      case 1:
        this.tab_1 = true;
        this.tab_2 = false;
        break;
      case 2:
        this.tab_1 = false;
        this.tab_2 = true;
        break;

      default:
        break;
    }
  }

  selectIndex(i: number) {
    this.selected_index == i ? this.selected_index = -1 : this.selected_index = i;
  }

  applyGiftCard(action: ActionType, kWalletTier: ProfileWalletDataType) {
    this.dialog.open(GiftCardComponent, {
      disableClose: true,
      width: 'auto'
    }).afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {
          if (data?.status) {
            if (!data?.cancel) {
              this.notificationService.showAndSubscribe(data?.msg, 'ACCEPT', 'CANCEL');
              this.getWalletAccount(true);
            }
          } else {
            this.notificationService.showAndSubscribe(data?.msg, 'ACCEPT', 'CANCEL');
          }
        }),
        error: (err => {
          this.notificationService.showAndSubscribe('GENERIC_ERROR', 'ACCEPT', 'CANCEL');
          console.error('Error in apply giftcard:', err);
        })
      })
  }

  protected readonly faCreditCard = faCreditCard;
  protected readonly faSignOut = faSignOut;

  async logout() {
    await this.authService.signOff();
    this.authService.navigateToApp();
  }
}
