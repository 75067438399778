import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {CardNewIntegration, SelectedZone} from "../../shared/models";
import {CardService} from "../../shared/services/card.service";
import {takeUntil} from "rxjs/operators";
import {CreatePaymentMethodComponent} from "../create-payment-method/create-payment-method.component";
import {MatDialog} from "@angular/material/dialog";
import {animate, style, transition, trigger} from '@angular/animations';
import { faCirclePlus, faCheckCircle, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import { filter } from "rxjs/operators";
import {CardTypeEnum} from "../../shared/enum/card-type.enum";
import {CardsResponse} from "../../shared/models";
import {Subject, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule, NgClass} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {ReactiveFormsModule} from "@angular/forms";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  standalone: true,
  selector: 'app-payments-cards',
  templateUrl: './payments-cards.component.html',
  styleUrls: ['./payments-cards.component.scss'],
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatTooltip,
  ],
  animations: [
    trigger('fadeInAnimationPositional', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateX(50%)'}),
        animate('.5s', style({opacity: 1, transform: 'translateX(0)'}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('.1s', style({opacity: 0}))
      ]),
    ])
  ]
})
export class PaymentsCardsComponent implements OnInit, OnDestroy {
  @Input() showAddCards = false;
  @Input() showCardDetails = false;
  @Input() filterByDebits = false;
  @Input() filterByCredits = false;
  @Input() isViewCheckout = false;
  @Input() isViewRemittance = false;
  @Input() formMarkAsTouched: boolean = false;
  @Output() onCardSelection = new EventEmitter<{selectedCard: CardNewIntegration | null}>();
  @Input() selectedCardId: string | null = null;
  @Input() debitCardOnly: boolean = false;

  faCirclePlus = faCirclePlus;
  faCheckCircle = faCheckCircle;
  faCreditCard = faCreditCard;
  data: any = [
    { section: 'Section', route: '' },
    { section: 'SubSection', route: '' },
  ];
  isLoadingCards: boolean = true;
  creditCards: CardNewIntegration[] = [];
  selectedCard: CardNewIntegration | null = null;
  isTransition: boolean = true;
  card_limit: number = 0;
  current_used_cards: number = 0;
  remainingSlots: number = 0;

  CardTypeEnum = CardTypeEnum;
  destroy$: Subject<boolean> = new Subject<boolean>();

  isCheckout: boolean = false;
  showManageCardsLink = false;
  queryParamsSubscriptions!: Subscription;

  billingCountriesToHide: string[] = [];

  constructor(
    private cardService: CardService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.data = [
      { section: 'YOUR_ACCOUNT', route: '/profile/account' },
      { section: 'PROFILE_CARDS', route: '/profile/payments/cards' }
    ];
    this.getCards();
    this.queryParamsSubscriptions = this.route.queryParams.subscribe(params => {
      const checkout = params['checkout'];
      this.isCheckout = !!checkout;
      this.showManageCardsLink = this.isCheckout;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    this.queryParamsSubscriptions?.unsubscribe();
  }

  getCards() {
    this.isLoadingCards = true;
    // this.cardService.getSavedCards()
    this.cardService.getCards()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: CardsResponse) => {
          if (this.filterByCredits) {
            this.creditCards = response.cards.filter((card) => card.type === this.CardTypeEnum.CREDIT);
          } else if (this.filterByDebits) {
            this.creditCards = response.cards.filter((card) => card.type === this.CardTypeEnum.DEBIT);
          } else {
            this.creditCards = response && response.cards ? response.cards : [];
          }
          this.remainingSlots = response && response.remainingSlots ? response.remainingSlots : 0;
          this.billingCountriesToHide = response?.billingCountriesToHide || [];
          // this.current_used_cards = response.remainingSlots;
          if (this.creditCards.length) {
            if (this.selectedCardId) {
              const selectedCard = this.creditCards.find(it => it.id === this.selectedCardId);
              if (selectedCard) {
                this.selectedCard = selectedCard;
              } else {
                this.selectedCard = this.creditCards[0];
              }
            } else {
              this.selectedCard = this.creditCards[0];
            }
          } else {
            this.selectedCard = null;
          }
          this.onCardSelection.emit({selectedCard: this.selectedCard});
          this.isLoadingCards = false;
        },
        error: () => {
          this.isLoadingCards = false;
        }
      });
  }

  setSelectedCard(card: CardNewIntegration) {
    if (this.selectedCard && card.id != this.selectedCard.id) {
      this.selectedCard = card;
      this.selectedCardId = card.id;
      if (this.showCardDetails) {
        this.isTransition = false;
        setTimeout(() => {
          this.isTransition = true;
        }, 100);
      }

      this.onCardSelection.emit({selectedCard: card});
    }
  }

  addCard() {
    if (this.remainingSlots > 0) {
      this.openAddCardModal();
    }
  }

  editCard(card?: any) {
    this.openAddCardModal(card);
  }

  removeCard(cardId: string) {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        messageUppercase: true,
        action: this.cardService.removeCard(cardId),
        cancelAction: 'CANCEL',
        message: "ARE_YOU_SURE_DELETE_CARD",
        notificationSuccess: "DELETE_CARD_SUCCESS",
      }
    }).afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response.success) {
          this.getCards();
        }
      });
  }

  openAddCardModal(data?: any) {
    const isModeEdit = !!data;

    let dialoRef = this.dialog.open(CreatePaymentMethodComponent, {
      disableClose: true,
      // position: { top: '30px' },
      data: { isModeEdit, data, billingCountriesToHide: this.billingCountriesToHide, isFilterByDebits: this.filterByDebits, debitCardOnly: this.debitCardOnly },
    });
    dialoRef.afterClosed()
      .subscribe((response: any) => {
        if (response != 'close') {
          this.getCards();
        }
      })
  }

  goToProfile() {
  //   if (this.isCheckout) {
  //     this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/1`], {
  //       queryParamsHandling: 'preserve',
  //       fragment: 'cards'
  //     });
  //   } else {
  //     this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/1`], {fragment: 'cards'});
  //   }
  }
}
