import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {filter, Subject, takeUntil} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CONFIG} from "../../public/assets/config";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "./shared/services/auth.service";
import {LanguageService} from "./shared/services/language.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'payments';
  destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly config: { version: string };

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _languageService: LanguageService,
  ) {
    this.config = CONFIG;
    this.verifyVersionChanges();
    this._translateService.setDefaultLang('es');
  }

  ngOnInit() {
    this._router.events.pipe(
      takeUntil(this.destroy$),
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const paramMap = this._activatedRoute.snapshot.queryParamMap;
      this.initLanguage(paramMap);
    });

  }

  verifyVersionChanges() {
    this._router.events.pipe(
      takeUntil(this.destroy$),
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('x-refresh', 'true');
      this._httpClient
        .get<{ version: string }>("/assets/config.json", {headers})
        .pipe(takeUntil(this.destroy$))
        .subscribe(config => {
          if (config.version !== this.config.version) {
            location.reload();
          }
        });
    });
  }

  initLanguage(params: any) {
    const language: string = params.get('locale') ?? this._translateService.getBrowserLang()!;
    this._translateService.use(language);
    this._languageService.setCurrentLanguage(language);
    this._languageService.selectedLanguage.next(language);
  }
}
