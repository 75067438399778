import { Component } from '@angular/core';
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-text-skeleton',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './text-skeleton.component.html'
})
export class TextSkeletonComponent {

}
