export const environment = {
  production: false,
  name: 'dev_cloud',
  base_route: 'https://dev.api.katapulk.com',
  v1: '/api/v1',
  v2: '/api/v2',

  token_resource: '/spree_oauth/token',
  refresh_token_resource: '/spree_oauth/token',
  captcha_key: '3x00000000000000000000FF',
  services_api_url: 'https://devapi-services.katapulk.com',
  xApiClient: '63eacf1eac1e33221cf28f18',
  fingerprint_endpoint: 'https://fku.katapulk.com',
  fingerprint_api_key: 'Ldn2fEkae4kqrrgFKXK6',
  sentry_dsn: 'https://64a68162a1f70047e5b49000742b2b9b@o4504119300063232.ingest.us.sentry.io/4508138443702272',
  retry_on_fail: 2,
  isLocal: false,
  mqtt: {
    server: 'dev.broker.katapulk.com',
    port: 8084,
    protocol: 'wss',
    path: '/mqtt'
  },
  strapi_route: 'https://strapi.katapulk.com',
  allowedRoutes : [
    new URL('https://dev.katapulk.com'),
    new URL('https://dev-services.katapulk.com'),
    new URL('katapulk://app.katapulk.com/'),
    new URL('https://katapulk.page.link/'),
    new URL('http://localhost:4200'),
  ]
}
