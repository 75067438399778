<section class="relative bg-white p-5 flex-col justify-center rounded-3px">
  <div class="relative w-full
                flex flex-col items-center justify-center gap-2 lg-landscape:mt-7">
            <div class="relative items-center justify-center">
              <qr-code [value]="joinedValues" centerImageSrc="favicon.ico" centerImageSize="24"></qr-code>
            </div>

      <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:mb-3">
        {{title | translate}}
      </p>

      <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                  us:text-15px us:mb-1
                  md:mb-2.5
                  lg-landscape:text-15px lg-landscape:mb-3">
        {{qrValue | translate}}
      </p>
  </div>
</section>
