<div class="relative w-full" *ngIf="!isLoading; else skeleton">
    <div class="flex flex-col w-full" *ngIf="showRequestTransferList.length; else noData">
        <div *ngFor="let requestTransfer of showRequestTransferList; let i = index"
             class="relative w-full bg-white flex flex-col border-b-4 py-4 min-h-144px us:px-6 px-6" [ngClass]="{'border-t-4': i === 0}">
            <div class="w-full flex flex-row flex-1">
                <div class="w-full flex flex-col justify-between">
                    <div class="flex flex-row justify-between w-full border-b pb-2">
                        <div class="flex flex-col gap-1">
                            <p class="relative w-auto font-Lato-Bold text-13px leading-4 uppercase font-bold"
                                [ngClass]="requestTransfer.statusId === PendingStatusId ? 'text-gray-#3a3a3a' : 'text-gray-#979797'">{{requestTransfer.status}}</p>
                            <p class="relative w-auto font-Lato-Bold text-13px leading-4 font-bold"
                               [ngClass]="requestTransfer.statusId === PendingStatusId ? 'text-gray-#727272' : 'text-gray-#979797'">{{requestTransfer.createdAt | date: 'MM.dd.YYYY | HH:mma'}}</p>
                        </div>
                        <div class="flex flex-col items-center">
                            <p class="relative w-auto font-Lato-Bold text-13px leading-4 uppercase font-bold"
                               [ngClass]="requestTransfer.statusId === PendingStatusId ? 'text-gray-#727272' : 'text-gray-#979797'">{{ 'AMOUNT_GIFT' | translate }}</p>
                            <p *ngIf="requestTransfer && requestTransfer.amount && requestTransfer.amount > 0"
                               class="relative w-auto font-Lato-Bold font-bold"
                               [ngClass]="requestTransfer.statusId === PendingStatusId ? 'text-gray-#252525' : 'text-gray-#979797'">{{ requestTransfer.amount | currency}}</p>
                        </div>
                    </div>

                    <div class="flex flex-col py-3 gap-3">
                        <p *ngIf="status === transferRequestEnum.RECEIVED" class="relative w-auto font-Lato-Bold font-semibold text-base leading-4"
                           [ngClass]="requestTransfer.statusId === PendingStatusId ? 'text-blue-#203868' : 'text-gray-#979797'">{{ requestTransfer.requesterUserEmail }}</p>
                        <p *ngIf="status === transferRequestEnum.REQUESTED" class="relative w-auto font-Lato-Bold font-semibold text-base leading-4"
                           [ngClass]="requestTransfer.statusId === PendingStatusId ? 'text-blue-#203868' : 'text-gray-#979797'">{{ requestTransfer.senderUserEmail }}</p>

                        <p *ngIf="requestTransfer.requestMessage" class="relative w-auto font-Lato-Bold text-14px leading-4 font-bold italic"
                           [ngClass]="requestTransfer.statusId === PendingStatusId ? 'text-gray-#727272' : 'text-gray-#979797'">{{ requestTransfer.requestMessage}}</p>
                    </div>

                    <div *ngIf="status === transferRequestEnum.RECEIVED && requestTransfer.statusId === PendingStatusId" class="flex flex-col justify-between">
<!--                        *ngIf="false; else downloadButton"-->
                        <div class="flex gap-2">
                            <button (click)="accept(requestTransfer)"
                                    [disabled]="requestTransfer.isAccepting || requestTransfer.isAccepting"
                                    [ngClass]="requestTransfer.isAccepting ? 'pr-1 opacity-50 shadow-custom' : 'pr-4'"
                                    class="relative font-Lato-Regular flex items-center text-yellow-#FF6D03 border-2 h-8 pl-4
                                           border-yellow-#FF6D03 text-15px w-auto rounded-3xl hover:shadow-custom">
                                <div>{{ 'ACCEPT' | translate }}</div>
                                <fa-icon *ngIf="requestTransfer.isAccepting"
                                         class="relative flex justify-center items-center animate-spin rounded-full w-9 text-xl"
                                         [icon]="faSpinner">
                                </fa-icon>
                            </button>
                            <button (click)="reject(requestTransfer)"
                                    [disabled]="requestTransfer.isRejecting || requestTransfer.isAccepting"
                                    [ngClass]="requestTransfer.isRejecting ? 'pr-1 opacity-50 shadow-custom' : 'pr-4'"
                                    class="relative text-gray-#5d5d5d font-Lato-Regular flex items-center text-15px w-auto h-8 pl-4 rounded-3xl hover:shadow-custom">
                                <div>{{ 'REJECT' | translate }}</div>
                                <fa-icon *ngIf="requestTransfer.isRejecting"
                                         class="relative flex justify-center items-center animate-spin rounded-full w-9 text-xl"
                                         [icon]="faSpinner">
                                </fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full h-14 bg-white border-b border-l border-r items-center justify-center">
            <div class="flex font-Lato-Regular text-15px text-gray-#979797 gap-2">
                <p>{{ (pageSize * (currentPage - 1)) + 1 }}
                    - {{ totalCount < (pageSize * currentPage) ? (totalCount - (pageSize * currentPage)) + (pageSize * currentPage) : (pageSize * currentPage) }} {{ 'OF' | translate }} {{ totalCount }}</p>
                <div class="flex gap-2">
                    <button mat-icon-button (click)="back()"
                            class="py-1 px-2 rounded-full hover:bg-gray-#E7E7E7" [disabled]="currentPage === 1"
                            [ngClass]="{'opacity-50': currentPage === 1}">
                        <fa-icon class="relative cursor-pointer flex justify-center items-center text-sm"
                                 [icon]="faArrowLeft"></fa-icon>
                    </button>
                    <button mat-icon-button (click)="next()"
                            class="py-1 px-2 rounded-full hover:bg-gray-#E7E7E7" [disabled]="totalPage === currentPage"
                            [ngClass]="{'opacity-50': totalPage === currentPage}">
                        <fa-icon class="relative cursor-pointer flex justify-center items-center text-sm"
                                 [icon]="faArrowRight"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noData>
        <div class="flex w-full items-center justify-center bg-white text-gray-#979797
                us:min-h-144px
                lg-landscape:min-h-36">
            <p>{{ 'NO_DATA' | translate | uppercase }}</p>
        </div>
    </ng-template>
</div>

<ng-template #skeleton>
    <div class="relative w-full">
        <div *ngFor="let requestTransfer of [1,2,3,4,5]; let i = index" [ngClass]="{'border-t-4': i === 0}"
             class="relative w-full bg-white border-b-4 py-4 min-h-144px us:px-6 px-6">
            <div class="w-full flex flex-col gap-2">
                <div class="flex flex-row justify-between border-b pb-2">
                    <div class="flex flex-col gap-1">
                        <p class="relative bg-gray-300 h-5 w-16 rounded"></p>
                        <p class="relative bg-gray-300 h-4 w-32 rounded"></p>
                    </div>
                    <div class="flex items-center flex-col gap-1">
                        <p class="relative bg-gray-300 h-5 w-12 rounded"></p>
                        <p class="relative bg-gray-300 h-4 w-16 rounded"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <p class="relative bg-gray-300 h-4 w-40 rounded"></p>
                    <p class="relative bg-gray-300 h-3 w-1/2 rounded"></p>
                </div>

                <div *ngIf="status === transferRequestEnum.RECEIVED" class="flex flex-col">
                    <div class="flex gap-2">
                        <p class="relative bg-gray-300 h-8 rounded-full w-20"></p>
                        <p class="relative bg-gray-300 h-8 rounded-full w-20"></p>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="flex w-full h-14 bg-white border-b border-l border-r items-center justify-center pointer-events-none">
            <div class="flex font-Lato-Regular text-15px text-gray-#979797 gap-2">
                <p>{{ (pageSize * (currentPage - 1)) + 1 }}
                    - {{ totalCount < (pageSize * currentPage) ? (totalCount - (pageSize * currentPage)) + (pageSize * currentPage) : (pageSize * currentPage) }} {{ 'OF' | translate }} {{ totalCount }}</p>
                <div class="flex gap-2">
                    <button mat-icon-button (click)="back()"
                            class="py-1 px-2 rounded-full hover:bg-gray-#E7E7E7" [disabled]="currentPage === 1"
                            [ngClass]="{'opacity-50': currentPage === 1}">
                        <fa-icon class="relative cursor-pointer flex justify-center items-center text-sm"
                                 [icon]="faArrowLeft"></fa-icon>
                    </button>
                    <button mat-icon-button (click)="next()"
                            class="py-1 px-2 rounded-full hover:bg-gray-#E7E7E7" [disabled]="totalPage === currentPage"
                            [ngClass]="{'opacity-50': totalPage === currentPage}">
                        <fa-icon class="relative cursor-pointer flex justify-center items-center text-sm"
                                 [icon]="faArrowRight"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
