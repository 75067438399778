import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {GroupedStore, Store} from "../models";
import {AuthService} from "./auth.service";
import {Observable, of, Subject} from 'rxjs';
import {groupedStoresMock} from "../mocks/grouped-stores";
import {multiStoresMock} from "../mocks/multi_stores";

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  categories_facets_detector: Subject<any> = new Subject();

  private resource = '/multi_stores'; // peticion para /api/v1
  private resourceCurrentStore = '/storefront/store'; // peticion para /api/v2
  private resourceGS = '/storefront/grouped_stores'; // peticion para /api/v2

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {
  }

  getStores(): Observable<Store[]> {
    return this.apiService.get(this.resource, new HttpParams(), false, this.getHeader(), true, true)
      .pipe(
        map((stores: any[]): Store[] => {
          return stores.map((store: any) => {
            return {
              id: store.id,
              name: store.name,
              logoUrl: store.logo_file_name,
              metaKeywords: store.meta_keywords?.split(', ').slice(0, 3),
              code: store.code,
              description: store.description,
              favorite_color: store.favorite_color
            }
          });
        }),
          catchError((err: any): Observable<Store[]>  =>  {
              return of(
                  multiStoresMock.map((store: any) => {
                      return {
                          id: store.id,
                          name: store.name,
                          logoUrl: store.logo_file_name,
                          metaKeywords: store.meta_keywords?.split(', ').slice(0, 3),
                          code: store.code,
                          description: store.description,
                          favorite_color: store.favorite_color
                      };
                  })
              );
          })
      );
  }

  /**
   * Obtener tiendas agrupadas por zonas
   */
  getGroupedStores() {
    return this.apiService.get(this.resourceGS, new HttpParams(), true, this.getHeader(), true, true).pipe(
      map((response: any): GroupedStore[] => {
        const data: GroupedStore[] = [];
        response.zones.forEach((zone: { id: number, name: string }) => {
          const gs: GroupedStore = {
            zoneId: zone.id,
            zoneName: zone.name,
            stores: response.data[zone.id].map((store: any) => {
              return {
                id: store.id,
                name: store.name,
                logoUrl: store.logo_file_name,
                metaKeywords: store.meta_keywords?.split(', ').slice(0, 3),
                code: store.code,
                favorite_color: store.favorite_color
              };
            }),
          };
          data.push(gs);
        });
        return data;
      }),
        catchError((err: any): Observable<GroupedStore[]> =>  {
          const data: GroupedStore[] = [];
          groupedStoresMock.zones.forEach((zone: { id: number, name: string }) => {
            const gs: GroupedStore = {
              zoneId: zone.id,
              zoneName: zone.name,
              stores: (groupedStoresMock as any).data[zone.id].map((store: any) => {
                return {
                  id: store.id,
                  name: store.name,
                  logoUrl: store.logo_file_name,
                  metaKeywords: store.meta_keywords?.split(', ').slice(0, 3),
                  code: store.code,
                  favorite_color: store.favorite_color
                };
              }),
            };
            data.push(gs);
          });
          return of(data);
        })
    );
  }

  getStoreById(storeId: number) {
    return this.getStores().pipe(map((stores: Store[]) => stores.find((store) => store.id == storeId)));
  }

  getStoresByZoneId(zoneId?: number) {
    return this.getGroupedStores().pipe(
        map((groupedStores) => groupedStores.find((gp) => gp.zoneId === zoneId)));
  }

  getStoreByCode(storeCode: string) {
    return this.getStores().pipe(
        map((stores: Store[]) => {
            return stores.find((store) => store.code == storeCode);
        })
    );
  }

  /**
   * Get headers
   * @private
   */
  private getHeader(): HttpHeaders {
    return this.authService.getHeaderNoRefresh();
  }

}
