export class Token {
  access_token?: string;
  refresh_token?: string;
  created_at?: number;
  expires_in?: number;

  constructor(access_token?: string, refresh_token?: string, created_at?: number, expires_in?: number) {
    this.access_token = access_token || '';
    this.refresh_token = refresh_token || '';
    this.created_at = created_at;
    this.expires_in = expires_in;
  }
}
