import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  constructor(private http: HttpClient) {
  }

  getTermsAndConditions(language: string) {
    return this.http.get(environment.strapi_route + `/api/simple-content-pages?filters[Key][$eq]=terms&locale=${language}`);
  }
}
