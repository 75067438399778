<section class="relative bg-white
    us:w-full us:h-auto
    us-landscape:max-h-75vh us-landscape:overflow-y-auto
    md:max-h-max
    md-landscape:max-h-max
    lg-landscape:h-auto lg-landscape:min-h-0">
    <p class="relative font-Lato-Regular font-bold text-gray-#252525 text-17px pl-23px pr-23px h-55px items-center flex">
        {{'TRANSFER_FUNDS_TO_ANOTHER_USER' | translate}}</p>
    <form class="relative w-full gap-x-1.5 bg-gray-#f2f2f2 p-23px" [formGroup]="transferForm" [ngClass]="{'opacity-50': isSaving}">

        <div class="relative w-full flex flex-col">

            <div class="relative flex flex-1 justify-start items-center pb-6">
                <mat-select placeholder="{{ 'SEND_BY' | translate }}*" formControlName="sendBy" required (valueChange)="change($event)"
                            [ngClass]="{
                               'border-none': sendBy?.valid || (sendBy?.invalid && !(sendBy?.dirty || sendBy?.touched)),
                               'border-red-500': sendBy?.invalid && (sendBy?.invalid && (sendBy?.dirty || sendBy?.touched))
                            }"
                            class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
                    <mat-option *ngFor="let send of sends" [value]="send.value">{{send.label | translate}}</mat-option>
                </mat-select>
                <div *ngIf="sendBy?.invalid && (sendBy?.dirty || sendBy?.touched)"
                     class="absolute bottom-0.5 text-sm text-red-500">
                    <p *ngIf="sendBy?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                </div>
            </div>

            <div class="relative w-full pb-6" *ngIf="sendBy?.value === true">
                <input type="email"
                       class="w-full relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base"
                       [ngClass]="{
                              'border-none': email?.valid || (email?.invalid && !(email?.dirty || email?.touched)),
                              'border-red-500': (email?.invalid && (email?.dirty || email?.touched))
                           }"
                       placeholder="{{'EMAIL_ADDRESS' | translate}}*" formControlName="email">
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)"
                     class="absolute bottom-0.5 text-sm text-red-500">
                    <p *ngIf="email?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                    <p *ngIf="email?.hasError('email')">{{ 'INVALID_FIELD' | translate }}</p>
                </div>
            </div>

            <div class="relative w-full pb-6" *ngIf="sendBy?.value === false">
                <input type="text"
                       class="w-full relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base"
                       [ngClass]="{
                               'border-none': (receiverId?.valid || (receiverId?.invalid && !(receiverId?.dirty || receiverId?.touched))),
                               'border-red-500': (receiverId?.invalid && (receiverId?.dirty || receiverId?.touched))
                           }"
                       placeholder="{{'K_WALLET_ID_USER' | translate}}*" formControlName="receiver_id">
                <div *ngIf="receiverId?.invalid && (receiverId?.dirty || receiverId?.touched)"
                     class="absolute bottom-0.5 text-sm text-red-500">
                    <p *ngIf="receiverId?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                </div>
            </div>

            <div class="relative w-full pb-6">
                <input required type="number" [min]="1"
                       class="w-full relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base"
                       [ngClass]="{
                          'border-none': amount?.valid || (amount?.invalid && !(amount?.dirty || amount?.touched)),
                          'border-red-500': amount?.invalid && (amount?.dirty || amount?.touched)
                       }"
                       placeholder="{{'AMOUNT_GIFT' | translate}}*" formControlName="amount">
                <div *ngIf="amount?.invalid && (amount?.dirty || amount?.touched)"
                     class="absolute bottom-0.5 text-sm text-red-500">
                    <p *ngIf="amount?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                    <p *ngIf="amount?.hasError('min')">{{ 'MIN_VALIDATION' | translate: {min: 1} }}</p>
                </div>
            </div>

            <div class="relative w-full gap-4 flex flex-col border rounded border-gray-#DEDEDE mb-4">
                <div class="flex bg-gray-#D4E1EE w-full min-h-40px items-center pr-3">
                    <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full h-40px
                                            text-gray-#5d5d5d us:text-lg md:text-2xl w-12" [icon]="faWarning"></fa-icon>
                    <p class="pt-2 py-2 w-full font-Lato-Regular text-gray-#5d5d5d leading-4 text-justify
                                                    us:text-15px">{{ 'TRANSFER_SECRET_REQUEST_WARNING' | translate }}</p>
                </div>
                <div class="flex flex-1 flex-col relative pb-6 px-4">
                    <input placeholder="{{ 'PASSWORD' | translate }}*" formControlName="pass" [type]="fieldType" (keyup)="valueChange($event)"
                           [ngClass]="{
                                'border-none': messageError == null && (pass?.valid || (pass?.invalid && !(pass?.dirty && pass?.touched))),
                                'border-red-500': messageError || (pass?.invalid && (pass?.dirty && pass?.touched))
                           }"
                           class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                                  focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base">
                    <fa-icon (click)="showHidePassword()"
                             class="absolute right-4 py-3 rounded-full w-7 h-7 text-yellow-#FF6D03 cursor-pointer"
                             [icon]="iconType">
                    </fa-icon>
                    <div *ngIf="(pass?.invalid && (pass?.dirty && pass?.touched))"
                         class="absolute top-12 text-sm text-red-500">
                        <p *ngIf="pass?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                    </div>
                </div>
            </div>

            <div *ngIf="messageError" class="relative flex flex-col py-2">
                <div class="text-sm text-red-500">
                    <p *ngIf="messageError">{{ messageError }}</p>
                </div>
            </div>
        </div>
        <div class="relative w-full">
            <div class="relative w-full">
                <input
                    class="focus:ring-offset-0 relative cursor-pointer border border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0"
                    type="checkbox" formControlName="terms_cond">
                <label class="relative font-Lato-Regular text-sm lg-landscape:ml-3">
                    {{ 'I_ACCEPT_THE' | translate }}
                    <a target="_blank" routerLink="/contents/terms"
                       class="relative text-blue-#18396C underline font-Lato-Regular font-bold capitalize us:text-xs lg-landscape:text-sm lg-landscape:leading-loose">
                        {{'TERMS_CONDITIONS' | translate}}
                    </a>
                    {{ 'AND' | translate }}
                    <a target="_blank" routerLink="/customer/affidavit"
                       class="relative font-Lato-Regular text-blue-#18396C underline font-bold capitalize us:text-xs lg-landscape:text-sm lg-landscape:leading-loose">
                        {{'AFFIDAVIT_CERTIFICATION' | translate }}
                    </a>
                </label>
            </div>
        </div>
    </form>
    <div class="relative w-full flex flex-row justify-start items-center pl-23px pr-23px pb-23px bg-gray-#f2f2f2">
        <button class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer w-auto h-41px rounded-3px pl-6" type="button"
                [ngClass]="{
                    'opacity-50 cursor-not-allowed': (transferForm.invalid || isSaving || !termsConditions), 'pr-1': isSaving, 'pr-6': !isSaving,
                    'cursor-pointer': (transferForm.valid && termsConditions && !isSaving)
                }"
                [disabled]="transferForm.invalid || isSaving || !termsConditions" (click)="onSubmit()">
            <p class="relative font-Lato-Bold text-15px leading-4">{{'SEND_TRANSFER' | translate}}</p>
            <fa-icon *ngIf="isSaving"
                     class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </button>

        <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D hover:bg-gray-#EEEEEE
                hover:border-gray-500 w-20 h-41px rounded-3px ml-2" type="button" mat-dialog-close="close">
            <p class="relative font-Lato-Bold text-15px leading-4">{{'CANCEL' | translate}}</p>
        </button>
    </div>
</section>
