<div class="flex flex-col flex-1 gap-10 p-5 sm:p-12 bg-gray-100 min-h-screen justify-center
       md:flex-row md:gap-12">
  <div class="flex flex-1 flex-col">
    <div class="flex justify-center font-Lato-Regular text-gray-#5d5d5d text-15px lg:text-17px text-center">
      {{ userMessage }}
    </div>
    <div class="flex justify-center mt-1">
<!--      <button class="w-64 flex bg-yellow-#FFC35D rounded-3px flex flex-row items-center font-Lato-Regular font-semibold text-md justify-center px-6 h-11">-->
<!--        {{ 'LINK' | translate }}-->
<!--      </button>-->

      <button class="flex w-64 items-center justify-center relative bg-white font-Lato-Regular font-semibold text-gray-#3a3a3a text-md
        border-none active:ring-0 active:border-none focus:ring-0 focus:border-none px-3 py-2 rounded-3px cursor-auto">
        <div>
          {{ 'EXPIRES' | translate}}
          <span>({{modalTime.hours}}:{{modalTime.minutes}}:{{modalTime.seconds | number: '2.0-0'}})</span>
        </div>
      </button>
    </div>
    <div class="flex mt-7 font-Lato-Regular text-gray-#5d5d5d text-15px lg:text-17px justify-center text-center w-full">
      <div [innerHtml]="specialIntructions"></div>
    </div>
    <div class="flex justify-center mt-7">
      <qrcode [qrdata]="extraData" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>

    <div class="flex justify-center">
      <div class="flex w-64" [ngClass]="{'justify-between': isAndroidSystem, 'justify-around': !isAndroidSystem}">
        <div *ngIf="isAndroidSystem" class="flex justify-center mt-7">
          <button (click)="openLink()" matTooltip="{{'TRANSFERMOVIL' | translate}}"
                  class="flex bg-yellow-#FFC35D rounded-3px flex flex-row items-center font-Lato-Regular
              font-semibold text-md justify-center px-3 py-2 hover:shadow-custom">
            <fa-icon class="relative flex justify-center items-center bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faMobile">
            </fa-icon>
            <!--        <div>{{ 'TRANSFERMOVIL' | translate }}</div>-->
          </button>
        </div>
        <div class="flex justify-center mt-7">
          <button [cdkCopyToClipboard]="link" matTooltip="{{'COPY_TO_CLIPBOARD' | translate}}"
                  class="flex bg-yellow-#FFC35D rounded-3px flex flex-row items-center font-Lato-Regular
              font-semibold text-md justify-center px-3 py-2 hover:shadow-custom">
            <fa-icon class="relative flex justify-center items-center bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faCopy">
            </fa-icon>
            <!--        <div>{{ 'COPY_TO_CLIPBOARD' | translate }}</div>-->
          </button>
        </div>
        <div class="flex justify-center mt-7">
          <button (click)="share()" matTooltip="{{'SHARE' | translate}}"
                  class="flex bg-yellow-#FFC35D rounded-3px flex flex-row items-center font-Lato-Regular
              font-semibold text-md justify-center px-3 py-2 hover:shadow-custom">
            <fa-icon class="relative flex justify-center items-center bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faShare">
            </fa-icon>
            <!--        <div>{{ 'SHARE' | translate }}</div>-->
          </button>
        </div>
      </div>
    </div>

    <div>
      <button (click)="backToConfirm()"
              class="relative font-Lato-Regular text-gray-#5d5d5d px-3 lg:px-6
                w-auto h-10 lg:h-49px rounded-3xl mt-7 hover:bg-gray-#E7E7E7">
        {{ 'BACK_TO_CONFIRM' | translate }}
      </button>
    </div>
  </div>
</div>
