import {Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output} from "@angular/core";
import {interval, of, Subject, Subscription, switchMap, takeWhile, tap} from "rxjs";
import {faCopy, faMobile, faShare, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";
import {IMqttMessage, IMqttServiceOptions, MqttService} from "ngx-mqtt";
import {takeUntil} from "rxjs/operators";
import moment from "moment";
import {CurrencyAvailableInterface} from "../multi-currencies/multi-currencies.component";
import {environment} from "../../../environments/environment";
import {Order, PendingPayable} from "../../shared/models/store-order.model";
import {CheckoutService} from "../../shared/services/checkout.service";
import {NewCartService} from "../../shared/services/newcart.service";
import {LanguageService} from "../../shared/services/language.service";
import {ZonesService} from "../../shared/services/zones.service";
import {NotificationService} from "../../shared/services/notification.service";
import {FingerprintService} from "../../shared/services/fingerprint.service";
import {SelectedZone} from "../../shared/models";
import {NewCartExternalPaymentData} from "../../shared/models/new-cart-external-payment.model";
import {NewCheckoutEnum} from "../../shared/enum/checkout.enum";
import {TranslateModule} from "@ngx-translate/core";
import {QRCodeModule} from "angularx-qrcode";
import {CommonModule, DecimalPipe} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {MatDivider} from "@angular/material/divider";
import {NewCartOrderSummaryComponent} from "../new-cart-order-summary/new-cart-order-summary.component";
import {MatTooltip} from "@angular/material/tooltip";
import {AuthService} from "../../shared/services/auth.service";

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.server,
  port: environment.mqtt.port,
  protocol: (environment.mqtt.protocol === "wss") ? "wss" : "ws",
  path: environment.mqtt.path,
};

@Component({
  standalone: true,
  selector: 'app-new-cart-qr-transfer',
  templateUrl: './new-cart-qr-transfer.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    QRCodeModule,
    DecimalPipe,
    FaIconComponent,
    CdkCopyToClipboard,
    MatDivider,
    MatTooltip,
    NewCartOrderSummaryComponent,
  ],
  styleUrls: ['./new-cart-qr-transfer.component.scss']
})
export class NewCartQrTransferComponent implements OnInit, OnDestroy, OnChanges {
  @Output() next = new EventEmitter<boolean>();
  @Output() nextToPayment = new EventEmitter<boolean>();
  order!: Order;
  isLoadingOrder: boolean = true;
  faSpinner = faSpinner;
  faShare = faShare;
  faCopy = faCopy;
  faMobile = faMobile;
  languageServiceSubscription!: Subscription;
  lang: string = 'es';

  extraData!: string;
  broketTopic!: string;
  userMessage!: string;
  link!: string;
  expireInMinutes!: number;
  specialIntructions!: string;
  subscription!: Subscription;
  subscriptionOnConnect!: Subscription;

  modalFinishDate!: Date | null;
  finishModalSubs$: Subject<boolean> = new Subject<boolean>();
  modalCounterTimer$: Subscription | undefined;
  modalTime: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } = { days: 0, hours: 0, minutes: 0, seconds: 0 };

  isAndroidSystem: boolean = false;

  selectedCurrencyAvailable!: CurrencyAvailableInterface;


  constructor(
    private checkoutService: CheckoutService,
    private router: Router,
    private newCartService: NewCartService,
    private languageService: LanguageService,
    private zonesService: ZonesService,
    private notificationService: NotificationService,
    private _mqttService: MqttService,
    private fingerprintService: FingerprintService,
    private authService: AuthService
  ) {}

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  ngOnInit(): void {
    window.scroll({top: 0});
    this.lang = this.languageService.selectedLanguage.getValue();
    const bolsaTransferStorageData: NewCartExternalPaymentData = <NewCartExternalPaymentData>JSON.parse(<string>localStorage.getItem(NewCheckoutEnum.BOLSA_TRANSFER_DATA));

    if (bolsaTransferStorageData) {
      // this.isLoadingCart = false;
      this.extraData = bolsaTransferStorageData.extraData.Qr;
      this.broketTopic = bolsaTransferStorageData.broketTopic;
      this.userMessage = bolsaTransferStorageData.userMessage;
      this.expireInMinutes = bolsaTransferStorageData.expireInMinutes;
      this.link = bolsaTransferStorageData.extraData.Link;
      this.specialIntructions = bolsaTransferStorageData.specialIntructions;

      const fingerprint = this.fingerprintService.getFingerPrintValuesFromStorage();
      if (fingerprint && fingerprint.os && fingerprint.os.toLowerCase() === 'android') {
        this.isAndroidSystem = true;
      }

      this.modalFinishDate = moment(Date.now()).add(this.expireInMinutes, 'minutes').toDate();
      this.initModalTime();
      this.subscribeToTopic();
    } else {
      // this.getCart();
    }
  }

  ngOnChanges(): void {
    window.scroll({top: 0});
    this.lang = this.languageService.selectedLanguage.getValue();
    const bolsaTransferStorageData: NewCartExternalPaymentData = <NewCartExternalPaymentData>JSON.parse(<string>localStorage.getItem(NewCheckoutEnum.BOLSA_TRANSFER_DATA));
      this.newCartService.newCartOrderSubject$
          .pipe(
              switchMap((order: Order | undefined) => {
                  if (!order) {
                      return this.newCartService.getOrderPendingPayable()
                          .pipe(
                              // takeUntil(this.destroy$),
                              switchMap((pendingPayableResponse: PendingPayable) => {
                                  return this.newCartService.getOrderDetails(pendingPayableResponse.data.storeOrderId)
                              })
                          );
                  }
                  return of(order);
              })
          )
          .subscribe((order: Order | undefined) => {
              this.isLoadingOrder = false;
              if (!order) {
                  this.router.navigate(['/']);
              }
          });

    if (bolsaTransferStorageData) {
      // this.isLoadingCart = false;
      this.extraData = bolsaTransferStorageData.extraData.Qr;
      this.broketTopic = bolsaTransferStorageData.broketTopic;
      this.userMessage = bolsaTransferStorageData.userMessage;
      this.expireInMinutes = bolsaTransferStorageData.expireInMinutes;
      this.link = bolsaTransferStorageData.extraData.Link;
      this.specialIntructions = bolsaTransferStorageData.specialIntructions;

      const fingerprint = this.fingerprintService.getFingerPrintValuesFromStorage();
      if (fingerprint && fingerprint.os && fingerprint.os.toLowerCase() === 'android') {
        this.isAndroidSystem = true;
      }

      this.modalFinishDate = moment(Date.now()).add(this.expireInMinutes, 'minutes').toDate();
      this.initModalTime();
      this.subscribeToTopic();
    } else {
      // this.getCart();
    }
  }

  initModalTime() {
    setTimeout(() => {
      if (this.modalFinishDate !== null) {
        this.finishModalSubs$ = new Subject<boolean>();
        // console.log('existe carrito');
        // Inicializando fecha de expiracion del carrito a partir de los datos de session storage
        if (moment(new Date()).diff(new Date(this.modalFinishDate)) < 0) {
          // console.log('existe carrito y no ha expirado');
          this.modalCounterTimer$ = this.startModalTime()
            .pipe(takeUntil(this.finishModalSubs$))
            .subscribe((_) => {
              if (this.modalTime.hours === 0 && this.modalTime.minutes === 0 && this.modalTime.seconds === 0) {
                // console.log('tiempo expirado: munitos  => ' + this.cartTime.minutes + '---- segundos => ' + this.cartTime.seconds)
                this.stopModalTime();
              }
            });
        } else {
          // console.log('diferencia de timepo >= 0')
          this.stopModalTime();
        }
      } else {
        // console.log('no existe modal');
        // this.isLoadingCancel = true;
        this.stopModalTime();
      }
    }, 0);
  }

  startModalTime() {
    return interval(1000).pipe(
      takeWhile(() => this.modalFinishDate !== null && this.modalTime.hours >= 0 && this.modalTime.minutes >= 0 && this.modalTime.seconds >= 0),
      tap(() => {
        this.updateModalTime();
      }),
    );
  }

  updateModalTime() {
    if (this.modalFinishDate) {
      const now = new Date();
      const expirationModalDate = this.modalFinishDate;
      const diff = expirationModalDate.getTime() - now.getTime();
      // console.log(diff);
      if (diff <= 0) {
        // this.isLoadingCancel = true;
        this.stopModalTime();
        return;
      }
      // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const mins = Math.floor(diff / (1000 * 60));
      const secs = Math.floor(diff / 1000);

      // La diferencia que se asignará para mostrarlo en la pantalla
      this.modalTime.days = days;
      this.modalTime.hours = hours - days * 24;
      this.modalTime.minutes = mins - hours * 60;
      this.modalTime.seconds = secs - mins * 60;
    } else {
      // console.log('NO HAY CARRITO PARA ACTUALIZAR TIEMPO');
      this.stopModalTime();
    }
  }

  stopModalTime(showMessageExpiration = true) {
    this.modalTime = {
      hours: 0,
      minutes: 0,
      seconds: 0,
      days: 0
    };

    this.modalFinishDate = null;
    this.modalCounterTimer$?.unsubscribe();
    if (showMessageExpiration) {
      this.notificationService.showAndSubscribe('YOU_EXTERNAL_PAYMENT_CODE_HAS_EXPIRED', 'CLOSE').afterClosed().subscribe((response) => {
        if (response) {
          this.backToPayment();
        } else {
          this.backToPayment();
        }
      });
    }
  }

  async share(): Promise<any> {
    const shareData = {
      title: 'Transfermóvil',
      text: this.link,
      // url: this.referenceUrl,
    };

    try {
      await navigator.share(shareData)
    } catch (err) {
      console.log(err);
    }
  }

  private subscribeToTopic() {
    // this._mqttService.state.subscribe(state => {
    //   if (state === MqttConnectionState.CONNECTED) {}
    // });
    this._mqttService.connect(MQTT_SERVICE_OPTIONS);

    this.subscriptionOnConnect = this._mqttService.onConnect
      .subscribe(connack => {
        this.subscription = this._mqttService.observe(this.broketTopic)
          .subscribe((data: IMqttMessage) => {
            this.stopModalTime(false);
            let item = JSON.parse(data.payload.toString());
            if (item.Succeed && item.Message) {
              this.notificationService.showAndSubscribe(item.Message, 'ACCEPT').afterClosed().subscribe((response: any) => {
                if (response) {
                  this.navigateNext();
                } else {
                  this.navigateNext();
                }
              })
            } else {
              this.notificationService.showAndSubscribe(item.Message, 'CLOSE').afterClosed().subscribe((response: any) => {
                  if (response) {
                    this.backToPayment();
                  } else {
                    this.backToPayment();
                  }
                }
              )
            }
          });
      });
  }

  openLink() {
    window.location.replace(this.link);
  }

  async navigateNext() {
    this.newCartService.cleanStorage();
    this.newCartService.removeCartValuesInStorage();
    await this.authService.signOff();
    this.authService.navigateToApp({redirect: '/profile/orders', cleanCart: true});
  }
  backToConfirm() {
    this.next.emit(false);
  }

  backToPayment() {
    this.nextToPayment.emit(false);
  }

  ngOnDestroy(): void {
    this.languageServiceSubscription?.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionOnConnect) {
      this.subscriptionOnConnect.unsubscribe();
    }
    if (this.modalCounterTimer$) {
      this.modalCounterTimer$.unsubscribe();
    }
    this._mqttService.disconnect(true);

    this._mqttService.onClose.emit();
    this._mqttService.onEnd.emit();
  }
}
