<form class="relative bg-gray-#f2f2f2 overflow-y-auto rounded-3px w-auto pb-5" [formGroup]="form">
    <div class="relative bg-white w-full flex items-center h-55px pl-23px pr-23px">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 text-17px">
            {{'REQUEST_FUNDS_TRANSFER' | translate}}:
        </p>
    </div>
    <div class="relative w-full pl-23px pr-23px pt-18px">
        <div class="flex bg-gray-#D4E1EE w-full min-h-40px items-center pr-3">
            <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full h-40px
                                            text-gray-#5d5d5d us:text-lg md:text-2xl w-12" [icon]="faWarning"></fa-icon>
            <p class="pt-2 py-2 w-full font-Lato-Regular text-gray-#5d5d5d leading-4 text-justify
                                                    us:text-15px">{{ 'WARNING_TRANSFER_REQUEST' | translate }}</p>
        </div>

        <div class="flex flex-1 flex-col relative pb-6 mt-6">
            <input placeholder="{{ 'ELECTRONIC_EMAIL' | translate }}*" formControlName="email" id="email" type="email" (keyup)="valueChange($event)"
                   [ngClass]="{
                        'border-none': (email?.valid || (email?.invalid && !(email?.dirty || email?.touched))),
                        'border-red-500': (email?.invalid && formMarkAsTouched) || (email?.invalid && (email?.dirty || email?.touched))
                   }"
                   class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base">
            <div *ngIf="email?.invalid && (email?.dirty || email?.touched)"
                 class="absolute top-12 text-sm text-red-500">
                <p *ngIf="email?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                <p *ngIf="email?.hasError('email')">{{ 'INVALID_FIELD' | translate }}</p>
            </div>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
            <input placeholder="{{ 'AMOUNT_GIFT' | translate }}*" formControlName="amount" id="amount" (keyup)="valueChange($event)" (change)="valueChange($event)"
                   [ngClass]="{
                        'border-none': (amount?.valid || (amount?.invalid && !(amount?.dirty || amount?.touched))),
                        'border-red-500': (amount?.invalid && formMarkAsTouched) || (amount?.invalid && (amount?.dirty || amount?.touched))
                   }"
                   class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="number" [min]="minAmountValue">
            <div *ngIf="amount?.invalid && (amount?.dirty || amount?.touched)"
                 class="absolute top-12 text-sm text-red-500">
                <p *ngIf="amount?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                <p *ngIf="amount?.hasError('min')">{{ 'MIN_VALIDATION' | translate: {min: minAmountValue} }}</p>
            </div>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
            <textarea placeholder="{{'MESSAGE_OPTIONAL' | translate}}" formControlName="message" [rows]="4" [maxlength]="maximumCharacter"
                      class="relative px-3 bg-white text-gray-#252525 font-Lato-Regular w-full active:ring-0
                         placeholder-gray-#9D9D9D active:outline-none focus:ring-0 active:border-none
                         focus:outline-none outline-none ring-0 border-none min-h-47px text-base">
            </textarea>
            <mat-hint class="text-gray-#979797 text-13px pt-1">{{ 'MAXIMUM_VALUE_CHARACTER' | translate: {value: maximumCharacter} }}</mat-hint>
        </div>

        <div class="relative w-full h-auto py-1 flex justify-start items-center">
            <app-captcha class="relative w-full" (onCaptchaCheck)="captchaCapture($event)"></app-captcha>
        </div>

        <div *ngIf="messageError" class="relative flex flex-col py-2">
            <div class="text-sm text-red-500">
                <p *ngIf="messageError">{{ messageError }}</p>
            </div>
        </div>
    </div>

    <div class="relative w-full flex flex-row justify-start items-center mt-7 pl-23px pr-23px">
        <button class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer w-auto h-41px rounded-3px pl-6"
                (click)="onSubmit()" [disabled]="form.invalid || isSaving || !is_human" type="button"
                [ngClass]="{'opacity-50 cursor-not-allowed': form.invalid || isSaving || !is_human, 'pr-1': isSaving, 'pr-6': !isSaving}">
            <p class="relative font-Lato-Bold text-15px leading-4">
                {{'SEND_REQUEST' | translate}}
            </p>
            <fa-icon *ngIf="isSaving"
                     class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </button>
        <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D hover:bg-gray-#EEEEEE
                hover:border-gray-500 w-20 h-41px rounded-3px ml-2" type="button" mat-dialog-close="close">
            <p class="relative font-Lato-Bold text-15px leading-4">{{'CANCEL' | translate}}</p>
        </button>
    </div>
</form>
