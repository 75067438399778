<section #section class="relative min-h-auto h-auto p-4 bg-gray-#f2f2f2 rounded-lg
us:w-90vw
us-landscape:w-422px
md:w-422px
md-landscape:w-422px
lg:w-422px
lg-landscape:w-422px">
    <div class="absolute top-2 right-3 flex justify-center items-center">
        <div class="absolute w-full h-full cursor-pointer z-10" (click)="close({status:true, msg:'', cancel:true})">
        </div>
        <fa-icon class=" text-yellow-#ff4103 lg-landscape:text-base z-0" [icon]="faClose"></fa-icon>
    </div>

    <p class="relative font-Lato-Regular text-19px font-semibold w-full border-gray-#9D9D9D border-b pb-2">
        {{'REDEM_CODE' | translate}}
    </p>
    <!-- <p *ngIf="is_human"
        class="relative font-Lato-Regular text-19px font-semibold w-full border-gray-#9D9D9D border-b pb-2">
        {{'REDEM_CODE' | translate}}
    </p>
    <p *ngIf="!is_human"
        class="relative font-Lato-Regular text-17px font-semibold w-full border-gray-#9D9D9D border-b pb-2">
        {{'COMPLETE_CAPTCHA' | translate}} :
    </p> -->

    <form [formGroup]="giftCardForm" class="relative mb-1
            us:w-full us:mt-19px us:gap-y-4
                lg-landscape:w-full lg-landscape:mt-7 lg-landscape:gap-y-1" action="">

        <div class="relative w-full h-auto flex justify-start items-center flex-wrap mb-7">
            <input formControlName="keyCode" id="masked" mask="0000-0000-0000-0000" maxlength="19" class="relative font-Lato-Regular w-full
            us:text-15px us:h-12 us:rounded-3px
            us-landscape:h-auto
            md:h-auto
            lg-landscape:text-15px px-4 py-3 bg-white
            active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
            focus:outline-none outline-none ring-0 border-none h-47px text-base" type="text"
                placeholder="{{'CODE' | translate}}">
            <p class="absolute top-103% w-full font-Lato-Regular text-sm text-red-300 h-3 my-1">
                <span
                    *ngIf="this.giftCardForm.controls['keyCode'].hasError('required') && this.giftCardForm.controls['keyCode'].touched">
                    {{'REQUIRED' | translate}}
                </span>
            </p>
            <p class="absolute top-103% w-full font-Lato-Regular text-sm text-red-300 h-3 my-1">
                <span *ngIf="!this.giftCardForm.controls['keyCode'].hasError('required') 
                    && this.giftCardForm.controls['keyCode'].hasError('pattern')
                    && this.giftCardForm.controls['keyCode'].touched">
                    {{'WRONG_FORMAT' | translate}}
                </span>
            </p>
        </div>
    </form>

    <div class="relative w-full h-auto py-1 flex justify-start items-center">
        <app-captcha class="relative w-full" (onCaptchaCheck)="captchaCapture($event)"></app-captcha>
    </div>

    <div class="relative flex justify-start items-center mt-3 gap-4 cursor-pointer">
        <button (click)="submit()" [disabled]="!giftCardForm.valid || !is_human"
            [ngClass]="{'opacity-50 cursor-not-allowed': !giftCardForm.valid || !is_human}" class="flex items-center px-4 justify-center relative bg-yellow-#FFC35D font-Lato-Regular font-semibold text-sm border-none active:ring-0
        active:border-none focus:ring-0 focus:border-none py-2 rounded-3px focus:outline-none">{{'ACCEPT' |
            translate}}
            <fa-icon *ngIf="is_loading_request"
                class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 text-xl text-white"
                [icon]="faSpinner">
            </fa-icon>
        </button>
        <button (click)="close({status:true, msg:'', cancel:true})"
            class="relative bg-white font-Lato-Regular font-semibold text-sm border-none active:ring-0 active:border-none focus:ring-0 focus:border-none px-3 py-2 rounded-3px">
            {{'CANCEL' | translate}}
        </button>
    </div>
</section>