import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, retry, Subject, throwError} from "rxjs";
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Stack, WalletResponse} from "../models";
import {environment} from "../../../environments/environment";
import {AuthService} from "./auth.service";
import {TranslateService} from "@ngx-translate/core";
import {catchError, map, takeUntil} from "rxjs/operators";
import {NotificationService} from "./notification.service";
import {OnfidoResponseModel} from "../models/onfido-response.model";
import {IMqttMessage, IMqttServiceOptions, MqttService} from "ngx-mqtt";
import {VerificationModel} from "../models/verification.model";
import {StorageService} from "./storage.service";
import {LanguageService} from "./language.service";

@Injectable({
  providedIn: 'root'
})
export class OnfidoService {
  private resource = '/onfido/generate-verify-token'; // peticion para /api/v2
  public routeStack: Stack;
  private ROUTE_STACK_STORAGE = 'route_stack';

  private serviceOnfidoRoute = `${environment.services_api_url}${environment.v1}/Identity/Account/StartVerification`;  // api/v1
  private serviceVerification = `${environment.services_api_url}${environment.v1}/Identity/Account/Verification`;  // api/v1
  private startFlowUrl = `${environment.services_api_url}${environment.v1}/Identity/Account/GenerateWorkflowUrl`;  // api/v1
  maxRetries = environment.retry_on_fail;
  ROUTE_VERIFICATION_BROKER_TOPIC = 'verification_broker_topic';
  ROUTE_REMITTANCE_VERIFICATION_DOCUMENT_BROKER_TOPIC = 'remittance_verification_document_broker_topic';
  ROUTE_REMITTANCE_VERIFICATION_FACE_BROKER_TOPIC = 'remittance_verification_face_broker_topic';
  destroyComponent$: Subject<boolean> = new Subject<boolean>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  destroyRemittanceDocument$: Subject<boolean> = new Subject<boolean>();
  destroyRemittanceFace$: Subject<boolean> = new Subject<boolean>();

  warningSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private authService: AuthService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private _mqttService: MqttService,
  ) {
    const routeStack = this.getStackFromStorage();
    if (routeStack) {
      const {stack, count} = routeStack;
      this.routeStack = new Stack(stack, count);
    } else {
      this.routeStack = new Stack();
    }
  }

  /**
   *
   * @param locale
   */
  getToken(locale: string): Observable<any> {
    const httpParams = new HttpParams().set('client_app', 'web').set('locale', locale);
    // annadir el show error en false
    return this.apiService.get(this.resource, httpParams);
  }

  private saveStackInStorage() {
    this.storageService.set(this.ROUTE_STACK_STORAGE, this.routeStack);
  }

  private getStackFromStorage() {
    return this.storageService.get(this.ROUTE_STACK_STORAGE);
  }

  private removeStackFromStorage() {
    this.storageService.remove(this.ROUTE_STACK_STORAGE);
  }

  pushValuesInStack(value: string) {
    this.routeStack.push(value);
    this.saveStackInStorage();
  }

  clearRouteStack() {
    this.routeStack.clear();
    this.removeStackFromStorage();
  }

  /*
   * New approach for Onfido verification
   */

  /**
   * Get headers
   * @private
   */
  private getHeader(): HttpHeaders {
    let httpHeaders = this.authService.getHeader();
    httpHeaders = httpHeaders
      .set('Accept-Language', this.languageService.selectedLanguage.value);
    return httpHeaders;
  }

  startFlow(errorCode: string, redirect: string, isForMobile: boolean = false) {
      return this.http.post(this.startFlowUrl, {errorCode, redirect, isForMobile}, {headers: this.getHeader()})
          .pipe(
              // @ts-ignore
              map((response: any) => {
                  if (response.fails) {
                      this.showError(response.message, true);
                  }
                  // else {
                  //     this.saveTopicOnStorage(this.ROUTE_VERIFICATION_BROKER_TOPIC, (<OnfidoResponseModel>response.data.verificationFlowData).brokerTopic);
                  // }
                  return response.data;
              }),
              catchError((error) => {
                  return this.handleErrors(error, true);
              })
          );
  }

  startVerification(data: { firstname: string, lastname: string, isMobileApp: boolean, redirect: string }): Observable<any> {
    return this.http.post(`${this.serviceOnfidoRoute}`, data, {headers: this.getHeader()})
      .pipe(
        // @ts-ignore
        map((response: WalletResponse) => {
          if (response.fails) {
            this.showError(response.message, true);
          }
          // else {
          //   this.saveTopicOnStorage(this.ROUTE_VERIFICATION_BROKER_TOPIC, (<OnfidoResponseModel>response.data).brokerTopic);
          // }
          return <OnfidoResponseModel>response.data;
        }),
        retry(this.maxRetries),
        catchError((error) => {
          return this.handleErrors(error, true);
        })
      );
  }

  verification(): Observable<any> {
    return this.http.get(`${this.serviceVerification}`, {headers: this.getHeader()})
      .pipe(
        // @ts-ignore
        map((response: VerificationModel) => {
          if (response.fails) {
            this.showError(response.message, true);
          }
          return response.data;
        }),
        retry(this.maxRetries),
        catchError((error) => {
          return this.handleErrors(error, true);
        })
      );
  }

  private handleErrors(error: any, showError: boolean = true): any {
    const msg = error?.error?.error || error?.error?.message || null;
    this.showError(msg, showError);

    window.scrollTo(0, 0);
    return throwError(error);
  }

  private showError(msg: string, show: boolean): void {
    if (show && msg) {
      this.notificationService.showAndSubscribe(msg, 'CLOSE');
      console.log('Response error => ', msg);
    } else {
      if (show) {
        this.notificationService.showAndSubscribe('GENERIC_ERROR', 'CLOSE');
      }
    }
  }

  get routeVerificationBrokerTopic(): string {
    return this.storageService.get(this.ROUTE_VERIFICATION_BROKER_TOPIC, true);
  }

  get routeRemittanceVerificationDocumentBrokerTopic(): string {
    return this.storageService.get(this.ROUTE_REMITTANCE_VERIFICATION_DOCUMENT_BROKER_TOPIC, true);
  }

  get routeRemittanceVerificationFaceBrokerTopic(): string {
    return this.storageService.get(this.ROUTE_REMITTANCE_VERIFICATION_FACE_BROKER_TOPIC, true);
  }

  subscribeToTopic() {
    // this._mqttService.state.subscribe(state => {
    //   if (state === MqttConnectionState.CONNECTED) {}
    // });

    const brokerTopic = this.routeVerificationBrokerTopic;
    const remittanceDocumentBrokerTopic = this.routeRemittanceVerificationDocumentBrokerTopic;
    const remittanceFaceBrokerTopic = this.routeRemittanceVerificationFaceBrokerTopic;

    this.destroy$ = new Subject<boolean>();
    this.destroyRemittanceDocument$ = new Subject<boolean>();
    this.destroyRemittanceFace$ = new Subject<boolean>();

    if (!brokerTopic) {
      this.destroySubscription();
    }

    if (!remittanceDocumentBrokerTopic) {
      this.destroyRemittanceDocumentSubscription();
    }

    if (!remittanceFaceBrokerTopic) {
      this.destroyRemittanceFaceSubscription();
    }

    if (!brokerTopic && !remittanceDocumentBrokerTopic && !remittanceFaceBrokerTopic) {
      return;
    }

    const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
      hostname: environment.mqtt.server,
      port: environment.mqtt.port,
      protocol: (environment.mqtt.protocol === "wss") ? "wss" : "ws",
      path: environment.mqtt.path,
    };

    this._mqttService.connect(MQTT_SERVICE_OPTIONS);

    this._mqttService.onConnect
      .pipe(takeUntil(this.destroyComponent$))
      .subscribe(connack => {
        if (brokerTopic) {
          this.subscribeToBrokerTopic(brokerTopic, this.destroy$, this.destroySubscription, this.ROUTE_VERIFICATION_BROKER_TOPIC);
        }
        if (remittanceDocumentBrokerTopic) {
          this.subscribeToBrokerTopic(remittanceDocumentBrokerTopic, this.destroyRemittanceDocument$, this.destroyRemittanceDocumentSubscription, this.ROUTE_REMITTANCE_VERIFICATION_DOCUMENT_BROKER_TOPIC);
        }
        if (remittanceFaceBrokerTopic) {
          this.subscribeToBrokerTopic(remittanceFaceBrokerTopic, this.destroyRemittanceFace$, this.destroyRemittanceFaceSubscription, this.ROUTE_REMITTANCE_VERIFICATION_FACE_BROKER_TOPIC);
        }
      });
  }

  private destroySubscription() {
    if (this.destroy$ !== undefined && !this.destroy$.closed) {
      this.destroy$.next(true);
      this.destroy$.complete();
      this.destroy$.unsubscribe();
    }

  }

  private destroyRemittanceDocumentSubscription() {
    if (this.destroyRemittanceDocument$ !== undefined && !this.destroyRemittanceDocument$.closed) {
      this.destroyRemittanceDocument$.next(true);
      this.destroyRemittanceDocument$.complete();
      this.destroyRemittanceDocument$.unsubscribe();
    }
  }

  private destroyRemittanceFaceSubscription() {
    if (this.destroyRemittanceFace$ !== undefined && !this.destroyRemittanceFace$.closed) {
      this.destroyRemittanceFace$.next(true);
      this.destroyRemittanceFace$.complete();
      this.destroyRemittanceFace$.unsubscribe();
    }
  }

  saveTopicOnStorage(route: string, data: string) {
    this.storageService.set(route, data, true);
  }

  subscribeToBrokerTopic(topicName: string, destroyForTopic: Subject<boolean>, destroyForTopicAction: any, topicRoute: string) {
      console.log('TOPIC_NAME: ', topicName);
      this._mqttService.observe(topicName)
      .pipe(takeUntil(destroyForTopic))
      .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());
        console.log('ITEM: ', item.Message);
        this.storageService.remove(topicRoute, true);
        destroyForTopicAction.bind(this)();
        if (item.Succeed && item.Message) {
          this.notificationService.showAndSubscribe(item.Message || 'VERIFICATION_PROCESS_COMPLETED_SUCCESSFULLY', 'ACCEPT');
          this.warningSubject.next(!this.warningSubject.getValue());
        } else {
          this.notificationService.showAndSubscribe(item.Message || 'VERIFICATION_PROCESS_FAILED', 'CLOSE');
        }
      });
  }


  /*
   * New approach for Onfido verification ---- End
   */
}
