import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {faEye, faEyeSlash, faSpinner, faWarning} from '@fortawesome/free-solid-svg-icons';
import {WalletService} from "../../../shared/services/wallet.service";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";

@Component({
  selector: 'app-accept-transfer-request',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FaIconComponent,
    ReactiveFormsModule
  ],
  templateUrl: './accept-tranfer-request-dialog.component.html'
})
export class AcceptTranferRequestDialogComponent {
  form: FormGroup = new FormGroup({
    pass: new FormControl(null, [Validators.required]),
  });
  formMarkAsTouched: boolean = false;
  requestTransferId: string;
  isSaving = false;
  faSpinner = faSpinner;
  messageError: string | null = null;
  showPassword: boolean = true;
  iconType = faEyeSlash;
  fieldType = 'password';
  faWarning = faWarning;

  constructor(
    private walletService: WalletService,
    private dialogRef: MatDialogRef<AcceptTranferRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.requestTransferId = data.id;
    // this.form.get('pass')?.valueChanges.subscribe(value => {
    //     this.messageError = null;
    // });
  }

  get pass() {
    return this.form.get('pass');
  }

  valueChange(event: any) {
    this.messageError = null;
  }

  onSubmit(): void {
    this.isSaving = true;
    this.formMarkAsTouched = true;
    this.form.disable();
    const dataForm = this.form.getRawValue();
    const dataObj = {id: this.requestTransferId, transferSecret: dataForm.pass};

    this.walletService.accept(dataObj).subscribe((response: any) => {
      if (response.success) {
        this.dialogRef.close(response);
      } else {
        this.messageError = response.message;
      }
      this.isSaving = false;
      this.form.enable();
    }, (error: any) => {
      this.messageError = error.error.message;
      this.isSaving = false;
      this.form.enable();
    });
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
    if (!this.showPassword) {
      this.iconType = faEye;
      this.fieldType = 'text';
    } else {
      this.iconType = faEyeSlash;
      this.fieldType = 'password';
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  // protected readonly faEyeSlash = faEyeSlash;
}
