<section class="relative bg-white
us:w-full us:h-auto us:pl-4 us:pr-5 us:pt-5 us:pb-8
us-landscape:w-568px us-landscape:pb-5 us-landscape:max-h-75vh us-landscape:overflow-y-auto
md:w-528px md:max-h-max
md-landscape:w-528px md-landscape:max-h-max
lg-landscape:w-603px lg-landscape:h-auto lg-landscape:min-h-0 lg-landscape:p-23px">
  <p class="relative uppercase font-Lato-Regular font-semibold
    us:text-sm us:tracking-1.75px
    lg-landscape:text-sm lg-landscape:tracking-1.75px">{{'SECURITY_CONFIG' | translate}}</p>
  <div class="relative w-full border-t border-t-gray-#E0E0E0
    us:mt-3
    lg-landscape:mt-3"></div>
  <form class="relative w-full grid
                us:grid-cols-1 us:gap-y-6 us:mt-6
                us-landscape:grid-cols-1 us-landscape:gap-x-1.5 us-landscape:mt-4
                md:grid-cols-1 md:gap-x-1.5 md:mt-10
                 lg-landscape:grid-cols-1 lg-landscape:gap-x-6px lg-landscape:mt-5 lg-landscape:gap-y-0" [formGroup]="securityConfigForm">

    <div class="relative w-full flex flex-col">
      <div class="flex flex-col relative pb-6">
        <mat-select placeholder="{{ 'CONFIRMATION_OPERATION' | translate }}*" formControlName="walletOperationConfirmationTypeId"
                    class="relative font-Lato-Regular w-full bg-white text-gray-#727272
                  us:text-15px us:h-12 us:rounded-3px
                  us-landscape:h-auto
                  md:h-auto
                  lg-landscape:text-15px px-4 py-3
                  active:ring-0 active:outline-none focus:ring-0
                  focus:outline-none outline-none ring-0 border border-gray-#929292 h-47px text-base col-span-1"
                    *ngIf="!isLoadingWalletOperationConfirmationType"
        >
          <mat-option
            *ngFor="let woc of walletOperationConfirmationType$"
            [value]="woc.id"
          >
            {{woc.name}}
          </mat-option>
        </mat-select>
        <div *ngIf="isLoadingWalletOperationConfirmationType" class="relative flex flex-col flex-1 justify-start">
          <div class="flex flex-1 px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:outline-none focus:ring-0
                        focus:outline-none outline-none ring-0 border h-47px text-base col-span-1 text-gray-#727272 min-h-48px">
            {{ 'CONFIRMATION_OPERATION' | translate }}*
            <mat-spinner class="spinner absolute right-3" diameter="25"></mat-spinner>
          </div>
        </div>
        <div *ngIf="walletOperationConfirmationTypeId?.invalid && (walletOperationConfirmationTypeId?.dirty || walletOperationConfirmationTypeId?.touched)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="walletOperationConfirmationTypeId?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
        </div>
      </div>


      <div class="relative w-full">
        <div class="relative w-full pt-4">
          <input
            class="focus:ring-offset-0 relative cursor-pointer border border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0"
            type="checkbox" formControlName="enableVerificationOnPurchase">
          <label class="relative font-Lato-Regular text-sm lg-landscape:ml-3">
            {{'ENABLE_VERIFICATION_ON_PURCHASE' | translate}}
          </label>
        </div>
      </div>
    </div>
  </form>

  <div class="relative w-full flex
    us:justify-start us:mt-6
    lg-landscape:mt-5 lg-landscape:justify-end">
    <button class="relative bg-yellow-#FFC35D flex items-center
        us:w-130px us:h-41px us:rounded-3px
        lg-landscape:w-130px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px" type="button"
            [ngClass]="{'opacity-50': (securityConfigForm.invalid || isSaving), 'cursor-pointer': (securityConfigForm.valid && !isSaving)}"
            [disabled]="securityConfigForm.invalid || isSaving" (click)="onSubmit()">
      <fa-icon class="relative
            us:w-22% us:ml-2
         lg-landscape:w-22% lg-landscape:ml-2" [icon]="faShareFromSquare">
      </fa-icon>
      <p class="relative font-Lato-Bold
            us:text-15px us:leading-4
         lg-landscape:text-15px lg-landscape:leading-4">{{'SAVE' | translate}}</p>
      <fa-icon *ngIf="isSaving"
               class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>

    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D
        us:w-111px us:h-41px us:rounded-3px
        lg-landscape:w-111px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px ml-2" type="button"
            mat-dialog-close="close">
      <p class="relative font-Lato-Bold
         text-15px leading-4">{{'CANCEL' | translate}}</p>
    </button>
  </div>
</section>
