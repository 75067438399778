import {Routes} from '@angular/router';
import {CardsManagementComponent} from "./components/cards-management/cards-management.component";
import {
  KWalletAddFundsDebitComponent
} from "./profile/components/k-wallet-add-funds-debit/k-wallet-add-funds-debit.component";
import {KWalletOperationsComponent} from "./profile/components/k-wallet-operations/k-wallet-operations.component";
import {RequestTransfersComponent} from "./profile/components/request-transfers/request-transfers.component";
import {
  NewCartPaymentMethodContainerComponent
} from "./components/new-cart-payment-method-container/new-cart-payment-method-container.component";
import {LoadingComponent} from "./shared/components/loading/loading.component";
import {PaymentSuccessComponent} from "./components/payment-success/payment-success.component";
import {PaymentRequestsComponent} from "./profile/components/payment-requests/payment-requests.component";
import {UserPaymentsComponent} from "./profile/components/user-payments/user-payments.component";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {TermsAndConditionsComponent} from "./shared/components/terms-and-conditions/terms-and-conditions.component";

export const routes: Routes = [
  {
    path: '', component: NewCartPaymentMethodContainerComponent,
  },
  {
    path: 'profile', component: CardsManagementComponent,
  },
  {path: 'add-funds-debit', component: KWalletAddFundsDebitComponent},
  {path: 'k-wallet-operations', component: KWalletOperationsComponent},
  {path: 'payments/:id/requests/:tierId', component: RequestTransfersComponent},
  {path: 'payment-success', component: PaymentSuccessComponent},
  {path: 'payment-requests', component: PaymentRequestsComponent},
  {path: 'user-payments', component: UserPaymentsComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: 'terms-and-conditions/:id', component: TermsAndConditionsComponent},
  {path: '**', component: LoadingComponent}
];
