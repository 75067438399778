<form class="relative bg-gray-#f2f2f2 rounded-3px overflow-y-auto
  us:w-screen us:h-screen us:w-90vw us:max-h-81vh us:pb-14
  us-landscape:w-auto us-landscape:h-screen
  md:w-440px md:h-auto md:max-h-full md:pb-5
  md-landscape:w-440px md-landscape:h-auto md-landscape:max-h-full md-landscape:pb-5
  lg-landscape:w-440px lg-landscape:h-auto lg-landscape:max-h-full lg-landscape:pb-5
  xl-landscape:h-auto xl-landscape:w-440px" [formGroup]="verificationForm">
  <!-- h-37.86vw -->
  <div class="relative bg-white w-full flex items-center
    h-55px pl-23px pr-23px">
    <p class="relative font-Lato-Regular font-bold text-gray-#252525
        text-17px">
      {{'VERIFY_YOUR_ACCOUNT' | translate}}:
    </p>
  </div>
  <div class="relative w-full pl-23px pr-23px pt-18px">
    <div class="relative w-full flex flex-col">

      <div class="grid gap-2
      us:grid-cols-1
      us-landscape:grid-cols-2
      md:grid-cols-2
      md-landscape:grid-cols-2">
        <div class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'NAME' | translate }}*" formControlName="firstname" id="firstname"
                 [ngClass]="{
                  'border-none': firstname?.valid || (firstname?.invalid && !(firstname?.dirty || firstname?.touched)),
                  'border-red-500': (firstname?.invalid && formMarkAsTouched) || (firstname?.invalid && (firstname?.dirty || firstname?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="firstname?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="firstname?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
            <p *ngIf="firstname?.hasError('pattern') && !firstname?.hasError('maxlength')">
              {{ 'CHECK_WORK_SPACING' | translate }}
            </p>
          </div>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'SURNAMES' | translate }}*" formControlName="lastname" id="lastname"
                 [ngClass]="{
                  'border-none': lastname?.valid || (lastname?.invalid && !(lastname?.dirty || lastname?.touched)),
                  'border-red-500': (lastname?.invalid && formMarkAsTouched) || (lastname?.invalid && (lastname?.dirty || lastname?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                        focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="lastname?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="lastname?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
            <p *ngIf="lastname?.hasError('pattern') && !lastname?.hasError('maxlength')">{{ 'CHECK_WORK_SPACING' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="relative w-full flex justify-start items-center
    mt-7 pl-23px pr-23px">
    <button
      class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer w-40 h-41px rounded-3px px-4"
      type="button" (click)="onSubmit()" [disabled]="isSaving"
      [ngClass]="{'opacity-50': isSaving, 'cursor-not-allowed ': isSaving}">
      <p class="relative font-Lato-Bold text-15px leading-4">
        {{'VERIFY' | translate}}
      </p>
      <fa-icon *ngIf="isSaving"
               class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>
    <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D hover:bg-gray-#EEEEEE hover:border-gray-500
        w-40 h-41px rounded-3px ml-2" type="button" mat-dialog-close="close">
      <p class="relative font-Lato-Bold
         text-15px leading-4">{{'CANCEL' | translate}}</p>
    </button>
  </div>
</form>
