import {Component, OnInit} from '@angular/core';
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-k-wallet-skeleton',
  standalone: true,
  templateUrl: './k-wallet-skeleton.component.html',
  imports: [
    CommonModule,
    FaIconComponent
  ],
  styleUrls: ['./k-wallet-skeleton.component.scss']
})
export class KWalletSkeletonComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
