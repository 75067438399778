<section class="relative w-full min-h-screen flex justify-center items-start bg-gray-#f2f2f2 pb-5">
  <div class="relative overflow-hidden flex justify-between flex-wrap
     lg:max-w-4xl
    us:w-screen us:px-5.3vw us:pt-7
    lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:pt-7">

    <app-bread-crumbs class="relative w-full lg-landscape:mb-4" [data]="data"></app-bread-crumbs>

    <div class="relative w-879px us:pt-7">
      <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                lg-landscape:text-19px">{{'K_WALLET_ADD_FUNDS' | translate}} </p>

      <form
        [formGroup]="addFundsForm"
        class="relative w-full bg-gray-#f2f2f2  h-auto grid us:pt-5 us:grid-cols-1 us-landscape:grid-cols-2 gap-5
            lg-landscape:min-h-346px lg-landscape:mt-6 lg-landscape:pt-10 lg-landscape:pb-6">
        <div class="relative w-full flex flex-col bg-white">
          <app-payments-cards [filterByDebits]="true" (onCardSelection)="updateSelectedCard($event)"></app-payments-cards>
        </div>
        <div class="relative w-full flex flex-col">
          <div class="grid gap-2 grid-cols-1">
            <div class="flex flex-1 flex-col relative pb-6">
              <input placeholder="{{ 'AMOUNT_TO_RECHARGE' | translate }}*" formControlName="amount" id="amount" class="relative text-gray-#727272 bg-white font-Lato-Regular border-none focus:outline-none active:outline-none focus:ring-0 active:ring-0
                h-47px rounded-3px pl-4 text-base" type="phone">
              <div *ngIf="amount?.invalid && (amount?.dirty || amount?.touched)"
                   class="absolute bottom-0.5 text-sm text-red-500">
                <p *ngIf="amount?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                <p *ngIf="amount?.hasError('pattern')">{{ 'ONLY_NUMBERS' | translate }}</p>
                <p *ngIf="amount?.hasError('min')">{{ 'MIN_VALIDATION' | translate: {min: minAmount} }}</p>
                <p *ngIf="amount?.hasError('max')">{{ 'MAX_VALIDATION' | translate: {max: maxAmount} }}</p>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="flex flex-row gap-2 lg:gap-5 justify-end items-end us:pb-10">
        <button [disabled]="addFundsForm.invalid || !selectedCard || isSaving"
                [ngClass]="{'opacity-50 cursor-not-allowed': addFundsForm.invalid || !selectedCard || isSaving}"
                (click)="submit()" class="relative bg-yellow-#FF6D03 font-Lato-Regular text-white px-3 lg:px-6 flex items-center
                w-auto h-10 rounded-3xl text-md lg:text-19px">
          <div>{{ 'TOP_UP' | translate }}</div>
          <fa-icon *ngIf="isSaving"
                   class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                   [icon]="faSpinner">
          </fa-icon>
        </button>

        <button (click)="goToRoute()" class="relative font-Lato-Regular text-gray-#5d5d5d px-3 lg:px-6
                w-auto h-10 rounded-3xl flex flex-row gap-0.5 justify-center items-center">
          <fa-icon
                   class="relative flex justify-center items-center rounded-full w-9 h-9 text-xl"
                   [icon]="faLongArrowLeft">
          </fa-icon>
          <p *ngIf="!isCheckout" class="mb-1">{{ 'BACK_TO_K_WALLET' | translate }}</p>
          <p *ngIf="isCheckout" class="mb-1">{{ 'BACK_TO_CHECKOUT' | translate }}</p>
        </button>
      </div>

    </div>

  </div>
</section>
