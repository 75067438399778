import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-bread-crumbs',
  standalone: true,
  templateUrl: './bread-crumbs.component.html',
  imports: [
    CommonModule,
    TranslateModule
  ],
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent{
  @Input() data: any = [
    {section: 'Section', route: ''},
    {section: 'SubSection', route: ''},
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  gotoRoute(link: string) {
    if (link) {
      this.router.navigate([`/${link}`], {queryParams: {redirect: this.authService.getRedirect()}});
    }
  }
}
