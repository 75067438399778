import {Component, OnDestroy, OnInit} from '@angular/core';
import {TextSkeletonComponent} from "../text-skeleton/text-skeleton.component";
import {NgIf} from "@angular/common";
import {Subscription} from "rxjs";
import {LanguageService} from "../../services/language.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderComponent} from "../header/header.component";
import {TermsService} from "../../services/terms.service";

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [
    TextSkeletonComponent,
    NgIf,
    HeaderComponent
  ],
  templateUrl: './terms-and-conditions.component.html'
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy{
  TITLE = null;
  TERMS = null;
  key: string = '';
  routeSubscription!: Subscription;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private termsService: TermsService
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(routeParams => {
      window.scroll({ top: 0 });
      this.TERMS = null;
      this.key = routeParams['id'];
      this.languageService.selectedLanguage.subscribe(() => {
        setTimeout(() => {
          this.getTermsAndConditions()
        });
      })
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  getTermsAndConditions()
  {
    const language = !!localStorage.getItem("language") ? localStorage.getItem("language")! : 'es';
    this.termsService.getTermsAndConditions(language)
      .subscribe((data: any) => {
        this.TITLE = data?.data[0]?.attributes?.Title;
        this.TERMS = data?.data[0]?.attributes?.Content;
      });
  }
}
