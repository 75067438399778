<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-gray-#f2f2f2 us:p-5 md:p-12 min-h-screen">

  <div class="relative us:w-full lg-landscape:w-879px">

    <section class="relative w-full lg-landscape:mb-4">
      <p class="relative text-gray-#252525 font-Lato-Regular font-semibold
            us:text-sm
            lg-landscape:text-sm">
        <span (click)="gotoRoute()" class="cursor-pointer">
                {{ 'K-wallet' | translate }} <span> > </span>
            </span>
        <span class="cursor-pointer">
                {{ 'TRANSACTIONS' | translate }}
            </span>
      </p>
    </section>

    <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                us:text-base us:pb-5
                md:text-19px">
                <span class=" w-full flex flex-row h-auto justify-between">
                  <span class="lg-landscape:h-41px lg-landscape:pt-1.5 us:pt-2">{{'TRANSACTIONS' | translate}}</span>
                </span>
    </p>

    <div *ngIf="groupedTransactions && groupedTransactions.length; else noData" class="pb-5">
      <div class="relative w-full bg-white flex justify-between items-center rounded
                    us:min-h-84px us:pt-5 us:pb-4 us:pl-26px us:pr-26px
                    lg-landscape:min-h-84px lg-landscape:pt-5 lg-landscape:pb-4 lg-landscape:pl-26px lg-landscape:pr-26px"
           *ngFor="let payment of groupedTransactions">
        <div class="relative font-Lato-Regular
                      us:text-13px us:leading-16px
                      lg-landscape:text-13px lg-landscape:leading-16px">
          <p class="relative text-gray-#5d5d5d">{{payment.date | date: 'MM.dd.YYYY | HH:mma'}}</p>

          <p
            class="relative font-bold underline text-blue-#18396C us:mt-1 lg-landscape:mt-1">{{'DESCRIPTION' | translate}}
            :
            {{payment.description}}
          </p>
        </div>
        <p class="relative font-Lato-Regular font-bold text-gray-#252525
              us:text-13px lg-landscape:text-13px">{{payment.amount | currency}}</p>
      </div>
    </div>

    <ng-template #noData>
      <div *ngIf="getObjectKeys(groupedTransactions)?.length !== 0 || !spinLoadMore; else loadTemplate">
        <div class="pb-5">
          <div class="relative w-full bg-white flex justify-between items-center rounded
                    us:min-h-84px us:pt-5 us:pb-4 us:pl-26px us:pr-26px
                    lg-landscape:min-h-84px lg-landscape:pt-5 lg-landscape:pb-4 lg-landscape:pl-26px lg-landscape:pr-26px">
            <div
              class="min-h-409px relative font-Lato-Regular flex w-full items-center justify-center bg-white text-gray-#979797">
              <p>{{ 'NO_DATA' | translate | uppercase }}</p>
            </div>
          </div>
        </div>
      </div>
      <ng-template #loadTemplate>
        <div id="skeleton" class="animate-pulse">
          <div
            class="relative box-border us:text-19px us:mb-17px lg-landscape:text-19px lg-landscape:mb-17px us:pb-5 bg-gray-300">
                      <span class=" w-full flex flex-row h-auto justify-between animate-pulse">
                        <span class="
                        us:h-41px us:pt-2
                        lg-landscape:h-41px lg-landscape:pt-1.5 bg-gray-300"></span>
                      </span>
          </div>

          <div class="box-border bg-gray-300 mb-4">
            <div class="relative w-full
                        us:pl-6 us:pr-6 us:pt-5 us:pb-5
                        lg-landscape:pl-6 lg-landscape:pr-6 lg-landscape:pt-5 lg-landscape:pb-5 animate-pulse">
              <p class="relative lg-landscape:text-sm lg-landscape:tracking-0.75px bg-gray-#3a3a3a"></p>
            </div>

            <div
              class="relative w-full box-border flex justify-between items-center animate-pulse
                                us:min-h-84px us:pt-5 us:pb-4 us:pl-26px us:pr-26px
                                lg-landscape:min-h-84px lg-landscape:pt-5 lg-landscape:pb-4 lg-landscape:pl-26px lg-landscape:pr-26px bg-gray-300">
              <div class="relative
                                    us:text-13px us:leading-16px
                                    lg-landscape:text-13px lg-landscape:leading-16px bg-gray-300">
                <p class="relative"></p>
                <p class="relative us:mt-1 lg-landscape:mt-1"></p>
              </div>
              <p class="relative us:text-13px lg-landscape:text-13px"></p>
            </div>
          </div>

          <div class="box-border bg-gray-300">
            <div class="relative w-full box-border bg-gray-300
                                us:pl-6 us:pr-6 us:pt-5 us:pb-5
                                lg-landscape:pl-6 lg-landscape:pr-6 lg-landscape:pt-5 lg-landscape:pb-5 animate-pulse">
              <p class="relative lg-landscape:text-sm lg-landscape:tracking-0.75px bg-gray-#3a3a3a"></p>
            </div>

            <div
              class="relative w-full box-border flex justify-between items-center animate-pulse
                               us:min-h-84px us:pt-5 us:pb-4 us:pl-26px us:pr-26px
                               lg-landscape:min-h-84px lg-landscape:pt-5 lg-landscape:pb-4 lg-landscape:pl-26px lg-landscape:pr-26px bg-gray-300">
              <div class="relative lg-landscape:text-13px lg-landscape:leading-16px bg-gray-300">
                <p class="relative"></p>
                <p class="relative us:mt-1 lg-landscape:mt-1"></p>
              </div>
              <p class="relative us:text-13px lg-landscape:text-13px"></p>
            </div>
          </div>
        </div>
      </ng-template>

    </ng-template>

    <div class="relative full flex flex-row justify-center"
         *ngIf="getObjectKeys(groupedTransactions)?.length !== 0"
    >
      <button
        class="relative self-center font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex items-center text-white
                            us:h-41px us:w-auto us:text-17px us:ml-3 us:px-6 us:pt-0 us:mt-0
                            lg-landscape:h-41px lg-landscape:w-auto lg-landscape:text-19px lg-landscape:ml-0 lg-landscape:mt-0 lg-landscape:px-4"
        (click)="loadMoreTransactions()" [disabled]="spinLoadMore" *ngIf="totalPages !== actualPage"
        [ngClass]="{'opacity-50': spinLoadMore, 'cursor-not-allowed ': spinLoadMore}">
        <span>{{'LOAD_MORE' | translate}}</span>
        <fa-icon *ngIf="spinLoadMore"
                 class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                 [icon]="faSpinner">
        </fa-icon>
      </button>
    </div>

  </div>

</section>

