import {Component, Inject, OnInit} from '@angular/core';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  standalone: true,
  selector: 'app-proccessing-payment-modal',
  templateUrl: './proccessing-payment-modal.component.html',
  imports: [
    FaIconComponent,
    TranslateModule
  ],
  styleUrls: ['./proccessing-payment-modal.component.scss']
})
export class ProccessingPaymentModalComponent implements OnInit {
  faSpinner = faSpinner;
  message: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.message = data.message;
  }

  ngOnInit(): void {
  }

}
