import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {REGULAR_EXPRESSION} from "../../shared/enum/regular-expression.enum";
import {AuthService} from "../../shared/services/auth.service";
import {Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {takeUntil} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {OnfidoService} from "../../shared/services/onfido.service";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  standalone: true,
  selector: 'app-onfido-start-verification',
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './onfido-start-verification.component.html',
  styleUrls: ['./onfido-start-verification.component.scss']
})
export class OnfidoStartVerificationComponent implements OnInit, OnDestroy {

  verificationForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  formMarkAsTouched: boolean = false;
  isSaving = false;
  faSpinner = faSpinner;

  redirectUrl: string = '';

  constructor(
    private authService: AuthService,
    private onfidoService: OnfidoService,
    private dialogRef: MatDialogRef<OnfidoStartVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    const currentUser = this.authService.getCurrentUser();
    this.verificationForm = new FormGroup({
      firstname: new FormControl(currentUser.firstName, [
        Validators.required,
        Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
        Validators.maxLength(35)
      ]),
      lastname: new FormControl(currentUser.lastName, [
        Validators.required,
        Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
        Validators.maxLength(35)
      ]),
    });
    this.redirectUrl = data.redirectUrl;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  get firstname() { return this.verificationForm.get('firstname'); }
  get lastname() { return this.verificationForm.get('lastname'); }

  onSubmit() {
    this.verificationForm.markAllAsTouched();
    this.formMarkAsTouched = true;
    if (this.verificationForm.valid) {
      this.isSaving = true;
      const data = this.verificationForm.value;
      data.firstname = data.firstname.trim();
      data.lastname = data.lastname.trim();
      data.isMobileApp = false;
      data.redirect = this.redirectUrl || `${environment.base_route}/profile/user`;
      this.disableForm();
      this.onfidoService.startVerification(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.enableFormAndSetSaving();
            this.dialogRef.close(Object.assign(response || {}, { modified: !!response}));
          },
          error: err => this.enableFormAndSetSaving(),
          complete: () => this.enableFormAndSetSaving()
        });
      // setTimeout(() => {
      //   this.enableFormAndSetSaving();
      // }, 2000);
    }
  }

  private enableFormAndSetSaving(): void {
    this.enableForm();
    this.isSaving = false;
  }

  close(): void {
    this.dialogRef.close(null);
  }

  disableForm() {
    this.verificationForm.get('firstname')?.disable();
    this.verificationForm.get('lastname')?.disable();
  }

  enableForm() {
    this.verificationForm.get('firstname')?.enable();
    this.verificationForm.get('lastname')?.enable();
  }
}
