import {Component, Inject, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {faEye, faEyeSlash, faShareFromSquare, faSpinner, faWarning} from '@fortawesome/free-solid-svg-icons';
import {NotificationService} from "../../../shared/services/notification.service";
import {WalletService} from "../../../shared/services/wallet.service";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";

@Component({
  standalone: true,
  selector: 'app-send-money-to-recipient',
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    NgxMatIntlTelInputComponent,
    MatDialogClose,
  ],
  templateUrl: './send-money-to-recipient.component.html',
  styleUrls: ['./send-money-to-recipient.component.scss']
})
export class SendMoneyToRecipientComponent implements OnDestroy {
  sends: { label: string, value: boolean }[] = [
    {label: 'ELECTRONIC_EMAIL', value: true},
    {label: 'K_WALLET_ID', value: false}
  ];
  fieldType = 'password';
  messageError: string | null = null;
  showPassword: boolean = true;
  iconType = faEyeSlash;
  faShareFromSquare = faShareFromSquare;
  faSpinner = faSpinner;
  faWarning = faWarning;
  transferForm: FormGroup = new FormGroup({
    sendBy: new FormControl(null, Validators.required),
    amount: new FormControl(null, [Validators.required, Validators.min(1)]),
    email: new FormControl(null),
    receiver_id: new FormControl(null),
    terms_cond: new FormControl(null, [Validators.required]),
    pass: new FormControl(null, [Validators.required]),
  });
  destroy$: Subject<boolean> = new Subject<boolean>();
  senderWalletTierId: number;
  isSaving = false;

  constructor(
    private dialogRef: MatDialogRef<SendMoneyToRecipientComponent>,
    private notificationService: NotificationService,
    private walletService: WalletService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.senderWalletTierId = data.senderWalletTierId;
  }

  get pass() {
    return this.transferForm.get('pass');
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
    if (!this.showPassword) {
      this.iconType = faEye;
      this.fieldType = 'text';
    } else {
      this.iconType = faEyeSlash;
      this.fieldType = 'password';
    }
  }

  change(event: boolean) {
    if (event) {
      this.receiverId?.setValidators([]);
      this.receiverId?.patchValue(null);

      this.email?.setValidators([Validators.required, Validators.email]);
      this.email?.markAsPristine();
      this.email?.markAsUntouched();
      this.transferForm.updateValueAndValidity();
    } else {
      this.email?.setValidators([]);
      this.email?.patchValue(null);

      this.receiverId?.setValidators([Validators.required]);
      this.receiverId?.markAsPristine();
      this.receiverId?.markAsUntouched();
      this.transferForm.updateValueAndValidity();
    }
  }

  valueChange(event: any) {
    this.messageError = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onSubmit() {
    const values = this.transferForm.value;
    this.isSaving = true;
    this.transferForm.disable();
    const amount = Number(Number(values.amount * 100).toFixed(2));
    const data = {
      amount: amount,
      senderWalletTierId: this.senderWalletTierId,
      transferSecret: values.pass,
      destinationEmail: values.sendBy ? values.email : undefined,
      publicAddress: values.sendBy ? undefined : values.receiver_id
    };
    this.walletService.sendFunds(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response.success) {
          this.notifyAndCloseModal('MONEY_TRANSFERRED_SUCCESSFULLY', true, true);
        } else {
          if (response.fails) {
            this.notifyAndCloseModal(response.message, false);
          }
        }
      }, (error: any) => {
        this.notifyAndCloseModal(error.error.message, false);
      });
  }

  get sendBy() {
    return this.transferForm.get('sendBy');
  }

  get amount() {
    return this.transferForm.get('amount');
  }

  get email() {
    return this.transferForm.get('email');
  }

  get receiverId() {
    return this.transferForm.get('receiver_id');
  }

  get termsConditions(): boolean {
    return this.transferForm.get('terms_cond')?.value || false;
  }

  notifyAndCloseModal(message: string, modified: boolean, closed = false) {
    this.notificationService.showAndSubscribe(message, 'CLOSE');
    if (closed) {
      this.dialogRef.close({modified});
    }
    this.isSaving = false;
    this.transferForm.enable();
  }
}
