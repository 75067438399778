import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subject, tap} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import { faShareFromSquare, faSpinner } from '@fortawesome/free-solid-svg-icons';
import {NotificationService} from "../../../shared/services/notification.service";
import {WalletService} from "../../../shared/services/wallet.service";
import {EnumType} from "../../../shared/models";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";

@Component({
  standalone: true,
  selector: 'app-k-wallet-security-config',
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    NgxMatIntlTelInputComponent,
    MatDialogClose,
  ],
  templateUrl: './k-wallet-security-config.component.html',
  styleUrls: ['./k-wallet-security-config.component.scss']
})
export class KWalletSecurityConfig implements OnInit, OnDestroy {


  faShareFromSquare = faShareFromSquare;
  faSpinner = faSpinner;

  securityConfigForm: FormGroup;

  destroy$: Subject<boolean> = new Subject<boolean>();

  isSaving = false;
  isLoadingWalletOperationConfirmationType = true;
  walletOperationConfirmationType$: EnumType[] = [];

  constructor(
    private dialogRef: MatDialogRef<KWalletSecurityConfig>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private walletService: WalletService
  ) {
    this.securityConfigForm = new FormGroup({
      walletOperationConfirmationTypeId: new FormControl(data.settings.walletOperationConfirmationTypeId, {
        validators: [Validators.required]
      }),
      enableVerificationOnPurchase: new FormControl(data.settings.enableVerificationOnPurchase || false),
    });
    this.walletService
      .enumDiscover('WalletOperationConfirmationType')
      .pipe(
        takeUntil(this.destroy$),
        tap((response: EnumType[]) => {
          this.isLoadingWalletOperationConfirmationType = false;
          this.walletOperationConfirmationType$ = response;
        })
      ).subscribe();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onSubmit() {
    const values = this.securityConfigForm.value;
    console.log('values => ', values);
    this.isSaving = true;
    this.disableFields();
    this.saveSecuritySettings(values);
  }

  private disableFields() {
    this.walletOperationConfirmationTypeId?.disable();
    this.enableVerificationOnPurchase?.disable();
  }

  saveSecuritySettings(values: {walletOperationConfirmationTypeId: number, enableVerificationOnPurchase: boolean}) {
    this.isSaving = true;
    this.walletService.setWalletsSettings(values)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.notifyAndCloseModal('SECURITY_CONFIG_SAVED_SUCCESSFULLY', true, true);
          } else {
            if (response.fails) {
              this.notifyAndCloseModal(response.message, false);
            }
          }
        },
        error: () => {
          this.notifyAndCloseModal('SECURITY_CONFIG_ERROR', false);
        },
        complete: () => {
          this.isSaving = false;
        }
      })
  }

  get walletOperationConfirmationTypeId() {
    return this.securityConfigForm.get('walletOperationConfirmationTypeId');
  }

  get enableVerificationOnPurchase() {
    return this.securityConfigForm.get('enableVerificationOnPurchase');
  }

  notifyAndCloseModal(message: string, modified: boolean, closed = false) {
    this.notificationService.showAndSubscribe(message, 'CLOSE');
    if (closed) {
      this.dialogRef.close({modified});
    }
    this.isSaving = false;
    this.securityConfigForm.enable();
  }

}
