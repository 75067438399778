import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { ExtendedGetResult, FingerprintjsProAngularService } from "@fingerprintjs/fingerprintjs-pro-angular";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {
  private KeyStore = {
    FINGER_PRINT: 'fingerPrint'
  };

  constructor(
    private fingerPrintService: FingerprintjsProAngularService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  /**
   * Get fingerprint visitor data
   * @param retryOnError
   * @private
   */
  async getVisitorData(retryOnError: number): Promise<ExtendedGetResult | null> {
    let data = null;
    if (isPlatformBrowser(this.platformId)) {
      try {
        data = await this.fingerPrintService.getVisitorData({ extendedResult: true });
        if ((!data.visitorId || data.visitorId == '') && retryOnError > 0) {
          return this.getVisitorData(--retryOnError);
        } else if (data.visitorId && data.visitorId != '') {
          this.saveFingerprintValuesInStorage(data);
        } else {
          data = this.getFingerPrintValuesFromStorage();
        }
      } catch (e) {
        if (retryOnError > 0) {
          return this.getVisitorData(--retryOnError);
        } else {
          data = this.getFingerPrintValuesFromStorage();
        }
        console.log('fingerprint error => ', e);
      }
      return data;
    } else {
      return null;
    }
  }

  saveFingerprintValuesInStorage(value: any) {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem(this.KeyStore.FINGER_PRINT, JSON.stringify(value));
    }
  }

  getFingerPrintValuesFromStorage(): ExtendedGetResult | null {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedData: string | null = localStorage.getItem(this.KeyStore.FINGER_PRINT);
      if (storedData) {
        return <ExtendedGetResult>JSON.parse(storedData);
      }
      return null;
    } else return null;
  }
}
