<section *ngIf="isLanguageLoaded" class="relative w-full min-h-screen flex flex-wrap justify-center bg-gray-#f2f2f2 us:p-5 md:p-12">

  <div class="relative
    us:w-full max-w-xl lg:max-w-4xl">
    <div class="flex flex-row justify-between w-full">

      <div class="relative w-full flex justify-between items-center justify-items-center mt-2">
        <p class="relative font-Lato-Regular font-semibold text-gray-#252525 text-19px
      us:mt-2
      lg-landscape:mt-0">{{'PAYMENT_METHODS' | translate}}</p>
      </div>
      <button type="button" class="bg-yellow-#FF6D03 text-white font-Lato-Regular font-bold rounded-full
            w-8 h-8 text-base" (click)="logout()">
        <fa-icon class="relative w-5 h-5" [icon]="faSignOut" [matTooltip]="'LOG_OUT'| translate">
        </fa-icon>
      </button>
    </div>
    <div class="relative w-full bg-gray-#E7E7E7 flex justify-start mt-4
      h-50px">
      <div (click)="selectTab(1)"
           [ngClass]="tab_1 == true ? 'bg-white border-t-4 border-t-yellow-#FF6D03' : 'border-t-0'" class="tab relative cursor-pointer w-auto h-full flex justify-start items-center
          pl-4 pr-4">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 uppercase
              text-sm">{{'K_WALLETS' | translate}}</p>
      </div>
      <div (click)="selectTab(2)"
           [ngClass]="tab_2 == true ? 'bg-white border-t-4 border-t-yellow-#FF6D03' : 'border-t-0'" class="tab relative cursor-pointer w-auto h-full flex justify-start items-center
          pl-4 pr-4">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 uppercase
              text-sm">{{'CARDS' | translate}}</p>
      </div>
    </div>

    <app-k-wallet-skeleton *ngIf="isLoadingWalletAccounts && tab_1"></app-k-wallet-skeleton>

    <div *ngIf="tab_1" class="relative w-full h-auto">
      <div
        class="relative w-full bg-white flex justify-start items-start flex-wrap
              us:mt-6 us:px-4 us:py-4 us:flex-col
              us-landscape:mt-6 us-landscape:pl-10 us-landscape:pr-10 us-landscape:pt-10 us-landscape:pb-9 us-landscape:flex-row
              md:mt-6 md:pl-10 md:pr-10 md:pt-10 md:pb-9 md:flex-row
              lg-landscape:mt-1 lg-landscape:pl-10 lg-landscape:pr-10 lg-landscape:pt-10 lg-landscape:pb-9 lg-landscape:flex-row"
        *ngFor="let walletAccount of walletAccounts?.profileWalletData;let i = index;">

        <fa-icon [ngClass]="selected_index == i ? 'rotate-180':'rotate-0'" class="absolute top-1 right-2 cursor-pointer rounded-full border-gray-#5d5d5d
                    text-blue-#18396C lg-landscape:text-13px ml-3" [icon]="faAngleDown" (click)="selectIndex(i)">
        </fa-icon>

        <p class="absolute top-2 px-2 py-px left-0 rounded-r-md uppercase text-xs text-white bg-yellow-#FF6D03">
          {{walletAccount.layout.label}}
        </p>


        <div *ngIf="walletAccount.layout.messages.length > 0"
             class="flex bg-gray-#D4E1EE rounded w-full min-h-40px items-center relative my-2">
          <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full
                    text-gray-#5d5d5d us:text-lg md:text-2xl w-12" [icon]="faWarning">
          </fa-icon>
          <p class="pt-2 py-2 w-full font-Lato-Regular md:text-17px md:leading-5 text-gray-#5d5d5d break-words
                            us:text-15px us:leading-4">{{walletAccount.layout.messages.join('\n')}}</p>
        </div>

        <div
          class="relative flex flex-col justify-start items-start
            us:w-full
            us-landscape:w-auto us-landscape:h-full us-landscape:pr-4
            md:w-auto md:h-full  md:pr-4
            lg:w-1/2 lg-h-full lg-border-r lg-border-r-gray-#dfdfdf lg-pr-4
                  lg-landscape:w-1/2 lg-landscape:h-full lg-landscape:border-r lg-landscape:border-r-gray-#dfdfdf lg-landscape:pr-4">
          <img class="relative
                      us:w-4/5 us:h-auto us:-ml-2 us:mt-4
                      us-landscape:w-280px us-landscape:h-60px us-landscape:-ml-2
                      md:w-280px md:h-60px md:-ml-2
                      lg-landscape:w-280px lg-landscape:h-60px lg-landscape:-ml-2 lg-landscape:mt-0"
               src="{{walletAccount.layout.logo.url}}" alt="">
          <p class="relative w-full font-Lato-Regular font-bold
            us:text-base us:mt-4
                      lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:mt-5">
            {{walletAccount.layout.title}}
          </p>

          <div class="relative w-full
            us:mt-4
            lg-landscape:mt-5" *ngIf="selected_index == i">
            <p class="relative font-Lato-Regular text-gray-#252525 flex justify-start items-center
              us:text-sm us:mb-1
              lg-landscape:text-sm lg-landscape:mb-1" *ngFor="let feature of walletAccount?.layout?.features">
              <img class="relative flex justify-center items-center  bg-white rounded-full
                          text-yellow-#FF6D03
                          us:text-sm us:mr-3
                          lg-landscape:text-sm lg-landscape:mr-3 h-4 w-4" [src]="feature.icon.url"/>
              {{feature.featureName}}
            </p>
          </div>

          <div class="relative grid gap-x-3 gap-y-3 mt-3
            us:grid-cols-1
            lg-landscape:grid-cols-2">
            <button
              [ngClass]="{'opacity-50 pointer-events-none': !walletAccount.walletData.isActive || isLoadingWalletAccounts}"
              (click)="navigateKWalletOperations(walletAccount.kWalletTierId, walletAccount.layout.label)"
              class="relative flex items-center bg-white text-yellow-#FF6D03 px-3 border-2 border-yellow-#FF6D03 font-Lato-Regular
                             font-bold rounded-full h-11 space-x-3
                             us:w-auto us:text-sm us:mt-3
                             us-landscape:w-auto us-landscape:text-sm us-landscape:mt-3 us-landscape:self-center
                             md:w-auto md:text-sm md:mt-3 md:self-center
                             lg-landscape:w-auto lg-landscape:text-sm lg-landscape:mt-3 lg-landscape:self-center">
              <fa-icon class="cursor-pointer text-yellow-#FF6D03 text-base" [icon]="faMoneyBill">
              </fa-icon>
              <span>{{'SEE_TRANSACTIONS' | translate}}</span>
            </button>

            <button
              [ngClass]="{'opacity-50 pointer-events-none': !walletAccount.walletData.isActive || isLoadingWalletAccounts}"
              (click)="navigateRequestList(walletAccount.kWalletTierId, walletAccount.layout.label)"
              class="relative flex bg-white border-2 border-yellow-#FF6D03 text-yellow-#FF6D03 h-11 text-sm px-3 mt-3
                            font-Lato-Regular font-semibold rounded-full justify-center items-center hover:shadow-custom">
              <fa-icon
                class="relative cursor-pointer flex justify-center items-center bg-yellow-#FF6D03 text-white rounded-full w-5 h-5 mr-3 text-sm"
                [icon]="faDollarSign"></fa-icon>
              {{'VIEW_REQUEST' | translate}}

              <div *ngIf="walletAccount.receivedCount && walletAccount.receivedCount > 0"
                   [ngClass]="walletAccount.receivedCount > 99 ? 'w-6 h-6 -top-4' : 'w-21px h-21px -top-3'"
                   class="absolute -right-3 rounded-full bg-yellow-#FF6D03 float-right flex justify-center items-center">
                <p [ngClass]="walletAccount.receivedCount > 99 ? 'text-10px' : 'text-xs'"
                   class="relative text-white font-Lato-Bold">
                  {{ walletAccount.receivedCount }}
                </p>
              </div>
            </button>
          </div>
        </div>

        <div class="relative w-full h-px bg-gray-#9D9D9D my-8
          us:block
          us-landscape:block
          md:block
          lg:hidden
          lg-landscape:hidden"></div>

        <div *ngIf="!walletAccount.walletData.isActive"
             class="relative flex us:w-full md:flex-1 items-center justify-center">
          <button [disabled]="isLoadingWalletAccounts" class="relative bg-yellow-#FF6D03 text-white font-Lato-Regular
                        font-bold rounded-full mt-0 xl:mt-3 w-261px h-49px text-19px"
                  (click)="activateWallet()" [ngClass]="{'opacity-50': isLoadingWalletAccounts}">
            {{'ACTIVATE_WALLET' | translate}}
          </button>
        </div>

        <div class="relative
                us:w-full
                us-landscape:pl-0
                md:pl-0
                lg:w-auto lg:pl-8
                lg-landscape:w-auto lg-landscape:pl-8"
             *ngIf="!isLoadingWalletAccounts && walletAccount.walletData.isActive">
          <p class="relative flex flex-row font-Lato-Regular font-bold text-gray-#252525
            us:text-base
                      lg-landscape:text-17px" *ngFor="let balance of walletAccount?.layout?.balances">
            {{balance.label}} <span class="relative font-normal us:ml-1 lg-landscape:ml-1">{{balance.value ||
          '$0.00'}}</span>
            <fa-icon class="cursor-pointer rounded-full border-gray-#5d5d5d
                              text-blue-#18396C lg-landscape:text-13px ml-3" [icon]="faCircleQuestion"
                     matTooltip="{{balance.tooltip}}">
              <!--              matTooltipClass="text-15px"-->
            </fa-icon>
          </p>

          <div *ngIf="walletAccount.walletData.isActive && walletAccount.layout.showWalletAddressComponent"
               class="relative pb-2 pt-2 flex us:flex-col us:w-full lg-landscape:w-auto lg-landscape:flex-row">
            <p class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                            us:text-15px us:ml-0 us:mb-1
                            md:mb-2.5
                            lg-landscape:text-15px lg-landscape:mb-3 lg-landscape:ml-0 w-40 pt-3" for="">
              {{'K_WALLET_ID'
              | translate}}:
            </p>
            <div class="flex flex-col w-full gap-2">
              <div class="relative w-full flex-row pb-4">
                <input type="password" class="relative font-Lato-Regular w-full
                            rounded-full
                            us:text-15px us:h-auto us:rounded-3px us:w-full
                            us-landscape:h-auto
                            md:h-auto
                            lg-landscape:text-15px px-3 py-2 bg-gray-#f2f2f2
                            active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                            focus:outline-none outline-none ring-0 border-none h-47px text-base col-span-1" readonly
                       value="{{walletAccount.walletData.publicAddress | slice: 0:8}}"/>
                <fa-icon class="cursor-pointer rounded-full border-gray-#5d5d5d
                              text-blue-#18396C lg-landscape:text-13px lg-landscape:mr-3 absolute right-16 top-3"
                         [icon]="faCopy" (click)="onWalletIdCopy(walletAccount)" matTooltip="{{'COPY' | translate}}">
                </fa-icon>
                <fa-icon class="cursor-pointer rounded-full border-gray-#5d5d5d
                              text-blue-#18396C lg-landscape:text-13px lg-landscape:mr-3 absolute right-8 top-3"
                         [icon]="faShareFromSquare" (click)="onWalletIdShare(walletAccount)"
                         matTooltip="{{'SHARE' | translate}}">
                </fa-icon>
                <fa-icon class="cursor-pointer rounded-full border-gray-#5d5d5d
                              text-blue-#18396C lg-landscape:text-13px lg-landscape:mr-3 absolute right-2 top-3"
                         [icon]="faQrcode" (click)="showQrGeneratorModal(walletAccount)"
                         matTooltip="{{'QR_CODE' | translate}}">
                </fa-icon>
              </div>
              <span class="w-full absolute bottom-0.5 text-sm"
                    *ngIf="id_copied && walletAccount.walletData?.publicAddress === copied_wallet_id">
                  {{'K_WALLET_ID_COPIED_TO_CLIPBOARD' | translate}}
                </span>
            </div>
          </div>
          <p class="relative font-Lato-Regular text-gray-#252525
            us:text-base us:mt-4
            lg-landscape:text-15px lg-landscape:mt-6" *ngIf="walletAccount.walletData.isActive && selected_index == i">
            {{walletAccount.layout.actionsLabel}}
          </p>

          <div class="relative w-full lg-landscape:mt-5" *ngIf="selected_index == i">
            <div class="relative w-full flex flex-row" *ngFor="let action of walletAccount?.layout?.actions">
              <p class="relative cursor-pointer font-Lato-Regular text-gray-#252525 flex justify-start items-center underline
                lg-landscape:text-sm lg-landscape:mb-4" *ngIf="walletAccount.walletData.isActive && !action.hidden"
                 [ngClass]="{'cursor-pointer': !action.disabled, 'opacity-50': action.disabled}"
                 [matTooltip]="action.tooltip" (click)="redirectToActionPage(walletAccount, action)">
                <img class="relative flex justify-center items-center rounded-full
                text-blue-#18396C lg-landscape:text-13px mr-3 h-4 w-4" [src]="action.logo.url"/>
                {{action.text}}
              </p>
              <fa-icon class="relative flex cursor-pointer rounded-full border-gray-#5d5d5d
                          text-blue-#18396C lg-landscape:text-13px ml-3 mt-1" *ngIf="action.messages.length > 0"
                       [icon]="faWarning" [matTooltip]="action.messages.join('\n')"
                       [matTooltipClass]="['my-tooltip-separator']"
                       [ngClass]="{'cursor-pointer': !action.disabled, 'opacity-50': action.disabled}">
              </fa-icon>
            </div>
          </div>
        </div>
        <span id="cards"></span>
      </div>
    </div>

    <div *ngIf="tab_2 && auth" class="relative w-full overflow-hidden flex flex-col justify-between">
      <app-payments-cards [showAddCards]="true" [showCardDetails]="true"></app-payments-cards>

      <button *ngIf="isCheckout" (click)="goToRoute()" class="relative flex justify-end font-Lato-Regular text-gray-#5d5d5d px-3 lg:px-6
                w-auto h-10 rounded-3xl flex-row gap-0.5 items-center">
        <fa-icon class="relative flex justify-center items-center rounded-full w-9 h-9 text-xl"
                 [icon]="faLongArrowLeft">
        </fa-icon>
        <p class="mb-1">{{ 'BACK_TO_CHECKOUT' | translate }}</p>
      </button>
    </div>

    <p *ngIf="false" class="relative font-Lato-Regular font-semibold text-gray-#252525
      us:text-base us:mt-6 us:mb-17px
      us-landscape:text-19px us-landscape:mt-7 us-landscape:mb-17px
      md:text-19px md:mt-7 md:mb-17px
      lg-landscape:text-19px lg-landscape:mt-70px lg-landscape:mb-17px">{{'ACCEPT_PRINCIPAL_PAYMENT_METHODS' |
      translate}}</p>

    <div *ngIf="false" class="relative w-full grid gap-2
            lg-landscape:grid-cols-3">
      <div class="relative bg-white flex justify-center items-center
                lg-landscape:h-82px">
        <img src="" alt="">
      </div>
      <div class="relative bg-white flex justify-center items-center
                lg-landscape:h-82px">
        <img src="" alt="">
      </div>
      <div class="relative bg-white flex justify-center items-center
                lg-landscape:h-82px">
        <img src="" alt="">
      </div>
      <div class="relative bg-white flex justify-center items-center
                lg-landscape:h-82px">
        <img src="" alt="">
      </div>
      <div class="relative bg-white flex justify-center items-center
                lg-landscape:h-82px">
        <img src="" alt="">
      </div>
      <div class="relative bg-white flex justify-center items-center
                lg-landscape:h-82px">
        <img src="" alt="">
      </div>
    </div>
  </div>

  <!--    <app-lateral-oferts [productsList]="productsList"></app-lateral-oferts>-->
</section>
