import {ApplicationConfig, ErrorHandler, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withFetch} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {CacheLocation, FingerprintjsProAngularModule} from "@fingerprintjs/fingerprintjs-pro-angular";
import {environment} from "../environments/environment";
import {IMqttServiceOptions, MqttModule} from 'ngx-mqtt';
import {AuthInterceptor} from "./shared/directives/auth.interceptor";
import {SentryErrorHandler} from "./shared/sentry/sentry";

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false,
  hostname: environment.mqtt.server,
  port: environment.mqtt.port,
  protocol: (environment.mqtt.protocol === "wss") ? "wss" : "ws",
  path: environment.mqtt.path,

};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      FingerprintjsProAngularModule.forRoot({
        loadOptions: { apiKey: environment.fingerprint_api_key, endpoint: environment.fingerprint_endpoint },
        cacheLocation: CacheLocation.SessionStorage
      }),
      MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },{
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
  ]
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
