import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {NewCart} from "../../shared/models/new-cart.model";
import {Order} from "../../shared/models/store-order.model";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  standalone: true,
  selector: 'app-new-cart-order-summary',
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './new-cart-order-summary.component.html',
  styleUrls: ['./new-cart-order-summary.component.scss']
})
export class NewCartOrderSummaryComponent implements OnInit {
  @Input() cart: NewCart | Order | undefined;
  @Input() confirmOrder: boolean = false;
  @Output() confirm = new EventEmitter<boolean>();
  @Input() isLoadingConfirm: boolean = false;
  faSpinner = faSpinner;

  constructor() {
  }

  ngOnInit(): void {
  }

  confirmData() {
    this.isLoadingConfirm = true;
    this.confirm.emit(true);
  }

  buildAddress() {
    let address = `${this.cart?.shippingAddress.firstname} ${this.cart?.shippingAddress.lastname}: ${this.cart?.shippingAddress.addressLine1} `;
    if (this.cart?.shippingAddress.addressLine2) {
      address += `${this.cart?.shippingAddress.addressLine2} `;
    }
    address += `${this.cart?.shippingAddress.city}, ${this.cart?.shippingAddress.stateName},  ${this.cart?.shippingAddress.countryCode},  ${this.cart?.shippingAddress.zipCode}`
    return address;
  }

}
