<div class="flex flex-col relative pt-4">

  <p *ngIf="is_human"
     class="relative font-Lato-Regular font-semibold text-gray-#252525 text-16px mb-2 leading-5 text-justify">
    {{ 'WHICH_CURRENCY_WOULD_YOU_TO_PAY' | translate }}
  </p>

  <div *ngIf="!isLoadCurrencies && is_human" class="flex flex-col gap-1">
    <div class="flex flex-col border-2" *ngFor="let currency of currencyData">
      <div class="relative w-full flex justify-center items-center bg-white h-50px us:px-3 lg:px-23px">
        <input [checked]="currency.selected" id="{{currency.externalCurrencyTypeId}}"
               (change)="changeCurrencySelect(currency)"
               [disabled]="!currency.availability || currency.selected"
               class="focus:ring-offset-0 relative cursor-pointer border rounded-full border-gray-#9D9D9D focus:outline-none
                        active:outline-none focus:ring-0 active:ring-0" type="checkbox">
        <p class="relative w-full text-gray-#252525 font-Lato-Regular text-17px ml-2"
           [ngClass]="{'font-semibold': false}">
          {{ currency.externalCurrencyType | uppercase }}
        </p>
      </div>
      <div [@collapse]="!currency.selected && currency.availability" class="flex flex-col relative w-full">
        <div class="flex flex-col pl-47px bg-white gap-3" *ngIf="currency.availability">
          <div class="flex gap-2 leading-4">
            <p class="text-gray-#5d5d5d font-Lato-Regular text-17px">{{ 'AMOUNT_PAYABLE' | translate }}: </p>
            <p
              class="text-gray-#252525 font-Lato-Regular text-17px">{{ currency.displayExternalCurrencyAmount | currency }} {{ currency.externalCurrencyType }}</p>
          </div>
          <div class="flex gap-2 pb-3">
            <p class="text-gray-#5d5d5d font-Lato-Regular text-17px">{{ 'EXCHANGE_RATE_MULTI_CURRENCIES' | translate }}
              : </p>
            <p class="text-gray-#252525 font-Lato-Regular text-17px">{{ currency.displayExchangeRate }}</p>
          </div>
        </div>

        <div class="flex flex-col my-2 us:px-3 lg:px-23px" *ngIf="!currency.availability">
          <div class="flex bg-gray-#D4E1EE w-full min-h-40px items-center">
            <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full
                                      text-gray-#5d5d5d us:text-lg md:text-2xl w-12" [icon]="faWarning"></fa-icon>
            <p class="pt-2 py-2 w-full font-Lato-Regular md:text-lg text-gray-#5d5d5d leading-4
                                              us:text-15px">{{ currency.availabilityMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoadCurrencies && is_human && !isCurrencySelected" class="flex flex-col gap-1 animate-pulse">
    <div class="flex flex-col bg-gray-#dfdfdf gap-3 h-28"></div>
  </div>
</div>
