import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Order} from "../../shared/models/store-order.model";
import {ZonesService} from "../../shared/services/zones.service";
import {NewCheckoutEnum} from "../../shared/enum/checkout.enum";
import {SelectedZone} from "../../shared/models";
import {TranslateModule} from "@ngx-translate/core";
import {PaymentsComponent} from "../payments/payments.component";
import {NewCartQrTransferComponent} from "../new-cart-qr-transfer/new-cart-qr-transfer.component";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {isAllowed} from "../../shared/utils/allowedRoutes";
import {AuthService} from "../../shared/services/auth.service";
import {LanguageService} from "../../shared/services/language.service";
import {HeaderComponent} from "../../shared/components/header/header.component";
import {LoadingComponent} from "../../shared/components/loading/loading.component";
import {MatDialog} from "@angular/material/dialog";
import {PaymentErrorDialogComponent} from "../../shared/components/payment-error-dialog/payment-error-dialog.component";
import {NotificationService} from "../../shared/services/notification.service";

@Component({
  standalone: true,
  selector: 'app-new-cart-payment-method-container',
  templateUrl: './new-cart-payment-method-container.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    MatStepper,
    MatStep,
    PaymentsComponent,
    NewCartQrTransferComponent,
    MatStepLabel,
    HeaderComponent,
    HeaderComponent,
    LoadingComponent
  ],
  styleUrls: ['./new-cart-payment-method-container.component.scss']
})
export class NewCartPaymentMethodContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper;
  language!: string;
  isLanguageLoaded: boolean = false;
  isBolsaTransfer: boolean = false;
  isLoaded = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  order: Order | undefined;
  orderNumber: string = '';
  redirect: any;
  total: number = 0;
  totalAmountUsd: number = 0;
  exchangeRate: number = 1;
  orderId: string = '';
  currency: any;
  paymentClient: any;
  paymentRoute: any;
  paymentBreakdown: any;
  paymentBreakdownDecoded: any;
  error: any;
  errorShown: boolean = false;
  userPaymentRequest: any;

  constructor(
    private zonesService: ZonesService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
  }

  async ngOnInit() {
    try {
      const paramMap = this.activatedRoute.snapshot.queryParamMap;
      if (isPlatformBrowser(this.platformID)) {
        if (isAllowed(paramMap.get('redirect'))) {
          this.language = this.languageService.selectedLanguage.value;
          this.isLanguageLoaded = true;

          const code = paramMap.get('code');
          if (code !== this.authService.getCode())
            this.authService.logout();

          this.redirect = paramMap.get('redirect');
          this.authService.saveRedirect(this.redirect);
          this.total = Number.parseFloat(paramMap.get('total') ?? '0') / 100;
          this.totalAmountUsd = Number.parseFloat(paramMap.get('totalAmountUsd') ?? '0') / 100;
          this.exchangeRate = Number.parseFloat(paramMap.get('orderExchangeRate') ?? '1');
          this.currency = paramMap.get('currency');
          this.paymentClient = paramMap.get('paymentClient') ?? '';
          this.paymentRoute = paramMap.get('paymentRoute') ?? '';
          this.orderId = paramMap.get('paymentRef') ?? '';
          this.orderNumber = paramMap.get('orderNumber') ?? '';
          this.paymentBreakdown = paramMap.get('paymentBreakdown');
          if (!!this.paymentBreakdown) {
            this.paymentBreakdownDecoded = JSON.parse(atob(this.paymentBreakdown));
          }
          this.authService.savePaymentClient(this.paymentClient);
          this.error = paramMap.get('error') ?? '';
          this.userPaymentRequest = paramMap.get('userPaymentRequest') === "true";

          if (code) {
            this.authService.setCode(code);
            if (!this.authService.isAuthenticated()) {
              const result = await this.authService.getTokensWithCode(code as string).toPromise();
              if (result?.error) {
                await this.handleAuthError(result.error.message);
                return;
              }
            }
          }

          const sig = paramMap.get('sig');
          let url = new URL(window.location.href);
          let params = new URLSearchParams(url.search);
          params.delete('sig');
          url.search = params.toString();
          let urlDecoded = decodeURIComponent(url.toString()) + '&sig=' + encodeURIComponent(sig as string);
          const verifyResponse = await this.authService.verifyUrl({
            url: urlDecoded,
          }).toPromise();

          if (!verifyResponse?.success) {
            this.router.navigate(['/not-found']);
            return;
          }

        } else {
          this.router.navigate(['/not-found']);
        }

        const isBolsaTransferAux = localStorage.getItem(NewCheckoutEnum.IS_BOLSA_TRANSFER_ENUM);
        let isBolsaTransfer = isBolsaTransferAux ? JSON.parse(isBolsaTransferAux) : null;
        if (isBolsaTransfer) {
          this.isBolsaTransfer = isBolsaTransfer;
        }
      }
    } catch (e) {
      console.log('Error in new-cart-payment-method-container.component.ts ngOnInit => ', e);
      if (isPlatformBrowser(this.platformID))
        window.location.replace(this.redirect);
    }
  }

  private async handleAuthError(errorMessage: string): Promise<void> {
    this.authService.logout();
    await this.notificationService.showAndSubscribe(errorMessage, 'ACCEPT', 'CLOSE').afterClosed().toPromise();
    if (isPlatformBrowser(this.platformID))
      window.location.replace(this.redirect);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (typeof window !== 'undefined' && window.localStorage && isPlatformBrowser(this.platformID)) {
      const paymentStorageData = JSON.parse(<string>localStorage.getItem(NewCheckoutEnum.PAYMENT));
      if (!!this.stepper) {
        if (paymentStorageData && paymentStorageData != '') {
          if (this.isBolsaTransfer) {
            this.stepper.selectedIndex = 1;
          } else {
            this.stepper.selectedIndex = 0;
          }
        } else {
          this.stepper.selectedIndex = 0;
        }
      }
      this.changeDetector.detectChanges();
    }
  }

  changeStepper(value: boolean) {
    if (value) {
      if (this.isBolsaTransfer && this.stepper?.selectedIndex === 1) {
        this.redirectToFinalStep();
      } else if (!this.isBolsaTransfer && this.stepper?.selectedIndex === 0) {
        this.redirectToFinalStep();
      } else {
        this.stepper?.next();
      }
    } else {
      this.stepper?.previous();
    }
  }

  redirectToFinalStep() {
    this.router.navigate([`/cart/checkout/completed`], {queryParams: {orderId: this.order?.id}});
  }

  setIsBolsaTransfer(value: boolean) {
    this.isBolsaTransfer = value;
    localStorage.setItem(NewCheckoutEnum.IS_BOLSA_TRANSFER_ENUM, JSON.stringify(this.isBolsaTransfer));
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  loadCompleted() {
    this.isLoaded = true;
    if (this.error && !this.errorShown) {
      this.errorShown = true;
      this.dialog.open(PaymentErrorDialogComponent, {
        width: '400px',
        data: {
          error: this.error
        }
      }).afterClosed().subscribe(() => {
        this.removeErrorFromUrl();
      });
    }
  }

  removeErrorFromUrl(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const updatedParams = {...params};
      delete updatedParams['error'];

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: updatedParams,
        replaceUrl: true
      });
    }).unsubscribe();
  }
}
