<section class="relative w-full h-auto flex justify-center bg-gray-#f2f2f2 pb-5 min-h-screen">

    <div class="relative w-full mt-4 flex flex-col us:w-full max-w-xl lg:max-w-4xl">
      <app-bread-crumbs class="relative w-full mb-3" [data]="data"></app-bread-crumbs>

      <div class="flex flex-row justify-between items-center us:mt-2 lg-landscape:mt-0">
        <div
          class="flex flex-row relative font-Lato-Regular font-semibold text-gray-#252525 text-19px items-center leading-5">
          {{'TRANSFER_REQUEST_TEXT' | translate: {text: label} }}
        </div>
      </div>

      <div class="relative w-full mb-5">
        <div class="relative w-full bg-gray-#E7E7E7 flex justify-start items-center box-border h-50px
                                us:mt-2 us:w-full
                                lg-landscape:mt-5">
          <div (click)="selectTab(1)"
               class="relative cursor-pointer w-auto flex justify-center items-center px-8 h-full border-t-4 transition-all ease-in-out duration-200
                                    us:w-full us:p-5
                                    us-landscape:w-auto
                                    md:w-auto"
               [ngClass]="tab === 1 ? 'bg-white border-yellow-#FF6D03' : 'bg-transparent border-transparent'">
            <p
              class="relative text-sm font-Lato-Regular font-semibold uppercase text-gray-#252525">{{'RECEIVED' | translate}}</p>
          </div>
          <div (click)="selectTab(2)"
               class="relative cursor-pointer w-auto flex justify-center items-center px-8 h-full border-t-4 transition-all ease-in-out duration-200
                                    us:w-full us:p-5
                                    us-landscape:w-auto
                                    md:w-auto"
               [ngClass]="tab === 2 ? 'bg-white border-yellow-#FF6D03' : 'bg-transparent border-transparent'">
            <p
              class="relative text-sm font-Lato-Regular font-semibold uppercase text-gray-#252525">{{'SEND_TRANSFER_REQUEST' | translate}}</p>
          </div>
        </div>

        <div *ngIf="tab === 1" class="relative w-full">
          <app-request-transfer-list [status]="transferRequestEnum.RECEIVED"
                                     [walletTierId]="walletTierId"></app-request-transfer-list>
        </div>

        <div *ngIf="tab === 2" class="relative w-full">
          <app-request-transfer-list [status]="transferRequestEnum.REQUESTED"
                                     [walletTierId]="walletTierId"></app-request-transfer-list>
        </div>
      </div>

    </div>
</section>
