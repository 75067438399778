import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {faClose, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {WalletService} from "../../../shared/services/wallet.service";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {CaptchaComponent} from "../../../shared/components/captcha/captcha.component";

declare global {
  interface Window {
    _turnstileCb?: any;
    turnstile: any;
  }
}

@Component({
  standalone: true,
  selector: 'app-gift-card',
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    NgxMatIntlTelInputComponent,
    MatDialogClose,
    CaptchaComponent,
  ],
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss']
})
export class GiftCardComponent implements OnInit {
  faClose = faClose;
  is_human = false;
  isLoadingCaptcha = true;
  token: string | null = null;
  faSpinner = faSpinner;
  giftCardForm: FormGroup;
  is_loading_request = false;
  @ViewChild('section') section: any;

  constructor(
    private dialogRef: MatDialogRef<GiftCardComponent>,
    private walletService: WalletService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
    this.giftCardForm = new FormGroup({
      keyCode: new FormControl(null, [Validators.required, Validators.pattern(/^([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{4}$/)]),
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      document.getElementById('masked')?.addEventListener("input", (e: any) => {
        e.target.value = this.returnMask(e?.target?.value);
      });
      if (this.data) {
        this.giftCardForm.setValue({
          keyCode: this.data
        })
      }
    }
  }

  captchaCapture(token: string) {
    this.token = token;
    this.is_human = true;
    let el: HTMLElement = this.section?.nativeElement;
    el?.click();
  }

  close(val: any) {
    if (isPlatformBrowser(this.platformID)) {
      window?.turnstile?.remove();
      this.dialogRef.close(val);
    }
  }

  confirmCaptcha() {
    window?.turnstile?.remove();
    this.is_human = true;
  }

  submit() {
    this.is_loading_request = true;
    if (isPlatformBrowser(this.platformID)){
      this.walletService.applyGiftCard({
        ...this.giftCardForm.value,
        CaptchaToken: this.token,
        Fails: false
      }).subscribe({
        next: (data: any) => {
          this.is_loading_request = false;
          window?.turnstile?.remove();
          data?.success ? this.close({status: true, msg: data?.message, cancel: false}) : this.close({
            status: false,
            msg: data?.message,
            cancel: false
          });
        },
        error: (err) => {
          this.is_loading_request = false;
          window?.turnstile?.remove();
          this.close({status: false, msg: err?.error?.message, cancel: false});
        }
      });
    }
  }

  returnMask(value: string) {
    if (![null, undefined, ''].includes(value.trim())) {
      const a = value.replace(/[^a-zA-Z0-9]/g, "");
      const b = a.match(/.{1,4}/g);
      return b?.join('-');
    }
    return null;
  }
}
