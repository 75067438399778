import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {SelectedZone, Transaction, TransactionType} from "../../../shared/models";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {WalletService} from "../../../shared/services/wallet.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {ZonesService} from "../../../shared/services/zones.service";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {AuthService} from "../../../shared/services/auth.service";

@Component({
  selector: 'app-k-k-wallet-operations',
  standalone: true,
  templateUrl: './k-wallet-operations.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    FaIconComponent
  ],
  styleUrls: ['./k-wallet-operations.component.scss']
})
export class KWalletOperationsComponent implements OnInit, OnDestroy {

  data: any;
  params: any;

  faSpinner = faSpinner;
  spinLoadMore = false;

  actualPage: number = 1;
  perPage: number = 10;
  totalElements: number = 0;
  totalPages: number = 0;

  walletTierId: number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  groupedTransactions: Transaction[] = [];


  constructor(
    private walletService: WalletService,
    private zonesService: ZonesService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.goToTop();
  }

  ngOnInit(): void {
    window.scroll({top: 0});
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.goToTop();
        this.params = {...params};
        this.data = [
          {section: 'YOUR_ACCOUNT', route: '/profile/account'},
          {section: 'K-wallet', route: '/profile/payments/1'},
          {section: 'TRANSACTIONS', route: '/profile/payments/k-wallet-operations'},
        ]

        this.walletTierId = Number(params['wTier']);
        this.getWalletDataFromWalletService();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  goToTop() {
    window.scroll({top: 0});
  }

  gotoRoute() {
    this.router.navigate(['/profile'], {queryParams: {redirect: this.authService.getRedirect()}});
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  loadPayments(page: number) {
    this.spinLoadMore = true;
    this.walletService.getTransactions(page, this.perPage, this.walletTierId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: (data: TransactionType) => {
            this.spinLoadMore = false;
            this.totalElements = data.totalCount;
            this.totalPages = data.totalPages;

            const mappedItems: Transaction[] = data.items.map((item) => {
              return {
                id: item.id,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                deletedAt: item.deletedAt,
                receiverType: item.receiverType,
                amount: Number(Number(item.amount / 100).toFixed(2)),
                description: item.description,
                date: item.date,
              }
            });

            this.groupedTransactions = [...this.groupedTransactions, ...mappedItems];
          },
          error: () => this.spinLoadMore = false
        }
      )
  }

  loadMoreTransactions() {
    this.loadPayments(++this.actualPage);
  }

  getObjectKeys(object: Object) {
    return Object.keys(object);
  }

  getWalletDataFromWalletService() {
    this.walletService.walletSubjectNewImplementation
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.walletService.redirectInCaseWalletInfoIsNotFound(value);
        if (value) {
          this.loadPayments(this.actualPage);
        }
      });
  }
}
