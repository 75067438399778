import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

if (!environment.isLocal) {
  Sentry.init({
    dsn: environment.sentry_dsn,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment.name,
    ignoreErrors: [
      "TurnstileError",
      "[Cloudflare Turnstile]",
      "Can't find variable: _AutofillCallbackHandler",
      "ReferenceError global code",
      "track.details.abs",
      "Failed to load the JS script of the agent"
    ],
  });
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
