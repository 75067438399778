<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div class="bg-white rounded-lg shadow-lg w-11/12 max-w-md p-6">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-xl font-semibold text-orange-500 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12A9 9 0 1112 3a9 9 0 010 18z" />
        </svg>
        {{ 'PAYMENT_FAILED' | translate }}
      </h2>
    </div>
    <p class="text-gray-700 mb-6">{{ 'PAYMENT_FAILED_MESSAGE' | translate }}</p>
    <div class="flex justify-end">
      <button (click)="onClose()" class="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-800 focus:outline-none">
        {{'CLOSE' | translate}}
      </button>
    </div>
  </div>
</div>
