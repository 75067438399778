<div
  class="relative w-full bg-white flex justify-start items-start
            us:min-h-346px us:mt-6 us:px-4 us:py-4 us:flex-col
            us-landscape:min-h-346px us-landscape:mt-6 us-landscape:pl-10 us-landscape:pr-10 us-landscape:pt-10 us-landscape:pb-9 us-landscape:flex-row
            md:min-h-346px md:mt-6 md:pl-10 md:pr-10 md:pt-10 md:pb-9
            lg-landscape:min-h-346px lg-landscape:mt-1 lg-landscape:pl-10 lg-landscape:pr-10 lg-landscape:pt-10 lg-landscape:pb-9">
  <fa-icon class="absolute top-1 right-2 rounded-full border-gray-#5d5d5d
                  text-blue-#18396C lg-landscape:text-13px ml-3 bg-gray-300 animate-pulse h-4 w-4">
  </fa-icon>
  <div class="relative us:w-full">

    <div
      class="relative flex flex-col justify-start items-start
          us:w-full
          us-landscape:w-auto us-landscape:h-full us-landscape:border-r us-landscape:border-r-gray-#dfdfdf us-landscape:pr-4
          md:w-auto md:h-full md:border-r md:border-r-gray-#dfdfdf md:pr-4
                lg-landscape:w-448px lg-landscape:h-full lg-landscape:border-r lg-landscape:border-r-gray-#dfdfdf lg-landscape:pr-4">
      <div class="relative
                    us:w-4/5 us:h-auto
                    us-landscape:w-280px us-landscape:h-60px
                    md:w-280px md:h-60px
                    lg-landscape:w-280px lg-landscape:h-60px bg-gray-300 rounded animate-pulse">
      </div>
      <p
        class="relative w-full font-Lato-Regular font-bold
          us:text-base us:mt-4
                    lg-landscape:text-17px lg-landscape:leading-21px lg-landscape:mt-5 rounded h-4 w-full bg-gray-300 animate-pulse">
      </p>

      <div class="relative w-full
          us:mt-4
          lg-landscape:mt-5">
        <p class="relative font-Lato-Regular text-gray-#252525 flex justify-start items-center
            us:text-sm us:mb-1
            lg-landscape:text-sm lg-landscape:mb-1" *ngFor="let feature of [1,2,3,4,5]">
          <span class="relative flex justify-center items-center  bg-white rounded-full
                        text-yellow-#FF6D03
                        us:text-sm us:mr-3
                        lg-landscape:text-sm lg-landscape:mr-3 h-4 w-4 bg-gray-300 animate-pulse">
          </span>
          <span class="relative w-60 h-4 bg-gray-300 animate-pulse rounded"></span>
        </p>
      </div>
    </div>
  </div>
  <!--        <app-k-wallet-skeleton *ngIf="isLoadingWalletAccounts"></app-k-wallet-skeleton>-->
  <div class="relative us:w-full">
    <div class="relative
        us:w-full
        us-landscape:pl-8
        us:pt-3
        md:pl-8
        lg-landscape:w-auto lg-landscape:pl-8">
      <p class="relative flex flex-row font-Lato-Regular font-bold text-gray-#252525
          us:text-base
                    lg-landscape:text-17px pb-2" *ngFor="let balance of [1,2]">
        <span class="relative w-40 h-4 bg-gray-300 animate-pulse rounded"></span>
        <fa-icon class="rounded-full border-gray-#5d5d5d
                            text-blue-#18396C lg-landscape:text-13px ml-3 w-4 h-4 bg-gray-300 animate-pulse">
        </fa-icon>
      </p>

      <div class="relative w-full pb-2 pt-2 flex us:flex-col us:w-full lg-landscape:gap-2">
        <span
          class="relative font-Lato-Regular font-bold text-gray-#252525 rounded-3px
                          us:text-15px us:ml-0 us:mb-1
                          us:w-20
                          md:mb-2.5
                          lg-landscape:text-15px lg-landscape:mb-3 lg-landscape:ml-0 lg-landscape:w-40 mt-3 mr-2 h-4 bg-gray-300 animate-pulse">
        </span>


        <span
          class="relative font-Lato-Regular
                        rounded-full
                        us:text-15px us:h-10 us:rounded-3px us:w-full
                        us-landscape:h-10
                        md:h-10
                        us-landscape:w-40
                        md-landscape:w-80
                        lg-landscape:text-15px px-3 py-2 bg-gray-#f2f2f2
                        active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 border-none h-10 text-base col-span-1 bg-gray-300 animate-pulse"></span>
      </div>
      <div class="relative bg-gray-300 text-white font-Lato-Regular font-bold rounded-full
              us:w-60 us:h-49px us:text-base us:mt-3 us:px-4
              us-landscape:h-49px us-landscape:text-19px us-landscape:mt-3 us-landscape:px-0 us-landscape:self-center
              us-landscape:w-40 md:h-49px md:text-19px md:mt-3 md:px-0 md:self-center
              md-landscape:w-80 lg-landscape:h-49px lg-landscape:text-19px lg-landscape:mt-3 lg-landscape:px-0 lg-landscape:self-center
              animate-pulse" [ngClass]="{'cursor-pointer': false}">
      </div>
      <p class="relative font-Lato-Regular text-gray-#252525
            us:text-base us:mt-4
            us:w-80
            us-landscape:w-40
            md-landscape:w-80
            lg-landscape:text-15px lg-landscape:mt-6 lg-landscape:w-80 h-4 bg-gray-300 animate-pulse rounded">
      </p>
      <div class="relative w-full us:mt-2 lg-landscape:mt-5">
        <div class="relative w-full flex flex-row us:flex-col" *ngFor="let action of [1,2,3,4]">
          <div class="relative flex gap-2 font-Lato-Regular text-gray-#252525 justify-start items-center underline
                    us:mb-3
                    lg-landscape:text-sm lg-landscape:mb-4 w-52 us-landscape:w-48
            md-landscape:w-80">
            <fa-icon class="relative flex rounded-full border-gray-#5d5d5d
                              text-blue-#18396C lg-landscape:text-13px ml-3 h-4 w-4 bg-gray-300 animate-pulse">
            </fa-icon>
            <span class="relative h-4 w-40 bg-gray-300 animate-pulse rounded"></span>

            <fa-icon class="relative flex rounded-full border-gray-#5d5d5d
                              text-blue-#18396C lg-landscape:text-13px h-4 w-4 bg-gray-300 animate-pulse">
            </fa-icon>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
