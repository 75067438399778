<div *ngIf="notAvailable" class="flex w-full mt-12 mb-5 items-center justify-center">
  <span class="font-Lato-Regular text-19px p-5 bg-white rounded text-gray-#363636 italic">
    {{ 'PAYMENT_METHOD_NOT_AVAILABLE_FOR_THIS_USER' | translate }}
  </span>
</div>

<div *ngIf="!isLoading && !notAvailable" class="flex flex-col justify-center mt-7 w-full items-center gap-5">
  <span class="">{{ title }}</span>
  <qrcode [qrdata]="extraData" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

  <form [formGroup]="form" class="flex w-full flex-col gap-2">
    <div class="flex flex-1 flex-col relative pb-6">
      <input placeholder="{{ 'TRANSACTIONS_INFO' | translate }}*" formControlName="txnInfo" id="txnInfo"
             [ngClass]="{
                   'border-none': txnInfo?.valid || (txnInfo?.invalid && !(txnInfo?.dirty || txnInfo?.touched || formMarkAsTouched)),
                   'border-red-500': (txnInfo?.invalid && formMarkAsTouched) || (txnInfo?.invalid && (txnInfo?.dirty || txnInfo?.touched))
                 }"
             class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
      <div *ngIf="txnInfo?.invalid && (formMarkAsTouched || txnInfo?.dirty || txnInfo?.touched)" class="absolute bottom-0.5 text-sm text-red-500">
        <p *ngIf="txnInfo?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
      </div>
    </div>

    <div class="flex flex-1 flex-col relative pb-6">
      <textarea placeholder="{{ 'OBSERVATIONS' | translate }}" formControlName="observations" id="observations" [rows]="2"
             [ngClass]="{
                   'border-none': observations?.valid || (observations?.invalid && !(observations?.dirty || observations?.touched || formMarkAsTouched)),
                   'border-red-500': (observations?.invalid && formMarkAsTouched) || (observations?.invalid && (observations?.dirty || observations?.touched))
                 }"
             class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 rounded-3px pl-4 text-base" type="text">
      </textarea>
      <div *ngIf="observations?.invalid && (formMarkAsTouched || observations?.dirty || observations?.touched)" class="absolute bottom-0.5 text-sm text-red-500">
        <p *ngIf="observations?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
      </div>
    </div>
  </form>
</div>

<div *ngIf="isLoading" class="flex flex-col justify-center mt-7 w-full animate-pulse items-center gap-5">
  <div class="relative w-64 h-7 bg-gray-300 rounded "></div>
  <div class="w-64 h-64 bg-gray-300"></div>

  <div class="flex w-full flex-col gap-7 gap-2">
    <div class="relative w-full h-12 bg-gray-300 rounded"></div>
    <div class="relative w-full h-16 bg-gray-300 rounded"></div>
  </div>
</div>
