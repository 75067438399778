import {ErrorHandler, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {environment} from "../../../environments/environment";
import {AuthService} from "../services/auth.service";
import {CONFIG} from "../../../../public/assets/config";
import {isPlatformBrowser} from "@angular/common";


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private authService: AuthService) {
  }

  handleError(error: Error): any {
    if (isPlatformBrowser(this.platformId)) {
      if (!environment.isLocal) {
        Sentry.setUser({
          username: this.authService.getCurrentUser()?.email
        });
        Sentry.setTag('Payments Version', CONFIG.version);
        Sentry.captureException(error);
      } else {
        throw error;
      }
    }
  }
}
