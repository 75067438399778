import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, catchError, empty, Observable, switchMap, take, throwError} from 'rxjs';

import {filter} from "rxjs/operators";
import {Router} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {CookieService} from "ngx-cookie-service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _cookieService: CookieService
  ) {
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    // && !AuthUtils.isTokenExpired(this._authService.keyAccessToken)
    if (this._authService.getCurrentToken() && !newReq.url.includes('s3.amazonaws.com')) {
      newReq = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + this._authService.getCurrentToken())
      });
    }
    // Response
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && error.headers.get('reason') === 'Invalid/Expired Token') {
        return this.handleAuthenticationError(req, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this._authService.refreshToken().pipe(
        switchMap((isToken: boolean) => {
          this.isRefreshing = false;
          const access_token = this._authService.getCurrentToken();
          this.refreshTokenSubject.next(access_token!);
          return next.handle(this.addToken(request, access_token!));
        }),
        catchError((err) => {
          // todo notificar que ha expirado la session
          this.isRefreshing = false;
          this._authService.navigateToAppExpiredToken();
          return empty();
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

}
