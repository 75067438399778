import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NotificationService} from "../../../shared/services/notification.service";
import {faChevronLeft, faChevronRight, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from "rxjs";
import {LanguageService} from "../../../shared/services/language.service";
import {TransferRequestEnum} from "../../../shared/enum/transfer-request.enum";
import {WalletService} from "../../../shared/services/wallet.service";
import {RequestTransferInterface} from "../../interfaces/request-transfer.interface";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {
  AcceptTranferRequestDialogComponent
} from "../accept-tranfer-request-dialog/accept-tranfer-request-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";

@Component({
  selector: 'app-request-transfer-list',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FaIconComponent,

  ],
  templateUrl: './request-transfer-list-by-status.component.html'
})
export class RequestTransferListByStatusComponent implements OnInit, OnDestroy {
  @Input() status: TransferRequestEnum = TransferRequestEnum.RECEIVED;
  @Input() walletTierId: number = 1;

  PendingStatusId = 10;
  transferRequestEnum = TransferRequestEnum;
  currentPage: number = 1;
  pageSize: number = 5;
  totalPage: number = 1;
  totalCount: number = 1;
  showRequestTransferList: RequestTransferInterface[] = [];
  isLoading: boolean = true;
  faArrowLeft = faChevronLeft;
  faArrowRight = faChevronRight;
  faSpinner = faSpinner;

  requestTransferList: { currentPage: number, showRequestTransferList: any[] }[] = [];
  isRejecting: number = 0;
  isAccepting: number = 0;
  languageServiceSubscription!: Subscription;
  lang!: string;

  constructor(
    private walletService: WalletService,
    private notificationService: NotificationService,
    private languageService: LanguageService,
    private dialog: MatDialog,
  ) {
    this.languageServiceSubscription = this.languageService.selectedLanguage.subscribe(lang => {
      if (this.lang && this.lang != lang) {
        this.lang = lang;
        this.getData(true);
      } else {
        this.lang = lang;
      }
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.languageServiceSubscription.unsubscribe();
  }

  getData(force: boolean = false) {
    this.isLoading = true;
    if (this.requestTransferList.length >= this.currentPage) {
      const objRemittance = this.requestTransferList.find((it: any) => it.currentPage === this.currentPage);
      if (objRemittance) {
        if (!force) {
          this.showRequestTransferList = [...objRemittance.showRequestTransferList];
          this.showRequestTransferList.forEach(it => {
            it.amount = Number(Number(it.amount / 100).toFixed(2))
          });
          this.isLoading = false;
        } else {
          this.requestData(objRemittance.currentPage);
        }
      } else {
        this.requestData();
      }
    } else {
      this.requestData();
    }
  }

  requestData(forceCurrentPage: number = 0) {
    if (forceCurrentPage > 0) {
      for (let i = forceCurrentPage - 1; i < this.requestTransferList.length - 1; i++) {
        this.requestTransferList.splice(i, 1);
      }
    }
    this.walletService.getRequestTransferByStatus(this.walletTierId, this.status, this.pageSize, this.currentPage).subscribe((response: any) => {
      if (response.success) {
        const items = response.data.items.map((it: any) => {
          it.seeReceipt = false;
          it.isCanceling = false;
          return it;
        });
        this.showRequestTransferList = [...items];
        this.showRequestTransferList.forEach(it => {
          it.amount = Number(Number(it.amount / 100).toFixed(2))
        });
        this.totalPage = response.data.totalPages;
        this.totalCount = response.data.totalCount;

        this.requestTransferList.push({
          currentPage: this.currentPage,
          showRequestTransferList: [...this.showRequestTransferList]
        });
      } else {
        this.notificationService.error(response.message);
      }
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  back() {
    this.currentPage--;
    this.getData();
  }

  next() {
    this.currentPage++;
    this.getData();
  }

  accept(requestTransfer: RequestTransferInterface) {
    this.dialog.open(AcceptTranferRequestDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        id: requestTransfer.id,
      }
    }).afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response.success) {
          this.notificationService.showAndSubscribe('OPERATION_COMPLETED_SUCCESS', 'CLOSE').afterClosed().subscribe((response: any) => {
            this.getData(true);
          });
          // this.getData(true);
        }
      });

  }

  reject(requestTransfer: RequestTransferInterface) {
    requestTransfer.isRejecting = true;
    this.isRejecting++;
    this.walletService.reject(requestTransfer.id).subscribe((response: any) => {
      this.isRejecting--;
      if (response.success && this.isRejecting === 0) {
        this.getData(true);
        this.notificationService.showAndSubscribe('OPERATION_COMPLETED_SUCCESS', 'CLOSE');
      } else {
        this.notificationService.showAndSubscribe(response.message, 'CLOSE');
        requestTransfer.isRejecting = false;
      }
    }, (error: any) => {
      this.isRejecting--;
      requestTransfer.isRejecting = false;
    });
  }
}
