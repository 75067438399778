<section class="relative bg-white rounded-md flex flex-col justify-center items-center
us:w-90vw us:h-40
us-landscape:w-568px us-landscape:max-h-75vh us-landscape:overflow-y-auto
md:w-528px
md-landscape:w-528px
lg-landscape:w-528px lg-landscape:min-h-0 lg-landscape:h-52">
    <p class="relative w-full text-center font-Lato-Bold
    us:text-xl
    lg-landscape:text-2xl">{{ message | translate}}</p>
    <div class="relatice w-full flex justify-center items-center mt-5">
        <fa-icon
            class="relative flex justify-center items-center animate-spin  bg-transparent rounded-full text-4xl text-gray-#252525"
            [icon]="faSpinner">
        </fa-icon>
    </div>
</section>
