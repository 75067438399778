import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { NotificationService } from "../../shared/services/notification.service";
import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {MatSelect} from "@angular/material/select";
import {MatOption} from "@angular/material/autocomplete";

@Component({
  standalone: true,
  selector: "app-confirm-dialog-component",
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  faClose = faClose;
  faSpinner = faSpinner;
  isLoading: boolean = false;
  title: string;
  action: any;
  message: string;
  messageBlack: boolean;
  subMessage: string;
  confirmAction: string;
  cancelAction: string;
  notificationSuccess: string;
  isWarnBotton: boolean;
  multipleAction = false;
  textCenterTitle = false;
  hiddenCancelAction = false;
  messageUppercase = true;
  notificationFailMultiple: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private _formBuilder: FormBuilder,
  ) {
    this.title = data.title;
    this.action = data.action;
    this.multipleAction = data.multipleAction;
    this.message = data.message ? data.message : "";
    this.messageUppercase = data.messageUppercase ? data.messageUppercase : false;
    this.messageBlack = data.messageBlack ? true : false;
    this.textCenterTitle = data.textCenterTitle ? true : false;
    this.hiddenCancelAction = data.hiddenCancelAction ? true : false;
    this.subMessage = data.subMessage ? data.subMessage : "";
    this.confirmAction = data.confirmAction ? data.confirmAction : "YES";
    this.cancelAction = data.cancelAction ? data.cancelAction : "NO";
    this.isWarnBotton = data.isWarnBotton ? data.isWarnBotton : false;
    this.notificationSuccess = data.notificationSuccess
      ? data.notificationSuccess
      : "ACTION_COMPLETED_SUCCESSFULLY";
    this.notificationFailMultiple = data.notificationFailMultiple;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  onSubmit(): void {
    if (this.action) {
      this.isLoading = true;
      this.action.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (this.multipleAction) {
          const responseArray: any[] = Object.values(response);
          if (responseArray.reduce((acc, cur) => acc.success && cur.success)) {
            this.notificationService.success(this.notificationSuccess);
          } else {
            this.notificationService.error(this.notificationFailMultiple);
          }
        }
        // else {
        //     if (!response || response.success) {
        //         this.notificationService.success(this.notificationSuccess);
        //     } else {
        //         this.notificationService.error(response.message);
        //     }
        // }

        this.isLoading = false;
        this.dialogRef.close(Object.assign({ success: true }, response));
      }, (error: any) => {
        console.log(error);

        this.isLoading = false;
        this.dialogRef.close(Object.assign({ success: false, message: error.message }));
      });
    } else {
      this.dialogRef.close();
    }
  }

  close(): void {
    if (!this.isLoading) {
      this.dialogRef.close(null);
    }
  }
}
