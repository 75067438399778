import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {KeyStore} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selectedLanguage: BehaviorSubject<string> = new BehaviorSubject<string>('es');
  constructor() {
  }

  public setCurrentLanguage(language: string) {
    localStorage.setItem(KeyStore.LANGUAGE, language);
  }

  public getCurrentLanguage(): string {
    return localStorage.getItem(KeyStore.LANGUAGE) ?? '';
  }
}
