import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import { CheckoutService } from "../../shared/services/checkout.service";
import {PaymentMethodsEnum} from "../../shared/enum/payment-methods.enum";
import {NotificationService} from "../../shared/services/notification.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {QRCodeModule} from "angularx-qrcode";
import {CommonModule, NgClass} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-qr-staff-payment',
  imports: [
    CommonModule,
    TranslateModule,
    QRCodeModule,
    ReactiveFormsModule,
  ],
  templateUrl: './qr-staff-payment.component.html'
})
export class QrStaffPaymentComponent implements OnInit, OnChanges {
  @Input() formMarkAsTouched: boolean = false;
  @Input() isSaving: boolean = false;
  @Input() form: FormGroup = new FormGroup({
    txnInfo: new FormControl(null, Validators.required),
    observations: new FormControl(null, Validators.required),
    qrValue: new FormControl(null, Validators.required),
    storeName: new FormControl(null, Validators.required),
  });
  @Output() updateForm = new EventEmitter<boolean>();
  extraData!: string;
  title!: string;
  isLoading: boolean = true;
  notAvailable: boolean = false;
  // form: FormGroup = new FormGroup({
  //   txnInfo: new FormControl(null, Validators.required),
  //   observations: new FormControl(null, Validators.required),
  // });

  constructor(
    private checkoutService: CheckoutService,
    private notificationService: NotificationService
  ) {
  }

  ngOnChanges(changes: any): void {
    if (changes.isSaving && changes.isSaving.currentValue) {
      this.setDisableForm();
    } else {
      this.setEnableForm();
    }
  }

  ngOnInit(): void {
    this.checkoutService.getIntentionExtraData(PaymentMethodsEnum.STAFF_PAYMENT).subscribe((response: any) => {
      if (response.success) {
        const qrData = response.data;
        this.extraData = qrData.QrValue;
        this.title = qrData.StoreName;
        this.form.patchValue({
          qrValue: this.extraData,
          storeName: this.title
        });
      } else {
        this.notificationService.showAndSubscribe(response.message, 'CLOSE');
      }
      this.isLoading = false;
    }, (error) => {
      if (error.status == 400) {
        this.notAvailable = true;
      } else {
        this.notificationService.showAndSubscribe(error.error && error.error.message ? error.error.message: error.message, 'CLOSE');
      }
      this.isLoading = false;
    });
  }

  get txnInfo() { return this.form.get('txnInfo'); }
  get observations() { return this.form.get('observations'); }

  setEnableForm() {
    this.form.get('txnInfo')?.enable();
    this.form.get('observations')?.enable();
  }

  setDisableForm() {
    this.form.get('txnInfo')?.disable();
    this.form.get('observations')?.disable();
  }
}
