import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {MatStep, MatStepLabel, MatStepper} from "@angular/material/stepper";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Order} from "../../shared/models/store-order.model";
import {ZonesService} from "../../shared/services/zones.service";
import {NewCheckoutEnum} from "../../shared/enum/checkout.enum";
import {SelectedZone} from "../../shared/models";
import {TranslateModule} from "@ngx-translate/core";
import {PaymentsComponent} from "../payments/payments.component";
import {NewCartQrTransferComponent} from "../new-cart-qr-transfer/new-cart-qr-transfer.component";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {isAllowed} from "../../shared/utils/allowedRoutes";
import {AuthService, KeyStore} from "../../shared/services/auth.service";
import {CookieService} from "ngx-cookie-service";
import {LanguageService} from "../../shared/services/language.service";
import {HeaderComponent} from "../../shared/components/header/header.component";
import {LoadingComponent} from "../../shared/components/loading/loading.component";
import {MatDialog} from "@angular/material/dialog";
import {PaymentErrorDialogComponent} from "../../shared/components/payment-error-dialog/payment-error-dialog.component";

@Component({
  standalone: true,
  selector: 'app-new-cart-payment-method-container',
  templateUrl: './new-cart-payment-method-container.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    MatStepper,
    MatStep,
    PaymentsComponent,
    NewCartQrTransferComponent,
    MatStepLabel,
    HeaderComponent,
    HeaderComponent,
    LoadingComponent
  ],
  styleUrls: ['./new-cart-payment-method-container.component.scss']
})
export class NewCartPaymentMethodContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper;
  language!: string;
  isLanguageLoaded: boolean = false;
  isBolsaTransfer: boolean = false;
  isLoaded = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  order: Order | undefined;
  orderNumber: string = '';
  redirect: any;
  total: number = 0;
  orderId: string = '';
  paymentClient: any;
  paymentRoute: any;
  id: any;
  discount: string = '';
  error: any;
  errorShown: boolean = false;
  vid: any;
  rid: any;


  constructor(
    private zonesService: ZonesService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private languageService: LanguageService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformID: Object
  ) {
  }

  async ngOnInit() {
    const paramMap = this.activatedRoute.snapshot.queryParamMap;
    if (isPlatformBrowser(this.platformID)) {
      if (isAllowed(paramMap.get('redirect'))) {
        this.language = this.languageService.selectedLanguage.value;
        this.isLanguageLoaded = true;

        this.redirect = paramMap.get('redirect');
        this.authService.saveRedirect(this.redirect);
        this.id = paramMap.get('id') ?? '';
        this.total = Number.parseFloat(paramMap.get('total') ?? '0') / 100;
        this.paymentClient = paramMap.get('paymentClient') ?? '';
        this.paymentRoute = paramMap.get('paymentRoute') ?? '';
        this.orderId = paramMap.get('paymentRef') ?? '';
        this.orderNumber = paramMap.get('orderNumber') ?? '';
        this.discount = paramMap.get('discount') ?? '';
        this.authService.savePaymentClient(this.paymentClient);
        this.error = paramMap.get('error') ?? '';
        this.vid = paramMap.get('vid') ?? '';
        this.rid = paramMap.get('rid') ?? '';

        const code = paramMap.get('code');
        if (code) {
          const codeExists = this.cookieService.get('code');
          this.cookieService.set(KeyStore.CODE, code as string);
          if (!this.authService.isAuthenticated() || (code !== codeExists))
            await this.authService.getTokensWithCode(code as string).toPromise();
        }

        this.authService.verifyUrl({
          url: decodeURIComponent(window.location.href),
        }).subscribe((response) => {
          if (!response.success) {
            this.router.navigate(['/not-found']);
          }
        })
      } else {
        this.router.navigate(['/not-found']);
      }
    }

    if (typeof window !== 'undefined' && window.localStorage) {
      const isBolsaTransferAux = localStorage.getItem(NewCheckoutEnum.IS_BOLSA_TRANSFER_ENUM);
      let isBolsaTransfer = isBolsaTransferAux ? JSON.parse(isBolsaTransferAux) : null;
      if (isBolsaTransfer) {
        this.isBolsaTransfer = isBolsaTransfer;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (typeof window !== 'undefined' && window.localStorage && isPlatformBrowser(this.platformID)) {
      const paymentStorageData = JSON.parse(<string>localStorage.getItem(NewCheckoutEnum.PAYMENT));
      if (!!this.stepper) {
        if (paymentStorageData && paymentStorageData != '') {
          if (this.isBolsaTransfer) {
            this.stepper.selectedIndex = 1;
          } else {
            this.stepper.selectedIndex = 0;
          }
        } else {
          this.stepper.selectedIndex = 0;
        }
      }
      this.changeDetector.detectChanges();
    }
  }

  changeStepper(value: boolean) {
    if (value) {
      if (this.isBolsaTransfer && this.stepper?.selectedIndex === 1) {
        this.redirectToFinalStep();
      } else if (!this.isBolsaTransfer && this.stepper?.selectedIndex === 0) {
        this.redirectToFinalStep();
      } else {
        this.stepper?.next();
      }
    } else {
      this.stepper?.previous();
    }
  }

  redirectToFinalStep() {
    this.router.navigate([`/cart/checkout/completed`], {queryParams: {orderId: this.order?.id}});
  }

  setIsBolsaTransfer(value: boolean) {
    this.isBolsaTransfer = value;
    localStorage.setItem(NewCheckoutEnum.IS_BOLSA_TRANSFER_ENUM, JSON.stringify(this.isBolsaTransfer));
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  loadCompleted() {
    this.isLoaded = true;
    if (this.error && !this.errorShown) {
      this.errorShown = true;
      this.dialog.open(PaymentErrorDialogComponent, {
        width: '400px',
        data: {
          error: this.error
        }
      }).afterClosed().subscribe(() => {
        this.removeErrorFromUrl();
      });
    }
  }

  removeErrorFromUrl(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const updatedParams = {...params};
      delete updatedParams['error'];

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: updatedParams,
        replaceUrl: true
      });
    }).unsubscribe();
  }
}
