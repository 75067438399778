export enum WalletActionTypesEnum { // tipos de acciones que pueden llegar en el json
  NONE = 0,
  ACH_DEBIT = 1,
  DEBIT_RECHARGE = 2,
  SEND_MONEY_TO_RECIPIENT = 6,
  WALLET_OPERATIONS = 3,
  GIFT_CARD = 4,
  TRANSFER_REQUEST = 5,
  TRANSFER = 6,
}
