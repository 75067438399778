import {Injectable} from '@angular/core';

import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';

import {BehaviorSubject, empty, Observable, of, tap} from 'rxjs';

import {environment} from '../../../environments/environment';

import {Permissions, Token, User} from '../models';
import {catchError, mapTo, switchMap} from "rxjs/operators";
import moment from "moment";
import {CookieService} from "ngx-cookie-service";
import {LanguageService} from "./language.service";
import {NotificationService} from "./notification.service";


export const KeyStore = {
  CURRENT_USER: 'payment_current_user',
  IS_AUTH: 'payment_is_auth',
  ACCESS_TOKEN: 'payment_access_token',
  REFRESH_TOKEN: 'payment_refresh_token',
  ACCESS_TOKEN_OBJECT: 'payment_auth',
  TOKEN_EXPIRATION_DATE: 'payment_token_expiration_date',
  PAYMENT_CLIENT: 'payment_client',
  REDIRECT: 'payment_redirect',
  LANGUAGE: 'payment_language',
  CODE: 'payment_code'
};

type Params = {
  redirect?: string;
  paymentToken?: string;
  paymentVariantId?: any;
  cleanCart?: boolean;
  pr?: string;
  id?: string;
  vid?: string;
  rid?: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {
  private account_resource = '/identity/account';
  private refreshTokenResource = '/auth/refresh';

  // @BlockUI() blockUI: NgBlockUI;

  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject({});
  public currentUser$: Observable<User> = this.currentUserSubject.asObservable();
  public currentDecodeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentTokenSubject: BehaviorSubject<Token> = new BehaviorSubject(new Token());
  permissions = new BehaviorSubject<Permissions[]>([]);


  constructor(
    public http: HttpClient,
    public cookieService: CookieService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
  ) {
  }

  public logout(): void {
    this.cookieService.delete(KeyStore.IS_AUTH, '/');
    this.cookieService.delete(KeyStore.ACCESS_TOKEN, '/');
    this.cookieService.delete(KeyStore.REFRESH_TOKEN, '/');
    this.cookieService.delete(KeyStore.CURRENT_USER, '/');
    this.cookieService.delete(KeyStore.ACCESS_TOKEN_OBJECT, '/');
    this.cookieService.delete(KeyStore.TOKEN_EXPIRATION_DATE, '/');
    this.cookieService.delete(KeyStore.PAYMENT_CLIENT, '/');
    this.cookieService.delete(KeyStore.LANGUAGE, '/');
    this.cookieService.delete(KeyStore.REDIRECT, '/');
    this.cookieService.delete('valid_age', '/');
    this.cookieService.delete(KeyStore.CODE, '/');
    this.currentUserSubject.next({});
    // });
  }

  public loadUserIfIsAutenticate(): Observable<any> {
    if (this.isAuthenticated()) {
      const url = environment.base_route + '/usuario/' + JSON.parse(<string>this.cookieService.get(KeyStore.CURRENT_USER)).id;

      return this.http.get(url, {headers: this.getHeader()})
        .pipe(
          mapTo((user: User) => {
            this.saveUser(user);
            // this.setPermissions([user.role]);
            return of(user);
          }),
          catchError((response: HttpErrorResponse) => {
            if (response.status === 401) {


              return this.refreshToken()
                .pipe(
                  catchError(() => {
                    this.logout();
                    return empty();
                  })
                );
            }

            return empty();
          })
        );
    }
    return empty();
  }

  public getCurrentUser(): User {
    if (!this.currentUserSubject.getValue()?.id) {
      // console.log('entro a buscar el objeto');
      const user: User = this.cookieService.check(KeyStore.CURRENT_USER) ? JSON.parse(<string>this.cookieService.get(KeyStore.CURRENT_USER)) : null;
      this.currentUserSubject.next(user);
    }
    return this.currentUserSubject.getValue();
  }

  public getCurrentToken(): string {
    return <string>this.cookieService.get(KeyStore.ACCESS_TOKEN);
  }

  public getCurrentRefreshToken(): string {
    return <string>this.cookieService.get(KeyStore.REFRESH_TOKEN);
  }

  public getTokenExpirationDate(): string {
    return <string>this.cookieService.get(KeyStore.TOKEN_EXPIRATION_DATE);
  }

  public getCurrentPaymentClient(): string {
    return <string>this.cookieService.get(KeyStore.PAYMENT_CLIENT) ?? '';
  }

  public getCurrentTokenObject(): User {
    return this.currentUserSubject.getValue();
  }

  public isAuthenticated(): boolean {
    return this.cookieService.get(KeyStore.IS_AUTH) === 'true';
  }

  public isSuperAdmin() {
    // return this.permissionsService.getPermission(RoleEnum.SUPER_ADMIN);
  }

  public hasRole(role = null) {
    // return this.permissionsService.getPermission(role);
  }

  public accessToken(loginData: { username: string, password: string, grant_type: string }): Observable<Token> {

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Accept', 'application/json');

    const url = this.getBaseUrl() + environment.token_resource;

    return this.getToken(url, loginData, headers);
  }

  public refreshToken(): Observable<boolean> {
    const data = {accessToken: this.getCurrentToken(), refreshToken: this.getCurrentRefreshToken()};
    const url = environment.services_api_url + environment.v1 + this.account_resource + this.refreshTokenResource;

    let headers = new HttpHeaders({
      'Accept-Language': 'es',
      'X-K-App': '20',
      'x-refresh': 'true'
    });

    if (data) {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (this.getCurrentToken()) {
      headers = headers.set('Authorization', `Bearer ${this.getCurrentToken()}`)
    }
    if (!!this.getCurrentPaymentClient()) {
      headers = headers.set('X-Payment-Client', this.getCurrentPaymentClient())
    }
    return this.http.post(url, data, {headers: headers})
      .pipe(
        tap((resp) => {
          console.log(resp);
        }),
        switchMap((resp: any) => {
          this.saveToken({
            access_token: resp?.data?.tokens?.accessToken,
            refresh_token: resp?.data?.tokens?.refreshToken,
          });
          return of(resp);
        }),
        catchError((err) => {
          return of(err)
        })
      );
  }

  /**
   * Retorna los header para las peticiones
   *
   * @author Hernan Antonio Sanchez Guzmán <sanchezhernan3@gmail.com>
   * @returns HttpHeaders cabeceras a retornar
   */
  public getHeader(body?: any): HttpHeaders {
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/json;')
      .set('X-Payment-Client', this.getCurrentPaymentClient())
      .set('x-refresh', 'true')
      .set('X-K-App', '20');
    if (body) {
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    return headers;
  }

  /**
   * Retorna los header para las peticiones sin x-refresh
   *
   * @author Hernan Antonio Sanchez Guzmán <sanchezhernan3@gmail.com>
   * @returns HttpHeaders cabeceras a retornar
   */
  public getHeaderNoRefresh(body?: any): HttpHeaders {
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/json;');
    if (body) {
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    return headers;
  }

  setApiUrlInStorage() {
    localStorage.setItem('api_base_route', environment.base_route);
  }

  get getApiUrlInStorage() {
    return localStorage.getItem('api_base_route');
  }

  private getBaseUrl(): String {
    let url: String = environment.base_route;
    if (!environment.production) {
      const base_route = this.getApiUrlInStorage;
      if (!base_route) {
        this.setApiUrlInStorage();
      } else {
        url = base_route;
      }
    }
    return url;
  }

  private getToken(url: string, data: any, headers: HttpHeaders): Observable<Token> {
    const params = new HttpParams()
      .set('locale', this.languageService.selectedLanguage.value);

    return this.http.post(url, data, {headers, params})
      .pipe(
        switchMap((token: Token) => {
          this.saveToken(token);
          // this.saveUser(token);
          // this.setPermissions([token?.user?.role]);
          return of(token);
        })
      );
  }

  private saveToken(token: Token): void {

    this.cookieService.set(KeyStore.ACCESS_TOKEN_OBJECT, JSON.stringify(token), {path: '/'});

    if (token.access_token) {
      this.cookieService.set(KeyStore.ACCESS_TOKEN, token.access_token, {path: '/'});
    }
    if (token.refresh_token) {
      this.cookieService.set(KeyStore.REFRESH_TOKEN, token.refresh_token, {path: '/'});
    }
    if (token.expires_in) {
      this.cookieService.set(KeyStore.TOKEN_EXPIRATION_DATE, moment(new Date()).add(token.expires_in, 'seconds').toISOString(), {path: '/'});
    }
    this.cookieService.set(KeyStore.IS_AUTH, 'true', {path: '/'});

    this.currentTokenSubject.next(token);
  }

  savePaymentClient(paymentClient: string) {
    this.cookieService.set(KeyStore.PAYMENT_CLIENT, paymentClient, {path: '/'});
  }

  saveRedirect(redirect: string) {
    this.cookieService.set(KeyStore.REDIRECT, redirect, {path: '/'});
  }

  public getRedirect(): string {
    return !!this.cookieService.get(KeyStore.REDIRECT) ? this.cookieService.get(KeyStore.REDIRECT) : 'https://www.katapulk.com/';
  }

  public saveUser(user: any): void {
    this.currentUserSubject.next(user);
    this.cookieService.set(KeyStore.CURRENT_USER, JSON.stringify(user), {path: '/'});
  }

  private setPermissions(roles: (string | undefined)[]): void {
    // this.permissionsService.loadPermissions(roles);
  }

  private addPermissions(rol: any): void {
    // this.permissionsService.addPermission(rol);
  }

  currentSession(): any {
    return JSON.parse(<string>this.cookieService.get('userData')) || null;
  }

  hasPermission(permissionKey: string) {
    let hasPermission = false;

    if (
      this.currentUserSubject &&
      this.currentUserSubject.value &&
      this.currentUserSubject.value.permissions &&
      this.permissions.value
    ) {
      const objPermission = this.permissions.value.find(
        (it) =>
          it.key.toLowerCase() === permissionKey.toLowerCase()
      );
      if (objPermission) {
        const permissionFound = this.currentUserSubject.value.permissions.find(
          (x) =>
            x.toLowerCase() ===
            objPermission.permission.toLowerCase()
        );
        if (permissionFound) {
          hasPermission = true;
        }
      }
    }

    return hasPermission;
  }

  getPermissions() {
    const localUser = JSON.parse(<string>this.cookieService.get('userData'));
    return localUser &&
    localUser.permissions
      ? localUser.permissions
      : [];
  }

  isTokenExpired() {
    return this.isAuthenticated() && moment(new Date()).diff(new Date(this.getTokenExpirationDate())) >= 0;
  }


  getTokensWithCode(code: string): Observable<any> {
    const url = environment.services_api_url + environment.v1 + '/identity/account/auth/login/redeemcode';

    const headers = new HttpHeaders({
      'accept': 'application/json',
      'x-refresh': 'true',
      'X-K-App': '20'
    });
    return this.http.get(url, {headers: headers, params: {code: code}}).pipe(
      switchMap((resp: any) => {
        this.saveToken({
          access_token: resp?.data?.tokens?.accessToken,
          refresh_token: resp?.data?.tokens?.refreshToken,
        });
        return of(resp);
      }),
      catchError((err, caught) => {
        this.currentTokenSubject.next({
          access_token: this.getCurrentToken(),
          refresh_token: this.getCurrentRefreshToken(),
        });
        return of(err);
      })
    )
  }

  getAuthCode(): Observable<any> {
    const url = environment.services_api_url + environment.v1 + '/identity/account/getaccesscode';
    let headers = this.getHeader();
    headers = headers.set('Authorization', 'Bearer ' + this.getCurrentToken());
    return this.http.get(url, {headers: headers}).pipe(
      tap(resp => {
        return of(resp);
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  async signOff() {
    const url = environment?.services_api_url + environment?.v1 + '/identity/account/auth/signoff';
    await this.http.post(url, {refreshToken: this.getCurrentRefreshToken()}).toPromise()
      .then((response) => {
        console.log('SignOff:', response);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  navigateToApp({redirect, paymentToken, paymentVariantId, cleanCart, pr, id, vid, rid} : Params = {}, redirectUrl?: string, orderId?: string) {
      const redirectURL = new URL(redirectUrl ?? this.getRedirect());
      if (redirectURL.searchParams.getAll('pt').length > 0) {
        redirectURL.searchParams.delete('pt');
      }
      if (paymentToken) {
        redirectURL.searchParams.append('pt', btoa(paymentToken));
        redirectURL.searchParams.append('pvid', btoa(paymentVariantId?.toString()));
      } else if (paymentToken == '') {
        redirectURL.searchParams.append('pt', '');
      }
      if (cleanCart) {
        redirectURL.searchParams.append('cc', cleanCart.toString());
      }
      if (orderId) {
        redirectURL.searchParams.append('orderId', orderId);
      }
      if (pr) {
        redirectURL.searchParams.append('pr', btoa(pr));
      }
      if (id) {
        redirectURL.searchParams.append('id', id);
      }
      if (vid) {
        redirectURL.searchParams.append('vid', vid);
      }
      if (rid) {
        redirectURL.searchParams.append('rid', rid);
      }
      if (redirect) {
        redirectURL.pathname = redirect;
      }

      this.getAuthCode().subscribe((data: any) => {
        if (data?.success) {
          redirectURL.searchParams.append('code', data?.data?.code);
          this.logout();
          window.location.replace(redirectURL.href);
        }else {
          console.error('Error getting auth code', data?.error);
          this.notificationService.showAndSubscribe('ERROR_GETTING_AUTH_CODE', 'CLOSE');
        }
      });
  }

  navigateToAppExpiredToken(){
    const redirectURL = new URL(this.getRedirect());
    if (redirectURL.searchParams.getAll('pt').length > 0) {
      redirectURL.searchParams.delete('pt');
    }
    this.logout();
    window.location.replace(redirectURL.href);
  }

  verifyUrl(data: any): Observable<any> {
    const url = environment.services_api_url + environment.v1 +
      '/Payment/PaymentLink/Verify';
    return this.http.post(url, data).pipe(
      tap((resp) => {
        return of(resp);
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }
}
