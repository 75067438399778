import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {animate, AUTO_STYLE, state, style, transition, trigger} from "@angular/animations";
import {faClose, faSpinner, faWarning} from "@fortawesome/free-solid-svg-icons";
import {CommonModule, CurrencyPipe, UpperCasePipe} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {CaptchaComponent} from "../../shared/components/captcha/captcha.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {CheckoutService} from "../../shared/services/checkout.service";
import {Order} from "../../shared/models/store-order.model";
import {CaptchaModalComponent} from "../../shared/components/captcha-modal/captcha-modal.component";
import {MatDialog} from "@angular/material/dialog";

const DEFAULT_DURATION = 300;

export interface CurrencyAvailableInterface {
  availability: boolean,
  availabilityMessage: string,
  displayExchangeRate: string,
  displayPaymentAmountUsd: string,
  paymentAmountUsd: number,
  selected: boolean,
  displayExternalCurrencyAmount: string,
  externalCurrencyAmount: number,
  externalCurrencyType: string,
  externalCurrencyTypeId: number,
  walletTierId?: number,
}

@Component({
  standalone: true,
  selector: 'app-new-cart-multi-currencies',
  imports: [
    CommonModule,
    TranslateModule,
    CaptchaComponent,
    FaIconComponent,
    CurrencyPipe,
    UpperCasePipe
  ],
  templateUrl: './new-cart-multi-currencies.component.html',
  animations: [
    trigger('collapse', [
      state('false', style({height: AUTO_STYLE, visibility: AUTO_STYLE})),
      state('true', style({height: '0', visibility: 'hidden'})),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class NewCartMultiCurrenciesComponent implements OnInit {
  @Input() selectedPaymentVariantId!: number;
  @Input() formMarkAsTouched: boolean = false;
  @Input() isSaving: boolean = false;
  @Input() isLoadingChangePaymentMethod: boolean = false;
  @Input() order!: Order;
  @Input() total!: number;
  @ViewChild('section') section: any;
  @Output() isLoadingCurrenciesAvailability = new EventEmitter<boolean>();
  @Output() changeCurrenciesSelected = new EventEmitter<CurrencyAvailableInterface>();
  token: string | null = null;
  is_human = false;
  is_human_currency_selected = false;
  currencyData!: CurrencyAvailableInterface[];
  faWarning = faWarning;
  faSpinner = faSpinner;
  faClose = faClose;
  isLoadCurrencies: boolean = true;

  isCurrencySelected: boolean = false;

  constructor(
    private checkoutService: CheckoutService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit(): Promise<void> {
    const dialogRef = this.dialog.open(CaptchaModalComponent);
    const token = await dialogRef.afterClosed().toPromise();
    this.captchaCapture(token);
  }

  captchaCapture(token: any, selectingCurrency = false) {
    this.token = token;
    if (token) {
      this.is_human = true;
      this.getCurrenciesAvailability();
    }
    let el: HTMLElement = this.section?.nativeElement;
    el?.click();
  }

  getCurrenciesAvailability() {
    this.isLoadingCurrenciesAvailability.next(true);
    this.isLoadCurrencies = true;

    this.checkoutService.getCurrenciesAvailability(this.token!, Number(this.total), this.selectedPaymentVariantId).subscribe((response: any) => {
      const currencyData = response.data.currencyData;
      let isDefaultSelected = false;

      currencyData.forEach((it: any) => {
        if (it.availability && !isDefaultSelected) {
          isDefaultSelected = true;
          it.selected = true;
          this.changeCurrenciesSelected.next(it);
          this.isCurrencySelected = true;
        } else {
          it.selected = false;
        }
      });
      this.currencyData = currencyData;
      this.isLoadingCurrenciesAvailability.next(false);
      this.isLoadCurrencies = false;
    });
  }

  changeCurrencySelect(currency: any) {
    currency.selected = true;
    this.isCurrencySelected = false;
    console.log('this.isCurrencySelected => ', this.isCurrencySelected);
    this.currencyData.forEach((it: any) => {
      it.selected = it.externalCurrencyTypeId == currency.externalCurrencyTypeId;
    });
    this.changeCurrenciesSelected.next(currency);
    this.isCurrencySelected = true;
    console.log('this.isCurrencySelected => ', this.isCurrencySelected);
  }
}
