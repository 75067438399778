import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  Input,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import {CommonModule, isPlatformBrowser, NgClass} from '@angular/common';
import {environment} from "../../../../environments/environment";

declare global {
  interface Window {
    _turnstileCb?: any;
    turnstile: any;
  }
}

@Component({
  selector: 'app-captcha',
  standalone: true,
  templateUrl: './captcha.component.html',
  imports: [
    CommonModule
  ],
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() captchaInstance: string = '';
  @Input() showCaptchaError: boolean = false;
  @Output() onCaptchaCheck: EventEmitter<string> = new EventEmitter<string>();
  // show_skeleton: boolean = true;

  constructor(@Inject(PLATFORM_ID) private platformID: Object) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformID)) {
      if (window.turnstile) {
        this.render()
      } else {
        window._turnstileCb = () => {
          this.render();
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroyWidget();
  }

  render() {
    // this.show_skeleton = false;
    if (isPlatformBrowser(this.platformID)) {
      window.turnstile.render(`#myWidget${this.captchaInstance}`, {
        sitekey: environment.captcha_key,
        theme: 'light',
        callback: (token: any) => {
          this.sendToPArent(token);
        },
      });
    }
  }

  destroyWidget() {
    // this.show_skeleton = true;
    window.turnstile.remove();
  }

  resetWidget() {
    // this.show_skeleton = true;
    window.turnstile.reset();
  }

  sendToPArent(token: string) {
    this.onCaptchaCheck.emit(token);
    // this.destroyWidget();
  }
}
