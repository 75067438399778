import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {
  Address,
  CardNewIntegration,
  DebitDepositType,
  MultipleWalletAccount,
  SelectedZone,
} from "../../../shared/models";
import {takeUntil} from "rxjs/operators";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {faLongArrowLeft, faSpinner,} from '@fortawesome/free-solid-svg-icons';
import {WalletService} from "../../../shared/services/wallet.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {Router} from "@angular/router";
import {ErrorsEnum} from "../../../shared/enum/errors.enum";
import {MatDialog} from "@angular/material/dialog";

import {WalletActionTypesEnum} from "../../../shared/enum/wallet-action-types.enum";
import {CreatePaymentMethodComponent} from "../../../components/create-payment-method/create-payment-method.component";
import {ZonesService} from "../../../shared/services/zones.service";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {BreadCrumbsComponent} from "../../../shared/components/bread-crumbs/bread-crumbs.component";
import {PaymentsCardsComponent} from "../../../components/payments-cards/payments-cards.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {AuthService} from "../../../shared/services/auth.service";


@Component({
  selector: 'app-k-wallet-add-funds-debit',
  standalone: true,
  imports: [
    CommonModule,
    BreadCrumbsComponent,
    TranslateModule,
    PaymentsCardsComponent,
    ReactiveFormsModule,
    FaIconComponent,
  ],
  templateUrl: './k-wallet-add-funds-debit.component.html',
  styleUrls: ['./k-wallet-add-funds-debit.component.scss']
})
export class KWalletAddFundsDebitComponent implements OnInit, OnDestroy {

  data: any = [
    {section: 'Section', route: ''},
  ];

  destroy$: Subject<boolean> = new Subject<boolean>();

  address: Address | null = null;
  addFundsForm: FormGroup = new FormGroup({
    amount: new FormControl(null, [Validators.required, Validators.pattern(/[0-9]+$/)]),
  });

  isSaving = false;
  faSpinner = faSpinner;
  faLongArrowLeft = faLongArrowLeft;

  selectedCard: CardNewIntegration | null = null;
  queryParamsSubscriptions!: Subscription;
  isCheckout: boolean = false;
  productsList: any;

  walletTierId: number = 0;
  minAmount: number = 0;
  maxAmount: number = 0;

  walletNewImplValue: MultipleWalletAccount | null = null;

  constructor(
    private zonesService: ZonesService,
    private walletService: WalletService,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.goToTop();
  }

  ngOnInit(): void {
    this.data = [
      {section: 'YOUR_ACCOUNT', route: '/profile'},
      {section: 'K_WALLET_ADD_FUNDS'},
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    this.queryParamsSubscriptions?.unsubscribe();
  }

  goToTop() {
    // @ts-ignore
    window.scroll({top: 0});
  }

  getWalletDataFromWalletService() {
    this.walletService.walletSubjectNewImplementation
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.walletNewImplValue = value;

        this.walletService.redirectInCaseWalletInfoIsNotFound(value);

        const walletTierData = this.walletNewImplValue?.profileWalletData.find((pd) => pd.kWalletTierId === this.walletTierId);

        let actionSelected = walletTierData?.layout.actions.find((action) => action.actionType === WalletActionTypesEnum.DEBIT_RECHARGE);
        if (actionSelected) {
          this.minAmount = actionSelected.min;
          this.maxAmount = actionSelected.max
        }

        this.addFundsForm.get('amount')?.addValidators([Validators.min(this.minAmount), Validators.max(this.maxAmount)]);
      });
  }

  get amount() {
    return this.addFundsForm.get('amount');
  }

  async submit() {
    this.enableOrDisableForm(true);

    console.log(typeof this.amount!.value);

    const values: DebitDepositType = {
      paymentCardId: this.selectedCard?.id!,
      amount: Number(this.amount!.value * 100),
      fingerprintTraceDto: await this.walletService.getFingerprintData()
    }

    // @ts-ignore
    this.walletService.makeADeposit(values)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.notificationService.showAndSubscribe('K_WALLET_ADD_FUNDS_SUCCESS', 'CLOSE');
            this.enableOrDisableForm(false);
            this.goToRoute();
          } else {
            if (response.fails) {
              this.enableOrDisableForm(false);
              if (response.responseCode === ErrorsEnum.PAYMENT_CARD_NEEDS_REVALIDATION) {
                this.openRevalidateCardModal();
              } else {
                this.notificationService.showAndSubscribe(response.message, 'CLOSE');
              }
            }
          }
        },
        error: (err) => {
          this.notificationService.showAndSubscribe(err.error.message || err.error.error || 'GENERIC_ERROR', 'CLOSE');
          this.enableOrDisableForm(false);
        },
        complete: () => {
          this.enableOrDisableForm(false);
        },
      });

    // if (environment.useLookUp) {
    //   try {
    //     const binLookupInfo = await this.walletService.getBinLookupInfo(values.number);
    //     if (binLookupInfo && binLookupInfo.type === 'debit') {
    //       this.sendForm(values);
    //     } else {
    //       this.notificationService.showAndSubscribe('K_WALLET_ADD_FUNDS_ERROR', 'CLOSE');
    //       this.enableOrDisableForm(false);
    //     }
    //   } catch (err: any) {
    //     this.notificationService.showAndSubscribe(err.message || 'GENERIC_ERROR', 'CLOSE');
    //     this.enableOrDisableForm(false);
    //   }
    // } {
    //   this.sendForm(values);
    // }
  }

  enableOrDisableForm(isSaving: boolean) {
    if (isSaving) {
      this.isSaving = true;
      this.addFundsForm.disable();
    } else {
      this.isSaving = false;
      this.addFundsForm.enable();
    }
  }

  updateSelectedCard(cardData: { selectedCard: CardNewIntegration | null }) {
    this.selectedCard = cardData.selectedCard;
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  goToRoute() {
    this.router.navigate([`/profile`], {queryParams: {redirect: this.authService.getRedirect()}});
  }

  openRevalidateCardModal() {
    let dialoRef = this.dialog.open(CreatePaymentMethodComponent, {
      disableClose: true,
      // position: { top: '30px' },
      data: {paymentCardId: this.selectedCard?.id, isRevalidate: true},
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response != 'close') {
          // this.getCards();
          this.submit();
        }
      })
  }

}
