import {Component} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-payment-error-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    TranslateModule
  ],
  templateUrl: './payment-error-dialog.component.html',
  styleUrl: './payment-error-dialog.component.css'
})
export class PaymentErrorDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PaymentErrorDialogComponent>) {
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
