import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {HeaderComponent} from "../../shared/components/header/header.component";
import {TranslateModule} from "@ngx-translate/core";
import {EMPTY, Subject, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {AuthService, KeyStore} from "../../shared/services/auth.service";
import {PaymentRoutesEnum} from "../../shared/enum/payment-routes.enum";
import {OrdersService} from "../../shared/services/orders.service";
import {catchError} from "rxjs/operators";
import {isPlatformBrowser, NgIf} from "@angular/common";
import {NotificationService} from "../../shared/services/notification.service";

@Component({
  selector: 'app-payment-success',
  standalone: true,
  imports: [
    HeaderComponent,
    TranslateModule,
    NgIf
  ],
  templateUrl: './payment-success.component.html',
  styleUrl: './payment-success.component.css'
})
export class PaymentSuccessComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  redirect: any;
  paymentToken: any;
  paymentVariantId: any;
  paymentRoute: any;
  orderId: any;
  pollingSubscription: any;
  loading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private ordersService: OrdersService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private notificationService: NotificationService
) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.redirect = params['redirect'];
        this.paymentToken = params['paymentToken'];
        this.paymentVariantId = params['paymentVariantId'];
        this.paymentRoute = params['paymentRoute'];
        this.orderId = params['orderId'];
        this.authService.refreshToken().subscribe(() => {
            let intervalMs = 5000;
            if(isPlatformBrowser(this.platformId)) {
              this.pollingSubscription = this.ordersService.getExternalPaymentOrderStatus(intervalMs, this.paymentToken)
                .pipe(
                  takeUntil(this.destroy$),
                  catchError(error => {
                    console.error('Error crítico en el polling:', error);
                    this.notificationService.showAndSubscribe('GENERIC_ERROR', 'ACCEPT', 'CANCEL');
                    this.loading = false;
                    return EMPTY;
                  })
                )
                .subscribe(
                  (data: any) => {
                    intervalMs *= 2;
                    if (data.success) {
                      this.onSubmit();
                      this.pollingSubscription.unsubscribe();
                    }
                  },
                  (error) => {
                    console.error('Error durante el polling:', error);
                    this.notificationService.showAndSubscribe('GENERIC_ERROR', 'ACCEPT', 'CANCEL');
                    this.loading = false;
                  }
                );
            }
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }


  onSubmit() {
    const url = new URL(this.redirect);
    if (this.paymentRoute === PaymentRoutesEnum.CUBACEL || this.paymentRoute == PaymentRoutesEnum.GIFT_CARDS)
      this.authService.navigateToApp({
        paymentToken: this.paymentToken,
        paymentVariantId: this.paymentVariantId
      }, url.origin, undefined);
    else
      this.authService.navigateToApp({
        redirect: '/cu/cart/checkout/start',
        paymentToken: this.paymentToken,
        paymentVariantId: this.paymentVariantId
      }, url.origin, this.orderId);
  }
}
