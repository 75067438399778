import {Routes} from '@angular/router';
import {CardsManagementComponent} from "./components/cards-management/cards-management.component";
import {
  NewCartPaymentMethodContainerComponent
} from "./components/new-cart-payment-method-container/new-cart-payment-method-container.component";
import {LoadingComponent} from "./shared/components/loading/loading.component";
import {TermsAndConditionsComponent} from "./shared/components/terms-and-conditions/terms-and-conditions.component";

export const routes: Routes = [
  {
    path: '', component: NewCartPaymentMethodContainerComponent,
  },
  {
    path: 'profile', component: CardsManagementComponent,
  },
  {
    path: 'add-funds-debit', loadComponent: () =>
      import('./profile/components/k-wallet-add-funds-debit/k-wallet-add-funds-debit.component')
        .then(c => c.KWalletAddFundsDebitComponent),
  },
  {
    path: 'k-wallet-operations',
    loadComponent: () => import('./profile/components/k-wallet-operations/k-wallet-operations.component')
      .then(c => c.KWalletOperationsComponent),
  },
  {
    path: 'payments/:id/requests/:tierId',
    loadComponent: () => import('./profile/components/request-transfers/request-transfers.component')
      .then(c => c.RequestTransfersComponent),
  },
  {
    path: 'payment-success', loadComponent: () => import('./components/payment-success/payment-success.component')
      .then(c => c.PaymentSuccessComponent),
  },
  {
    path: 'payment-requests',
    loadComponent: () => import('./profile/components/payment-requests/payment-requests.component')
      .then(c => c.PaymentRequestsComponent),
  },
  {
    path: 'user-payments', loadComponent: () => import('./profile/components/user-payments/user-payments.component')
      .then(c => c.UserPaymentsComponent)
  },
  {
    path: 'not-found', loadComponent: () => import('./components/not-found/not-found.component')
      .then(m => m.NotFoundComponent)
  },
  {
    path: 'terms-and-conditions/:id',
    loadComponent: () => import('./components/accept-terms-and-conditions/accept-terms-and-conditions.component')
      .then(c => c.AcceptTermsAndConditionsComponent),
  },
  {path: '**', component: LoadingComponent}
];
