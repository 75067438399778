import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subject} from "rxjs";
import {SelectedZone} from "../../../shared/models";
import {takeUntil} from "rxjs/operators";
import {ZonesService} from "../../../shared/services/zones.service";

import {TransferRequestEnum} from "../../../shared/enum/transfer-request.enum";
import {ActivatedRoute} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {BreadCrumbsComponent} from "../../../shared/components/bread-crumbs/bread-crumbs.component";
import {
  RequestTransferListByStatusComponent
} from "../request-transfer-list-by-status/request-transfer-list-by-status.component";

@Component({
  selector: 'app-request-transfers',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BreadCrumbsComponent,
    RequestTransferListByStatusComponent
  ],
  templateUrl: './request-transfers.component.html'
})
export class RequestTransfersComponent implements OnInit, OnDestroy {
  transferRequestEnum = TransferRequestEnum;
  tab: number = 1;
  data: any = [
    {section: 'Section', route: ''},
    {section: 'SubSection', route: ''},
  ];
  destroy$: Subject<boolean> = new Subject<boolean>();
  walletTierId: number = 1;
  label: string = '';

  constructor(
    private zonesService: ZonesService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    window.scroll({top: 0});

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const walletTierId = params['walletTierId'];
      this.label = params['label'];
      this.walletTierId = walletTierId ? Number(walletTierId) : 1;
    });

    this.data = [
      {section: 'K-wallet', route: 'profile'},
      {section: 'TRANSFER_REQUEST'},
    ]
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  selectTab(num: number) {
    this.tab = num;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
