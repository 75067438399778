import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectedZone} from "../../shared/models";
import {MatDialog} from "@angular/material/dialog";
import {ZonesService} from "../../shared/services/zones.service";
import {filter} from "rxjs/operators";
import {AddressItem} from "../../shared/models/new-address.model";
import {NewAddAddressComponent} from "../new-add-address/new-add-address.component";
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";


@Component({
  standalone: true,
  selector: 'app-new-checkout-address-manager',
  imports: [
    CommonModule,
    FaIconComponent,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './new-checkout-address-manager.component.html',
  styleUrls: ['./new-checkout-address-manager.component.scss']
})
export class NewCheckoutAddressManagerComponent implements OnInit {

  @Input() addresses: AddressItem[] = [];
  @Input() isShipping: boolean = true;
  @Input() isCheckout: boolean = true;
  @Input() selectedAddress: AddressItem | undefined;
  @Input() formMarkAsTouched: boolean = false;
  @Input() errorText: string = 'SELECTION_REQUIRED';
  @Input() showEditButton: boolean = true;
  @Output() onAddressSelect: EventEmitter<AddressItem> = new EventEmitter<AddressItem>();
  @Output() onAddressUpdate: EventEmitter<AddressItem> = new EventEmitter<AddressItem>();

  constructor(
    private dialog: MatDialog,
    private zonesService: ZonesService,
  ) {
  }

  ngOnInit(): void {
  }

  emitAddress(address: AddressItem) {
    this.selectedAddress = address;
    this.onAddressSelect.emit(address);
  }

  openEditAddressModal(address: AddressItem, index: number) {
    this.dialog.open(NewAddAddressComponent, {
      disableClose: false,
      // position: {top: '30px'},
      data: {
        isModeEdit: true,
        isCheckout: this.isCheckout,
        data: address,
        isShippingAddress: this.isShipping
      },
    })
      .afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response?.modified) {
          this.onAddressUpdate.emit(response);
        }
      });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

}
