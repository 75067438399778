<form class="relative bg-gray-#f2f2f2 overflow-y-auto rounded-3px w-auto pb-5" [formGroup]="form">
    <div class="relative bg-white w-full flex items-center h-55px pl-23px pr-23px">
        <p class="relative font-Lato-Regular font-bold text-gray-#252525 text-17px">
            {{'ACCEPT_TRANSFER_REQUEST' | translate}}:
        </p>
    </div>
    <div class="relative w-full pl-23px pr-23px pt-18px gap-4 flex flex-col">
        <div class="flex bg-gray-#D4E1EE w-full min-h-40px items-center pr-3">
            <fa-icon class="relative cursor-pointer flex justify-center items-center rounded-full h-40px
                                            text-gray-#5d5d5d us:text-lg md:text-2xl w-12" [icon]="faWarning"></fa-icon>
            <p class="pt-2 py-2 w-full font-Lato-Regular text-gray-#5d5d5d leading-4 text-justify
                                                    us:text-15px">{{ 'TRANSFER_SECRET_REQUEST' | translate }}</p>
        </div>
        <div class="flex flex-1 flex-col relative pb-6">
            <input placeholder="{{ 'PASSWORD' | translate }}*" formControlName="pass" id="pass" [type]="fieldType" (keyup)="valueChange($event)"
                   [ngClass]="{
                        'border-none': messageError == null && (pass?.valid || (pass?.invalid && !(pass?.dirty && pass?.touched))),
                        'border-red-500': messageError || (pass?.invalid && formMarkAsTouched) || (pass?.invalid && (pass?.dirty && pass?.touched))
                   }"
                   class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                          focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base">
            <fa-icon (click)="showHidePassword()"
                     class="absolute right-2 py-3 rounded-full w-7 h-7 text-yellow-#FF6D03 cursor-pointer"
                     [icon]="iconType">
            </fa-icon>
            <div *ngIf="messageError || (pass?.invalid && (pass?.dirty && pass?.touched))"
                 class="absolute top-12 text-sm text-red-500">
                <p *ngIf="!messageError && pass?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
                <p *ngIf="messageError">{{ messageError }}</p>
            </div>
        </div>

<!--        <div *ngIf="messageError" class="relative flex flex-col pb-16">-->
<!--            <div class="absolute text-sm text-red-500">-->
<!--                <p *ngIf="messageError">{{ messageError }}</p>-->
<!--            </div>-->
<!--        </div>-->
    </div>

    <div class="relative w-full flex flex-row justify-start items-center mt-7 pl-23px pr-23px">
        <button class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer w-auto h-41px rounded-3px pl-6"
                (click)="onSubmit()" [disabled]="form.invalid || isSaving || messageError" type="button"
                [ngClass]="{'opacity-50 cursor-not-allowed': form.invalid || isSaving || messageError, 'pr-1': isSaving, 'pr-6': !isSaving}">
            <p class="relative font-Lato-Bold text-15px leading-4">
                {{'SEND_REQUEST' | translate}}
            </p>
            <fa-icon *ngIf="isSaving"
                     class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
        </button>
        <button class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D hover:bg-gray-#EEEEEE
                hover:border-gray-500 w-20 h-41px rounded-3px ml-2" type="button" mat-dialog-close="close">
            <p class="relative font-Lato-Bold text-15px leading-4">{{'CANCEL' | translate}}</p>
        </button>
    </div>
</form>
