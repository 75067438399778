import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject, takeUntil} from 'rxjs';
import {faCopy, faShareNodes, faSignOut, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {CookieService} from "ngx-cookie-service";
import {Clipboard} from "@angular/cdk/clipboard";
import {Product, SelectedZone} from "../../../shared/models";
import {PaymentRequestDetailsData} from "../../../shared/models/new-cart.model";
import {ZonesService} from "../../../shared/services/zones.service";
import {LanguageService} from "../../../shared/services/language.service";
import {CheckoutService} from "../../../shared/services/checkout.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {OrdersService} from "../../../shared/services/orders.service";
import {
  ProccessingPaymentModalComponent
} from "../../../shared/components/proccessing-payment-modal/proccessing-payment-modal.component";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";
import {BreadCrumbsComponent} from "../../../shared/components/bread-crumbs/bread-crumbs.component";
import {CommonModule} from "@angular/common";
import {isAllowed} from "../../../shared/utils/allowedRoutes";
import {AuthService} from "../../../shared/services/auth.service";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  standalone: true,
  selector: 'app-payment-requests',
  templateUrl: './payment-requests.component.html',
  imports: [
    FaIconComponent,
    TranslateModule,
    BreadCrumbsComponent,
    CommonModule,
    MatTooltipModule
  ],
  styleUrls: ['./payment-requests.component.scss']
})
export class PaymentRequestsComponent implements OnInit, OnDestroy {
  tab_1 = false;
  tab_2 = true;
  data: any = [
    {section: 'Section', route: ''},
    {section: 'SubSection', route: ''},
  ];
  skeletonsSpecialOffers: number[] = Array(6);
  destroy$: Subject<boolean> = new Subject<boolean>();
  date = new Date();
  payment_requests: PaymentRequestDetailsData[] = [];
  is_loading_requests = false;
  actual_page: number = 1;
  loadingSpecialOffers = false;
  specialProductsObs!: Observable<Product[] | null>;
  has_next = false;
  items_per_page = 10;

  productsList: any;

  can_share: boolean = false;
  is_sharing: boolean = false;
  is_copying: boolean = false;
  show_copied_text: boolean = false;

  PENDING_PAYMENT_STATUS = 10;

  readonly faspinner = faSpinner;
  readonly fashare = faShareNodes;
  readonly facopy = faCopy;

  isCancelingRequest = false;

  language!: string;
  isLanguageLoaded: boolean = false;
  redirect: any;
  paymentClient: any;
  selectedTab: string = '';
  auth: boolean = false;


  constructor(
    private orderService: OrdersService,
    private zonesService: ZonesService,
    private languageService: LanguageService,
    private router: Router,
    private checkoutService: CheckoutService,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private clipboard: Clipboard,
    private authService: AuthService,
  ) {
    this.searchForPaymentIntentionOnUrl();
  }

  ngOnInit(): void {
    window.scroll({top: 0});
    this.data = [{section: 'YOUR_ACCOUNT', route: '/profile/account'},
      {section: 'PROFILE_PAYMENT_REQUESTS', route: '/profile/payment-requests'},]
    this.languageService.selectedLanguage
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        const paramMap = this.activeRoute.snapshot.queryParamMap;
        if (isAllowed(paramMap.get('redirect'))) {
          this.language = this.languageService.selectedLanguage.value;
          this.isLanguageLoaded = true;

          this.redirect = paramMap.get('redirect');
          this.authService.saveRedirect(this.redirect);
          this.paymentClient = paramMap.get('paymentClient') ?? '';
          this.selectedTab = paramMap.get('tab') ?? '';
          if (this.selectedTab) {
            this.selectTab(Number(this.selectedTab));
          }
          if (!this.authService.getCurrentPaymentClient()) {
            this.authService.savePaymentClient(this.paymentClient);
          }
          const code = paramMap.get('code');
          if (code && !this.authService.isAuthenticated()) {
            await this.authService.getTokensWithCode(code as string).toPromise();
          }
          if (this.authService.getCurrentToken()) {
            this.auth = true;
            this.getPaymentRequests(true);

          }
        } else {
          this.router.navigate(['']);
        }
      });

    this.can_share = this.cookieService.get('can-share') == 'true';
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  selectTab(num: number) {
    switch (num) {
      case 1:
        this.tab_1 = true;
        this.tab_2 = false;
        break;
      case 2:
        this.tab_1 = false;
        this.tab_2 = true;
        break;

      default:
        break;
    }
    this.getPaymentRequests(true);
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  getPaymentRequests(initialLoad: boolean, filters?: any) {
    // this.curent_filter_value = null;
    this.is_loading_requests = true;
    if (initialLoad) {
      this.actual_page = 0
      this.payment_requests = [];
    }
    if (this.tab_1) {
      this.orderService.getReceivedPaymentRequests(++this.actual_page, 10, filters).subscribe({
        next: (data: any) => {
          this.payment_requests = data?.data?.items;
          this.has_next = data?.data.hasNext;
          this.items_per_page = data?.pageSize;
          this.is_loading_requests = false;
          console.log(data);
        },
        error: (err) => {
          this.is_loading_requests = false;
          console.log(err);
        }
      })
    } else {
      this.orderService.getSentPaymentRequests(++this.actual_page, 10, filters).subscribe({
        next: (data: any) => {
          this.payment_requests = data?.data?.items;
          this.has_next = data?.data.hasNext;
          this.items_per_page = data?.pageSize;
          this.is_loading_requests = false;
          console.log(data);
        },
        error: (err) => {
          this.is_loading_requests = false;
          console.log(err);
        }
      })
    }
  }

  searchForPaymentIntentionOnUrl() {
    this.activeRoute.queryParams.subscribe({
      next: (data: any) => {
        if (data?.pt && data?.pvid && data?.pri) {
          this.openProcessingPaymentModal();
          const PAYMENT_DATA = {
            paymentToken: atob(data?.pt),
            paymentVariantId: Number(atob(data?.pvid)),
            paymentRequestId: atob(data?.pri)
          };
          this.checkoutService.paymentRequestPay(PAYMENT_DATA).subscribe({
            next: (data: any) => {
              this.dialog.closeAll();
              this.notificationService.showAndSubscribe(data?.message, 'ACCEPT', 'CANCEL').afterClosed()
                .subscribe({
                  next: (e) => {
                    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payment-requests`],
                      {
                        // relativeTo: this.activeRoute,
                        queryParams: {},
                        queryParamsHandling: ""
                      }
                    );
                    this.getPaymentRequests(true);
                  },
                  error: (e) => {
                    console.log(e?.error?.message);
                  }
                })
            },
            error: (e) => {
              this.dialog.closeAll();
              this.notificationService.showAndSubscribe(e?.error?.message, 'ACCEPT', 'CANCEL').afterClosed()
                .subscribe({
                  next: (e) => {
                    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payment-requests`],
                      {
                        // relativeTo: this.activeRoute,
                        queryParams: {},
                        queryParamsHandling: ""
                      }
                    );
                    // this.updateHistory();
                  },
                  error: (e) => {
                    console.log(e?.error?.message);
                  }
                })
            }
          })
        }
      },
      error: (e) => {
        console.log(e);
      }
    })
  }

  openProcessingPaymentModal() {
    this.dialog.open(ProccessingPaymentModalComponent, {
      disableClose: true,
      data: {
        message: 'PROCESSING_PAYMENT'
      }
      // position: { top: '30px' },
    }).afterClosed().subscribe({
      next: (data) => {
      },
      error: (e) => {
      },
    })
  }


  shareLink(item: PaymentRequestDetailsData) {
    this.is_sharing = true;
    this.shareCode(item);
  }

  copyToClipboard(item: PaymentRequestDetailsData) {
    this.is_copying = true;
    let copied = this.clipboard.copy(item.paymentLink);
    if (copied) {
      this.show_copied_text = true;
      setTimeout(() => {
        this.show_copied_text = false;
        this.is_copying = false;
      }, 4000);
      return console.log('Copied');
    } else {
      this.is_copying = false;
    }
  }

  async shareCode(item: PaymentRequestDetailsData) {
    try {
      await navigator.share({url: item.paymentLink});
      this.is_sharing = false;
    } catch (error) {
      console.log(error);
    }
  }

  cancelPaymentRequest(paymentRequestId: string) {
    this.isCancelingRequest = true;
    this.orderService.cancelPaymentRequest(paymentRequestId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.notificationService.showAndSubscribe('PAYMENT_REQUEST_CANCELED_SUCCESSFULLY', 'OK');
          this.isCancelingRequest = false;
          this.getPaymentRequests(true);
        },
        error: () => this.isCancelingRequest = false
      })
  }

  protected readonly faSignOut = faSignOut;

  async logout() {
    await this.authService.signOff();
    this.authService.navigateToApp();
  }
}
