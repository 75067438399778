import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import { FpjsClient } from '@fingerprintjs/fingerprintjs-pro-spa';
export { CacheLocation, FingerprintJSPro, InMemoryCache, LocalStorageCache, SessionStorageCache, defaultEndpoint, defaultScriptUrlPattern, defaultTlsEndpoint } from '@fingerprintjs/fingerprintjs-pro-spa';
const FINGERPTINTJS_PRO_ANGULAR_SETTINGS_TOKEN = new InjectionToken('ng-fingerprintjspro-settings-token', {
  factory: () => ({
    clientOptions: {
      loadOptions: {
        apiKey: ''
      }
    }
  })
});
const packageVersion = '2.0.0';

/**
 * Inject FingerprintjsProAngularService and use it to make identification requests.
 *
 * @example ```typescript
 * import { Component, OnInit } from '@angular/core';
 * import { FingerprintjsProAngularService } from 'fingerprintjs-pro-angular';
 *
 * @Component({
 *   selector: 'app-home',
 *   templateUrl: './home.component.html',
 *   styleUrls: ['./home.component.css']
 * })
 * export class HomeComponent implements OnInit {
 *
 *   constructor(private fingerprintjsProAngularService: FingerprintjsProAngularService) { }
 * //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *   visitorId = 'Press "Identify" button to get visitorId';
 *
 *   async onIdentifyButtonClick() : Promise<void> {
 *     const data = await this.fingerprintjsProAngularService.getVisitorData();
 * //  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *     this.visitorId = data.visitorId;
 *     this.extendedResult = data;
 *   }
 * }
 * ```
 */
class FingerprintjsProAngularService {
  constructor(settings) {
    const {
      loadOptions
    } = settings.clientOptions;
    const clientOptions = {
      ...settings.clientOptions,
      loadOptions: {
        ...loadOptions,
        integrationInfo: [...(loadOptions.integrationInfo || []), `fingerprintjs-pro-angular/${packageVersion}`]
      }
    };
    this.fingerprintJsClient = new FpjsClient(clientOptions);
    this.fingerprintJsClientInitPromise = this.fingerprintJsClient.init();
  }
  async getVisitorData(options, ignoreCache) {
    await this.fingerprintJsClientInitPromise;
    return this.fingerprintJsClient.getVisitorData(options, ignoreCache);
  }
  clearCache() {
    return this.fingerprintJsClient.clearCache();
  }
}
FingerprintjsProAngularService.ɵfac = function FingerprintjsProAngularService_Factory(t) {
  return new (t || FingerprintjsProAngularService)(i0.ɵɵinject(FINGERPTINTJS_PRO_ANGULAR_SETTINGS_TOKEN));
};
FingerprintjsProAngularService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: FingerprintjsProAngularService,
  factory: FingerprintjsProAngularService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FingerprintjsProAngularService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [FINGERPTINTJS_PRO_ANGULAR_SETTINGS_TOKEN]
      }]
    }];
  }, null);
})();

/**
 * Include FingerprintjsProAngularModule using `forRoot` method.
 *
 * @example ```typescript
 * import { NgModule } from '@angular/core';
 * import { FingerprintjsProAngularModule } from 'fingerprintjs-pro-angular';
 * // ...
 *
 * @NgModule({
 *   declarations: [AppComponent],
 *   imports: [
 *     BrowserModule,
 *     FingerprintjsProAngularModule.forRoot({loadOptions: {apiKey: 'your-fpjs-public-api-key'}})
 * //  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *   ],
 *   providers: [],
 *   bootstrap: [AppComponent]
 * })
 * export class AppModule { }
 * ```
 */
class FingerprintjsProAngularModule {
  static forRoot(clientOptions) {
    return {
      ngModule: FingerprintjsProAngularModule,
      providers: [{
        provide: FINGERPTINTJS_PRO_ANGULAR_SETTINGS_TOKEN,
        useValue: {
          clientOptions
        }
      }, FingerprintjsProAngularService]
    };
  }
}
FingerprintjsProAngularModule.ɵfac = function FingerprintjsProAngularModule_Factory(t) {
  return new (t || FingerprintjsProAngularModule)();
};
FingerprintjsProAngularModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FingerprintjsProAngularModule
});
FingerprintjsProAngularModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FingerprintjsProAngularModule, [{
    type: NgModule
  }], null, null);
})();

/*
 * Public API Surface of fingerprintjs-pro-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FingerprintjsProAngularModule, FingerprintjsProAngularService };
